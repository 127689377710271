import formatCount from "../../../lib/generic/formatCount";
import {
  blackHex,
  darkBlueHex,
  normal,
  small,
  mediumFontSize,
  singlePanelHeight,
  greyHex,
} from "../../../lib/constants";
import EvaluationCount from "./EvaluationCount";
import {
  Intent,
  intentToHexColor,
  intentToHexColorFilter,
} from "../../../components/intent";

export default function LabeledExpressionControl({
  label,
  count,
  useArrow,
  arrowMinWidth,
  intent = "neutral",
  options,
  expressionControl,
}: {
  label: React.ReactNode;
  count?: number;
  useArrow: boolean;
  arrowMinWidth: number;
  intent?: Intent;
  options: React.ReactNode[];
  expressionControl: React.ReactNode;
}): React.ReactElement {
  return useArrow ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      <LabelArrow
        label={label}
        count={count}
        minWidth={arrowMinWidth}
        intent={intent}
        options={options}
      />
      {expressionControl}
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <LabelHeading
        label={label}
        count={count}
        intent={intent}
        options={options}
      />
      {expressionControl}
    </div>
  );
}

function LabelHeading({
  label,
  count,
  intent,
  options,
}: {
  label: React.ReactNode;
  count?: number;
  intent: Intent;
  options: React.ReactNode[];
}): React.ReactElement {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        color: intent !== "neutral" ? intentToHexColor(intent) : blackHex,
        lineHeight: 1,
        paddingBottom: normal,
        marginBottom: normal,
        borderBottom: `1px solid ${greyHex}`,
      }}
    >
      {options.map((option) => (
        <div style={{ marginRight: 6 }}>{option}</div>
      ))}
      <div>{label}</div>
      {typeof count !== "undefined" ? (
        <EvaluationCount style={{ marginLeft: normal }} count={count} />
      ) : null}
    </div>
  );
}

function LabelArrow({
  label,
  count,
  minWidth,
  intent,
  options,
}: {
  label: React.ReactNode;
  count?: number;
  minWidth: number;
  intent: Intent;
  options: React.ReactNode[];
}): React.ReactElement {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: singlePanelHeight,
      }}
    >
      {options.map((option) => (
        <div style={{ marginTop: -2, marginRight: small }}>{option}</div>
      ))}
      <div
        style={{
          minWidth,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: intent !== "neutral" ? intentToHexColor(intent) : blackHex,
        }}
      >
        <div
          style={{
            whiteSpace: "nowrap",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: -2,
            marginRight: small,
            fontSize: mediumFontSize,
          }}
        >
          {label}
        </div>
        <div
          style={{
            height: singlePanelHeight,
            flexGrow: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: small,
            position: "relative",
          }}
        >
          <div
            style={{
              flexGrow: 1,
              height: 1,
              marginRight: -3,
              backgroundColor:
                intent !== "neutral" ? intentToHexColor(intent) : blackHex,
            }}
          />
          <img
            src="/right_arrow.svg"
            width={8}
            height={8}
            alt=""
            style={{
              filter:
                intent !== "neutral"
                  ? intentToHexColorFilter(intent)
                  : undefined,
            }}
          />
          {typeof count !== "undefined" ? (
            <div
              style={{
                position: "absolute",
                top: 2,
                left: 0,
                width: "calc(100% - 4px)",
                color: darkBlueHex,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 10,
              }}
            >
              {formatCount(count)}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
