import { useEffect, useState } from "react";
import {
  Schema,
  SplitMap,
  fieldPathSeparator,
} from "@hypertune/sdk/src/shared";
import toStartCase from "@hypertune/shared-internal/src/toStartCase";
import { createSplit } from "@hypertune/shared-internal/src/expression/split";
import { getSplitRewardSql } from "@hypertune/shared-internal";
import TextInput from "../../../../components/input/TextInput";
import Label from "../../../../components/Label";
import Card from "../../../../components/Card";
import TypeIcon from "../../../../components/icons/TypeIcon";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  setDraftCommitSplits,
  setNewSplitModalState,
} from "../../projectSlice";
import {
  SplitTypeOption,
  splitTypeOptions,
  useSplitEditorSelectedSplitId,
} from "../splitHooks";
import Modal from "../../../../components/Modal";
import SchemaNameError from "../../schema/typeEditor/SchemaNameError";
import NameError from "./NameError";
import IdSelector from "../../IdSelector";
import UnitIdPathSelector, {
  getJoinIdOptionsForObject,
} from "../../UnitIdPathSelector";

export default function NewSplitModal(): React.ReactElement | null {
  const splits = useAppSelector(
    (globalState) => globalState.project.draftCommit?.splits
  );
  const schema = useAppSelector(
    (globalState) => globalState.project.draftCommit?.schema
  );
  const state = useAppSelector(
    (globalState) => globalState.project.newSplitModal
  );

  if (!state || !splits || !schema) {
    return null;
  }
  return (
    <NewSplitModalInner
      splits={splits}
      schema={schema}
      defaultTypeOption={state.defaultSplitTypeOption}
      onCreate={state.onCreate}
    />
  );
}

function NewSplitModalInner({
  splits,
  schema,
  defaultTypeOption,
  sourceSplitName,
  onCreate,
}: {
  splits: SplitMap;
  schema: Schema;
  defaultTypeOption?: SplitTypeOption;
  sourceSplitName?: string;
  onCreate?: (type: SplitTypeOption, id: string) => void;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();

  const [, setSelectedSplitId] = useSplitEditorSelectedSplitId();

  const [splitType, setSplitTypeOption] = useState<SplitTypeOption>(
    defaultTypeOption || "test"
  );
  const [splitName, setSplitName] = useState("");
  const [goalEventTypeName, setGoalEventTypeName] = useState<string | null>(
    null
  );
  const [goalEventUnitIDPath, setGoalEventUnitIDPath] = useState<
    string[] | null
  >(null);
  useEffect(() => {
    if (goalEventTypeName) {
      setGoalEventUnitIDPath(
        getJoinIdOptionsForObject(schema, goalEventTypeName)[0] ?? null
      );
    }
  }, [schema, goalEventTypeName]);

  const nameError = NameError({
    idMap: splits,
    newName: splitName,
    noun: "Split",
  });

  const isValid =
    splitName !== "" &&
    nameError === null &&
    (splitType !== "ml" ||
      (goalEventTypeName &&
        goalEventUnitIDPath &&
        goalEventUnitIDPath.length > 0));

  function onClose(): void {
    dispatch(setNewSplitModalState(undefined));
  }
  function onSubmit(): void {
    if (!isValid) {
      return;
    }

    const newSplit = createSplit();

    switch (splitType) {
      case "test":
        dispatch(
          setDraftCommitSplits({
            ...splits,
            [newSplit.id]: {
              ...newSplit,
              name: splitName,
            },
          })
        );
        break;
      case "ml":
        dispatch(
          setDraftCommitSplits({
            ...splits,
            [newSplit.id]: {
              ...newSplit,
              name: splitName,
              type: "ml",
              rewardEvents: [
                {
                  eventObjectTypeName: goalEventTypeName!,
                  unitIdPayloadPath: goalEventUnitIDPath!,
                },
              ],
              rewardSql: getSplitRewardSql(goalEventTypeName!),
            },
          })
        );
        break;
      default:
        throw new Error(`Unknown split type option: ${splitType}`);
    }
    setSelectedSplitId(newSplit.id);
    if (onCreate) {
      onCreate(splitType, newSplit.id);
    }
    onClose();
  }
  const typeName = splitType === "ml" ? "ML loop" : "test";

  return (
    <Modal
      modalStyle="medium"
      buttonLayout="end"
      onClose={onClose}
      closeText="Cancel"
      closeOnEsc
      title={
        <Label type="title3" className="text-tx-default">
          {`New ${typeName}`}
        </Label>
      }
      saveText={sourceSplitName ? "Clone" : "Create"}
      saveWeight="outlined"
      saveIntent="neutral"
      saveDisabled={!isValid}
      onSave={onSubmit}
    >
      <div className="mt-4 flex flex-col text-tx-default">
        <Label type="title4" className="mb-[9px] text-tx-muted">
          Type
        </Label>
        <div className="grid grid-cols-2 items-stretch gap-2">
          {splitTypeOptions.map((option) => {
            return (
              <Card
                key={option}
                isSelected={option === splitType}
                layout="horizontal-with-icon"
                className="gap-2"
                onClick={() => setSplitTypeOption(option)}
              >
                <TypeIcon type={option} />
                <Label type="title3">
                  {option === "ml" ? "ML loop" : toStartCase(option)}
                </Label>
              </Card>
            );
          })}
        </div>
        <Label type="title4" className="mb-[9px] mt-6 text-tx-muted">
          Name
        </Label>
        <TextInput
          placeholder={`Enter a name for this ${typeName}`}
          value={splitName}
          onChange={setSplitName}
          focusOnMount
          trimOnBlur={false}
          readOnly={false}
          onEnter={onSubmit}
          size="medium"
          error={
            nameError && <SchemaNameError schemaCheckOrError={nameError} />
          }
        />
        {splitType === "ml" && (
          <>
            <Label type="title4" className="mb-[9px] mt-6 text-tx-muted">
              Goal event type
            </Label>
            <IdSelector
              context={{ splits, schema }}
              source="eventTypes"
              selectedId={goalEventTypeName}
              setSelectedId={setGoalEventTypeName}
              readOnly={false}
              dropDownHeight={34}
            />
            <Label type="title4" className="mb-[9px] mt-6 text-tx-muted">
              Goal event type unit ID path
            </Label>
            <UnitIdPathSelector
              context={{ splits, schema }}
              source="event"
              eventTypeName={goalEventTypeName}
              unitIdPath={goalEventUnitIDPath?.join(fieldPathSeparator) ?? null}
              setUnitIdPath={(newUnitIDPath) =>
                setGoalEventUnitIDPath(newUnitIDPath.split(fieldPathSeparator))
              }
              dropDownHeight={34}
            />
          </>
        )}
      </div>
    </Modal>
  );
}
