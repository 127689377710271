import {
  ApplicationExpression,
  Expression,
  UpdateObjectExpression,
  VariableExpression,
} from "@hypertune/sdk/src/shared";
import getConstraintFromValueType from "@hypertune/shared-internal/src/expression/constraint/getConstraintFromValueType";
import getEmptyExpression from "@hypertune/shared-internal/src/expression/getEmptyExpression";
import isValueTypeValid from "@hypertune/shared-internal/src/expression/isValueTypeValid";
import {
  ValueTypeConstraint,
  VariableMap,
} from "@hypertune/shared-internal/src/expression/types";
import createApplication from "../../../lib/expression/createApplication";
import getTextLabel from "../../../lib/generic/getTextLabel";
import {
  liftPermissionsDeniedErrorMessage,
  normal,
} from "../../../lib/constants";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
} from "../../../lib/types";
import toLabeledOption from "../../../lib/toLabeledOption";
import Dropdown from "../../../components/Dropdown";
import ExpressionControl from "./ExpressionControl";
import LabeledExpressionControlList, {
  LabeledExpressionControlListItemControlType,
} from "./LabeledExpressionControlList";
import isReadOnly from "../../../lib/expression/isReadOnly";
import DeleteButton from "../../../components/buttons/DeleteButton";

export default function UpdateObjectExpressionControl({
  context,
  variables,
  setVariableName,
  expression,
  setExpression,
  includeExpressionOption,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  expression: UpdateObjectExpression;
  setExpression: (newExpression: Expression | null) => void;
  includeExpressionOption: IncludeExpressionOptionFunction;
}): React.ReactElement {
  const childValueTypeConstraint: ValueTypeConstraint = isValueTypeValid(
    context.commitContext.schema,
    expression.valueType
  )
    ? getConstraintFromValueType(expression.valueType)
    : { type: "ErrorValueTypeConstraint" };

  const schemaObjectFields =
    context.commitContext.schema.objects[expression.valueType.objectTypeName]
      ?.fields || {};

  const fieldPosition: { [fieldName: string]: number } = {};

  Object.keys(expression.updates).forEach((fieldName) => {
    if (!schemaObjectFields[fieldName]) {
      // Show invalid fields at the top
      fieldPosition[fieldName] = -1;
    }
  });

  Object.keys(schemaObjectFields).forEach((fieldName, index) => {
    if (typeof expression.updates[fieldName] !== "undefined") {
      // Show valid fields in the order they appear in the schema
      fieldPosition[fieldName] = index;
    }
  });

  const options = Object.keys(schemaObjectFields)
    .filter((fieldName) => typeof expression.updates[fieldName] === "undefined")
    .map((fieldName) => toLabeledOption(fieldName));

  const readOnly = isReadOnly(context);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: normal }}>
      <div>Source</div>
      <ExpressionControl
        context={context}
        variables={variables}
        setVariableName={setVariableName}
        valueTypeConstraint={childValueTypeConstraint}
        expression={expression.object}
        setExpression={(newExpression: Expression | null): void =>
          setExpression({
            ...expression,
            object: newExpression,
          })
        }
        lift={(child): void => {
          if (readOnly) {
            // eslint-disable-next-line no-alert
            alert(liftPermissionsDeniedErrorMessage);
            return;
          }
          function replaceArgument(
            variable: VariableExpression | ApplicationExpression
          ): UpdateObjectExpression {
            const newExpression: UpdateObjectExpression = {
              ...expression,
              object: child.replaceArgument(variable),
            };
            return newExpression;
          }
          const applicationExpression = createApplication({
            variables,
            rawArgument: child.argument,
            replacedVariableIdToNewVariable:
              child.replacedVariableIdToNewVariable,
            valueType: expression.valueType,
            replaceArgument,
            newVariableName: child.newVariableName,
            setExpressionEditorSelectedItem: (newSelectedItem) =>
              context.setExpressionEditorState({
                ...context.expressionEditorState,
                selectedItem: newSelectedItem,
              }),
          });
          setExpression(applicationExpression);
        }}
        parentExpression={expression}
        setParentExpression={setExpression}
        includeExpressionOption={includeExpressionOption}
      />
      <div>Updates</div>
      <LabeledExpressionControlList
        useArrow={false}
        items={Object.entries(expression.updates)
          .sort((a, b) => fieldPosition[a[0]] - fieldPosition[b[0]])
          .map(([fieldName, fieldExpression]) => {
            const fieldValueType = schemaObjectFields[fieldName]?.valueType;

            return {
              type: LabeledExpressionControlListItemControlType,
              label: getTextLabel(fieldName),
              count: fieldExpression
                ? context.evaluations[fieldExpression.id]
                : undefined,
              intent: !fieldValueType
                ? "danger"
                : context.expressionIdToIntent?.[expression.id] ?? "neutral",
              options: readOnly
                ? []
                : [
                    <DeleteButton
                      size="x-small"
                      key={`delete${fieldName}`}
                      onClick={(): void => {
                        const { [fieldName]: _, ...updates } =
                          expression.updates;
                        setExpression({ ...expression, updates });
                      }}
                    />,
                  ],
              expressionControl: (
                <ExpressionControl
                  context={context}
                  variables={variables}
                  setVariableName={setVariableName}
                  valueTypeConstraint={
                    fieldValueType
                      ? getConstraintFromValueType(fieldValueType)
                      : { type: "ErrorValueTypeConstraint" }
                  }
                  expression={fieldExpression}
                  setExpression={(newExpression: Expression | null): void =>
                    setExpression({
                      ...expression,
                      updates: {
                        ...expression.updates,
                        [fieldName]: newExpression,
                      },
                    })
                  }
                  lift={(child): void => {
                    if (isReadOnly(context)) {
                      // eslint-disable-next-line no-alert
                      alert(liftPermissionsDeniedErrorMessage);
                      return;
                    }
                    function replaceArgument(
                      variable: VariableExpression | ApplicationExpression
                    ): UpdateObjectExpression {
                      const newExpression: UpdateObjectExpression = {
                        ...expression,
                        updates: {
                          ...expression.updates,
                          [fieldName]: child.replaceArgument(variable),
                        },
                      };
                      return newExpression;
                    }
                    const applicationExpression = createApplication({
                      variables,
                      rawArgument: child.argument,
                      replacedVariableIdToNewVariable:
                        child.replacedVariableIdToNewVariable,
                      valueType: expression.valueType,
                      replaceArgument,
                      newVariableName: child.newVariableName, // ?? `${toCamelCase(fieldName)}Var`
                      setExpressionEditorSelectedItem: (newSelectedItem) =>
                        context.setExpressionEditorState({
                          ...context.expressionEditorState,
                          selectedItem: newSelectedItem,
                        }),
                    });
                    setExpression(applicationExpression);
                  }}
                  parentExpression={expression}
                  setParentExpression={setExpression}
                  includeExpressionOption={includeExpressionOption}
                />
              ),
            };
          })}
      />
      {!readOnly ? (
        <div>
          <Dropdown
            options={{ type: "options", options }}
            value={null}
            onChange={(option) => {
              if (!option) {
                return;
              }
              const fieldName = option.value;
              const fieldValueType = schemaObjectFields[fieldName]?.valueType;
              setExpression({
                ...expression,
                updates: {
                  ...expression.updates,
                  [fieldName]: fieldValueType
                    ? getEmptyExpression(
                        context.commitContext.schema,
                        variables,
                        fieldValueType,
                        null
                      )
                    : null,
                },
              });
            }}
            placeholder="Add field update..."
            noOptionsMessage="No fields found"
            height={30}
          />
        </div>
      ) : null}
    </div>
  );
}
