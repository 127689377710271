import { initializeApp as initializeFirebaseApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import LogRocket from "logrocket";
import getApolloClient from "./getApolloClient";
import { MeDocument, MeQuery, MeQueryVariables } from "../../generated/graphql";
import env from "../../environment";
import getFaro from "../getFaro";

let auth: Auth | null = null;

export function initializeAuth(): void {
  initializeFirebaseApp({
    apiKey: "AIzaSyA2GuK4BKn9m-UFXT3hRI0noID3B-Aw9HA",
    authDomain: "hypertune-web.firebaseapp.com",
    databaseURL: "https://hypertune-web.firebaseio.com",
    projectId: "hypertune-web",
    storageBucket: "hypertune-web.appspot.com",
    messagingSenderId: "673539311162",
    appId: "1:673539311162:web:582fd93c210e024f389703",
    measurementId: "G-KL3FBZ8Q25",
  });

  auth = getAuth();
}

export function getAuthToken(): Promise<string | null> {
  const user = auth?.currentUser;
  if (!user) {
    return Promise.resolve(null);
  }
  return user.getIdToken().catch((error) => {
    console.error("[getAuthToken] getIdToken error:", error);
    return null;
  });
}

/** Fired when the auth state has changed to an authenticated user with up-to-date profile information (e.g. displayName). */
export async function onUpdateProfile(): Promise<void> {
  const res = await getApolloClient().query<MeQuery, MeQueryVariables>({
    query: MeDocument,
    fetchPolicy: "network-only",
  });
  if (res.error) {
    console.error("[onAuthStateChanged] Failed to retrieve user from backend");
    return;
  }
  const backendUser = res.data.me;

  if (env.NAME === "production") {
    LogRocket.identify(backendUser.id, {
      name: backendUser.displayName ?? "",
      email: backendUser.email ?? "",
    });
  }
  getFaro()?.api.setUser({
    username: backendUser.displayName ?? "",
    email: backendUser.email ?? "",
  });
}
