import { Schema, ValueType } from "@hypertune/sdk/src/shared";
import filterAndSortObjectEntries from "./filterAndSortObjectEntries";

export type SchemaFilter = {
  type: "exclude" | "include";
  values: Set<string>;
};

export default function getSchemaCodeFromSchema(
  schema: Schema,
  filter?: SchemaFilter
): string {
  const hasEnums = Object.keys(schema.enums).length > 0;
  const hasUnions = Object.keys(schema.unions).length > 0;

  return `${objectsToCode(schema, filter)}
${hasUnions || hasEnums ? "\n" : ""}${unionsToCode(schema, filter)}${
    hasUnions ? "\n\n" : ""
  }${enumsToCode(schema, filter)}${hasEnums ? "\n" : ""}`;
}

function objectsToCode(schema: Schema, filter?: SchemaFilter): string {
  return filterAndSortObjectEntries(schema)
    .filter(([objectTypeName]) => includeTypeName(filter, objectTypeName))
    .map(([objectTypeName]) => {
      return objectToCode(schema, objectTypeName);
    })
    .join("\n\n");
}

export function objectToCode(
  schema: Schema,
  objectTypeName: string,
  omitDescriptions?: boolean
): string {
  const objectSchema = schema.objects[objectTypeName];

  return `${descriptionToCode(objectSchema.description, "", omitDescriptions)}${
    objectSchema.role === "output" ? "type" : "input"
  } ${objectTypeName}${objectSchema.role === "event" ? " @event " : ""}${
    Object.keys(objectSchema.fields).length > 0
      ? ` {
  ${Object.keys(objectSchema.fields)
    .map((fieldName) =>
      objectFieldToCode(schema, objectTypeName, fieldName, omitDescriptions)
    )
    .join("\n  ")}
}`
      : ""
  }`;
}

export function objectFieldToCode(
  schema: Schema,
  objectTypeName: string,
  fieldName: string,
  omitDescriptions?: boolean
): string {
  const fieldSchema = schema.objects[objectTypeName].fields[fieldName];

  return `${descriptionToCode(
    fieldSchema.description,
    "  ",
    omitDescriptions
  )}${fieldName}${argsToCode(
    schema,
    fieldSchema.valueType
  )}: ${fieldTypeToGraphqlFieldType(
    fieldSchema.valueType
  )}${deprecationReasonToCode(
    fieldSchema.deprecationReason,
    omitDescriptions
  )}`;
}

function argsToCode(schema: Schema, fieldType: ValueType): string {
  if (
    fieldType.type !== "FunctionValueType" ||
    fieldType.parameterValueTypes.length !== 1 ||
    fieldType.parameterValueTypes[0].type !== "ObjectValueType" ||
    !schema.objects[fieldType.parameterValueTypes[0].objectTypeName] ||
    Object.keys(
      schema.objects[fieldType.parameterValueTypes[0].objectTypeName].fields
    ).length === 0
  ) {
    return "";
  }

  return `(${Object.entries(
    schema.objects[fieldType.parameterValueTypes[0].objectTypeName].fields
  )
    .map(
      ([argName, argField]) =>
        `${argName}: ${fieldTypeToGraphqlFieldType(argField.valueType)}`
    )
    .join(", ")})`;
}

function fieldTypeToGraphqlFieldType(fieldType: ValueType): string {
  switch (fieldType.type) {
    case "VoidValueType":
      return "Void!";
    case "BooleanValueType":
      return "Boolean!";
    case "IntValueType":
      return "Int!";
    case "FloatValueType":
      return "Float!";
    case "StringValueType":
      return "String!";
    case "EnumValueType":
      return `${fieldType.enumTypeName}!`;
    case "FunctionValueType":
      return fieldTypeToGraphqlFieldType(fieldType.returnValueType);
    case "ObjectValueType":
      return `${fieldType.objectTypeName}!`;
    case "ListValueType":
      return `[${fieldTypeToGraphqlFieldType(fieldType.itemValueType)}]!`;
    case "UnionValueType":
      return `${fieldType.unionTypeName}!`;
    default:
      throw Error(`unexpected object field type: ${fieldType.type}`);
  }
}

function descriptionToCode(
  description: string | null,
  padding: string,
  omit?: boolean
): string {
  if (description === null || omit) {
    return "";
  }
  return `"""
${description
  .split("\n")
  .map((line) => `${padding}${line}`)
  .join("\n")}
${padding}"""
${padding}`;
}

function deprecationReasonToCode(
  deprecationReason: undefined | string,
  omit?: boolean
): string {
  if (omit) {
    return "";
  }
  return deprecationReason !== undefined
    ? ` @deprecated(reason: "${deprecationReason}")`
    : "";
}

function unionsToCode(schema: Schema, filter?: SchemaFilter): string {
  return Object.keys(schema.unions)
    .filter((unionName) => includeTypeName(filter, unionName))
    .sort((unionNameA, unionNameB) => unionNameA.localeCompare(unionNameB))
    .map((unionName) => unionToCode(schema, unionName))
    .filter(Boolean)
    .join("\n\n");
}

export function unionToCode(
  schema: Schema,
  unionName: string,
  omitDescriptions?: boolean
): string {
  const unionSchema = schema.unions[unionName];
  if (Object.keys(unionSchema.variants).length === 0) {
    return "";
  }
  return `${descriptionToCode(
    unionSchema.description,
    "",
    omitDescriptions
  )}union ${unionName} = ${Object.keys(unionSchema.variants).join(" | ")}`;
}

function enumsToCode(schema: Schema, filter?: SchemaFilter): string {
  return Object.keys(schema.enums)
    .filter((enumName) => includeTypeName(filter, enumName))
    .sort((enumNameA, enumNameB) => enumNameA.localeCompare(enumNameB))
    .map((enumName) => {
      return enumToCode(schema, enumName);
    })
    .filter(Boolean)
    .join("\n\n");
}

export function enumToCode(
  schema: Schema,
  enumName: string,
  omitDescriptions?: boolean
): string {
  const enumSchema = schema.enums[enumName];

  if (Object.keys(enumSchema.values).length === 0) {
    return "";
  }
  return `${descriptionToCode(
    enumSchema.description,
    "",
    omitDescriptions
  )}enum ${enumName} {
${Object.entries(enumSchema.values)
  .map(
    ([valueName, value]) =>
      `  ${descriptionToCode(
        value.description,
        "  ",
        omitDescriptions
      )}${valueName}${deprecationReasonToCode(value.deprecationReason)}`
  )
  .join(",\n")}
}`;
}

function includeTypeName(
  filter: SchemaFilter | undefined,
  typeName: string
): boolean {
  return (
    !filter ||
    (filter.type === "include" && filter.values.has(typeName)) ||
    (filter.type === "exclude" && !filter.values.has(typeName))
  );
}
