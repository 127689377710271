import { ComparisonOperator, ValueType } from "@hypertune/sdk/src/shared";

export default function getComparisonOperators(
  aValueType: ValueType | null
): ComparisonOperator[] {
  if (!aValueType) {
    return [];
  }
  switch (aValueType.type) {
    case "BooleanValueType":
      return ["==", "!=", "AND", "OR"];

    case "IntValueType":
    case "FloatValueType":
      return ["in", "notIn", "<", "<=", ">", ">="];

    case "StringValueType":
      return [
        "in",
        "notIn",
        "endsWith",
        "notEndsWith",
        "startsWith",
        "notStartsWith",
        "matches",
        "notMatches",
        "contains",
        "notContains",
      ];

    case "EnumValueType":
    case "ObjectValueType":
    case "UnionValueType":
      return ["in", "notIn"];

    case "ListValueType":
      return ["contains", "notContains", "in", "notIn"];

    default:
      return [];
  }
}
