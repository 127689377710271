import React from "react";
import currentFunnelStepHasBreakdowns from "../../../../lib/analytics/currentFunnelStepHasBreakdowns";
import round from "../../../../lib/generic/round";
import { greyHex } from "../../../../lib/constants";
import { FunnelSegmentData } from "../../../../lib/types";

const charactersPerLine = 19;
const lineHeight = 22;

export default function Segment({
  maxStepCount,
  stepCount,
  numSegments,
  stepHeight,
  data,
  parent,
  style,
}: {
  maxStepCount: number;
  stepCount: number;
  numSegments: number;
  stepHeight: number;
  data: FunnelSegmentData;
  parent: FunnelSegmentData | null;
  style?: React.CSSProperties;
}): React.ReactElement {
  // const height = (data.uniqueCount / maxStepCount) * stepHeight;
  const height = (data.count / stepCount) * stepHeight;
  // const height = stepHeight / numSegments;

  const label = data.label ? (
    <div style={{ textAlign: "center" }}>{data.label}</div>
  ) : null;

  const cvr =
    data.showPercentage && parent && !currentFunnelStepHasBreakdowns(data)
      ? `${round((data.count * 100) / parent.count, 2)}%`
      : "";

  const aggregations =
    data.aggregations && data.aggregations.length > 0
      ? data.aggregations.map(
          ({ label: aLabel, value }) =>
            `${aLabel}: ${(Number.isInteger(value) ? value : Math.round(value * 100) / 100).toLocaleString()}`
        )
      : [];

  const contentLines = [
    data.label,
    `Count: ${data.count.toLocaleString()}`,
    cvr,
    ...aggregations,
  ].filter(Boolean);
  const content = contentLines.join("\n");

  const numLines =
    contentLines.length +
    contentLines
      .map((line) => Math.ceil(line.length / charactersPerLine) - 1)
      .reduce((sum, num) => sum + num, 0);
  const maxLines = height / lineHeight;
  const hideContent = numLines > maxLines;

  return (
    <div className="group/segment relative">
      <div
        className="flex w-full flex-col items-center justify-center overflow-hidden whitespace-pre-wrap px-2 py-0 text-center text-xs font-semibold text-white"
        style={{
          height,
          minHeight: height,
          backgroundColor: data.hexColor,
          wordBreak: "break-word",
          lineHeight: `${lineHeight}px`,
          ...(style || {}),
        }}
      >
        {hideContent ? null : content}
      </div>

      <div
        className="absolute left-full top-0 z-100 hidden min-w-[125px] flex-col items-center justify-center whitespace-pre-wrap rounded-lg border bg-white px-3 py-2 text-center text-xs font-semibold group-hover/segment:flex"
        style={{
          boxShadow: `0 0 8px ${greyHex}`,
          lineHeight: `${lineHeight}px`,
        }}
      >
        {content}
      </div>
    </div>
  );
}
