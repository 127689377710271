import toStartCase from "@hypertune/shared-internal/src/toStartCase";
import { Check } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { DbBillingData } from "@hypertune/shared-internal";
import Skeleton from "react-loading-skeleton";
import { useHypertune } from "../../../generated/hypertune.react";
import twMerge from "../../../lib/twMerge";
import BusinessPage from "../BusinessPage";
import { calendlyUrl, intentPrimaryHex } from "../../../lib/constants";
import {
  BusinessType,
  useBusinessesQuery,
  useUpdatePlanMutation,
} from "../../../generated/graphql";
import Button from "../../../components/buttons/Button";
import parseGraphqlPlan from "../../../lib/parseGraphqlPlan";
import useUpgradeToProAction from "./useUpgradeToProAction";
import { PlanType, allPlans } from "../../../lib/types";
import DowngradeModal from "./DowngradeModal";
import { formatRawDate } from "../../../lib/generic/formatDate";
import Tag from "../../../components/Tag";
import { canEditBusiness } from "../../../lib/query/rolePermissions";
import { switchBusinessRefetchQueries } from "../../../lib/query/refetchQueries";
import NewBusinessModal from "../team/NewBusinessModal";

export default function PlansPage(): React.ReactElement | null {
  useEffect(() => {
    document.title = "Plans - Hypertune";
  }, []);

  const { data } = useBusinessesQuery();
  const billingData: DbBillingData = JSON.parse(
    data?.primaryBusiness?.business.billingDataJson ?? "{}"
  );
  const isEnding = !!billingData.subscriptionEndsAt;
  const canEdit = canEditBusiness(data?.primaryBusiness?.role);

  const currentPlan =
    (parseGraphqlPlan(
      data?.primaryBusiness?.business.plan
    )?.toLowerCase() as PlanType) ?? null;

  const hypertune = useHypertune();
  const descriptions = hypertune.content().plans().descriptions().get();
  const content = hypertune.featureGrid().map((group) => group.get());

  return (
    <BusinessPage containerChildrenClassName="max-w-[1264px] md:w-full lg:w-full xl:w-full px-20">
      <div className="grid min-w-[675px] grid-cols-9">
        <RowName
          name="Plans"
          className="flex-col items-start px-[30px] pt-4 text-h2 font-semibold text-tx-default"
          wrapperClassName="px-0"
        />
        {allPlans.map((plan) => (
          <PlanHeader
            businessId={data?.primaryBusiness?.id}
            businessType={data?.primaryBusiness?.business.type}
            billingData={billingData}
            plan={plan}
            currentPlan={currentPlan}
            description={descriptions[plan]}
            isEnding={isEnding}
            canEdit={canEdit}
          />
        ))}
        {content.map((group) => (
          <>
            {group.name && (
              <FeatureGroupHeaderRow
                name={group.name}
                currentPlan={currentPlan}
                isEnding={isEnding}
              />
            )}
            {group.feature.map((feature) => (
              <>
                <RowName name={feature.name} />
                {allPlans.map((planName) => (
                  <FeatureCell
                    enabled={feature.plans[planName]}
                    isCurrent={planName === currentPlan}
                    isEnding={isEnding}
                  />
                ))}
              </>
            ))}
          </>
        ))}
        <FeatureGroupHeaderRow
          name=""
          currentPlan={currentPlan}
          isEnding={isEnding}
          className="min-h-[42px]"
          cellWrapperClassName="rounded-bl-lg rounded-br-lg"
        />
      </div>
    </BusinessPage>
  );
}

function PlanHeader({
  businessId,
  businessType,
  billingData,
  plan,
  description,
  currentPlan,
  isEnding,
  canEdit,
}: {
  businessId?: string;
  businessType?: BusinessType;
  billingData: DbBillingData;
  plan: PlanType;
  description: string;
  currentPlan: PlanType | null;
  isEnding: boolean;
  canEdit: boolean;
}): React.ReactElement | null {
  const { upgrade } = useUpgradeToProAction();
  const [loading, setLoading] = useState(false);
  const [update] = useUpdatePlanMutation({
    refetchQueries: switchBusinessRefetchQueries,
    awaitRefetchQueries: true,
  });
  const [showNewBusinessModal, setShowNewBusinessModal] = useState(false);
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);

  const isCurrent = plan === currentPlan;
  const isReactivate = isCurrent && !!billingData.subscriptionEndsAt;
  const planIndex = allPlans.findIndex((pl) => pl === plan);
  const currentPlanIndex = allPlans.findIndex((pl) => pl === currentPlan);
  const isUpgrade = currentPlan && currentPlanIndex < planIndex;

  return (
    <Cell
      className="flex flex-col items-stretch gap-3 px-[30px] pb-[10px] pt-[30px]"
      wrapperClassName="px-0 rounded-tl-lg rounded-tr-lg"
      isCurrent={isCurrent}
      isEnding={isEnding}
    >
      <div className="flex flex-row items-center justify-between">
        <p className="text-h4 font-semibold">{toStartCase(plan)}</p>
        {isCurrent && billingData.subscriptionEndsAt && (
          <Tag
            intent="warning"
            text={`Ends at ${formatRawDate(billingData.subscriptionEndsAt)}`}
          />
        )}
      </div>
      <p className="text-md text-tx-muted">{description}</p>
      {currentPlan && businessId ? (
        <Button
          size="x-large"
          weight={isUpgrade || isReactivate ? "filled" : "elevated"}
          disabled={
            !canEdit ||
            (isCurrent && !isReactivate) ||
            (!isCurrent && !isUpgrade && !!billingData.subscriptionEndsAt)
          }
          loading={loading}
          intent={isUpgrade || isReactivate ? "primary" : "neutral"}
          text={
            isCurrent
              ? billingData.subscriptionEndsAt
                ? "Reactivate"
                : "Current plan"
              : plan === "enterprise"
                ? "Contact us"
                : isUpgrade
                  ? "Upgrade"
                  : "Downgrade"
          }
          className="mt-auto shadow-button"
          onClick={async () => {
            if (businessType === BusinessType.Personal) {
              setShowNewBusinessModal(true);
              return;
            }
            if (plan === "enterprise") {
              window.open(calendlyUrl, "_blank");
              return;
            }
            if (isUpgrade && plan === "pro") {
              setLoading(true);
              await upgrade(businessId);
              // No need to disable loading state as we are navigating to stripe.
              return;
            }
            if (isReactivate) {
              setLoading(true);
              await update({
                variables: { input: { businessId, cancel: false } },
              });
              setLoading(false);
              return;
            }
            if (!isUpgrade) {
              setShowDowngradeModal(true);
            }
          }}
        />
      ) : (
        <Skeleton className="h-[32px] w-full" />
      )}
      <div>
        {businessId && currentPlan && showDowngradeModal && (
          <DowngradeModal
            businessId={businessId}
            currentPlan={currentPlan}
            hasNewBilling={Object.keys(billingData).length > 0}
            onClose={() => setShowDowngradeModal(false)}
          />
        )}
        {showNewBusinessModal && (
          <NewBusinessModal
            upgradePlan
            onClose={() => setShowNewBusinessModal(false)}
          />
        )}
      </div>
    </Cell>
  );
}

function FeatureGroupHeaderRow({
  name,
  currentPlan,
  isEnding,
  className,
  cellWrapperClassName,
}: {
  name: string;
  currentPlan: PlanType | null;
  isEnding: boolean;
  className?: string;
  cellWrapperClassName?: string;
}): React.ReactElement | null {
  return (
    <>
      <RowName
        name={name}
        className={twMerge(
          "min-h-[70px] items-end border-b-0 text-lg font-semibold text-tx-default",
          className
        )}
      />
      {allPlans.map((planName) => (
        <Cell
          isCurrent={planName === currentPlan}
          isEnding={isEnding}
          className={twMerge("border-b-0", className)}
          wrapperClassName={cellWrapperClassName}
        />
      ))}
    </>
  );
}

function RowName({
  name,
  className,
  wrapperClassName,
}: {
  name: string;
  className?: string;
  wrapperClassName?: string;
}): React.ReactElement | null {
  return (
    <Cell
      wrapperClassName={twMerge("col-span-3 text-tx-muted", wrapperClassName)}
      className={className}
      isCurrent={false}
      isEnding={false}
    >
      {name}
    </Cell>
  );
}

function FeatureCell({
  enabled,
  isCurrent,
  isEnding,
}: {
  enabled: boolean;
  isCurrent: boolean;
  isEnding: boolean;
}): React.ReactElement | null {
  return (
    <Cell isCurrent={isCurrent} isEnding={isEnding}>
      {enabled && <Check weight="bold" color={intentPrimaryHex} size={20} />}
    </Cell>
  );
}

function Cell({
  children,
  isCurrent,
  isEnding,
  wrapperClassName,
  className,
}: {
  children?: React.ReactNode;
  isCurrent: boolean;
  isEnding: boolean;
  wrapperClassName?: string;
  className?: string;
}): React.ReactElement | null {
  return (
    <div
      className={twMerge(
        "col-span-2 px-[30px]",
        isCurrent && !isEnding && "bg-intent-primary/5",
        isCurrent && isEnding && "bg-intent-warning/10",
        wrapperClassName
      )}
    >
      <div
        className={twMerge(
          "flex h-full w-full flex-row items-center border-b py-[10px]",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}
