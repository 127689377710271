import React, { useCallback, useEffect, useMemo } from "react";
import { DbFunnelSegmentData, FunnelStep } from "@hypertune/shared-internal";
import { ChartBar } from "@phosphor-icons/react";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { Schema } from "@hypertune/sdk/src/shared";
import { NavigateOptions, useSearchParams } from "react-router-dom";
import {
  FunnelDataJsonInput,
  ProjectQuery,
  useFunnelDataQuery,
} from "../../../generated/graphql";
import {
  CommitContext,
  ImplementationContext,
  TimeRange,
} from "../../../lib/types";
import Funnel from "./Funnel";
import SidebarItem from "../../../components/SidebarItem";
import matchesSearch from "../../../lib/generic/matchesSearch";
import { iconColor, iconWeight } from "../../../components/icons/icons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  AnalyticsEditorState,
  resetAnalyticsEditor,
  setActiveAnalyticsView,
  setFunnelSteps,
  setShowNewViewModal,
} from "./analyticsSlice";
import {
  analyticsFromParamKey,
  analyticsToParamKey,
  useActiveSelectedAnalyticsView,
  useSelectedAnalyticsViewId,
} from "./analyticsHooks";
import SidebarContainer from "../SidebarContainer";
import AnalyticsTopBar from "./AnalyticsTopBar";
import ErrorMessageCard from "../../../components/ErrorMessageCard";
import PlusButton from "../../../components/buttons/PlusButton";
import EmptyStateContainer from "../../../components/EmptyStateContainer";
import { useHypertune } from "../../../generated/hypertune.react";
import useSearchParamsState from "../../../app/useSearchParamsState";
import getFunnelStepPayloadObjectTypeName from "../../../lib/getFunnelStepPayloadObjectTypeName";
import {
  derivedFieldHasExpressionError,
  getDerivedFieldsCommitContext,
} from "./step/DerivedFields";
import {
  filterHasExpressionError,
  getFilterCommitContext,
} from "./step/SelectFilter";

const searchTextQueryParamKey = "analytics_search";
const focusModeEnabledPramKey = "analytics_focus_mode";

export default function AnalyticsEditor({
  meId,
  isVisible,
  canEdit,
  projectId,
  views,
  schema,
  implementationContext,
}: {
  meId: string;
  isVisible: boolean;
  canEdit: boolean;
  projectId: string;
  views: ProjectQuery["project"]["analyticsViews"];
  schema: Schema;
  implementationContext: ImplementationContext;
}): React.ReactElement {
  const content = useHypertune().content().analytics();
  const dispatch = useAppDispatch();
  const editorState = useAppSelector((state) => state.projectAnalytics);

  const [searchText, setSearchText] = useSearchParamsState(
    searchTextQueryParamKey,
    ""
  );

  const activeView = useActiveSelectedAnalyticsView();
  const [selectedViewId, setSelectedViewId] = useSelectedAnalyticsViewId();

  useEffect(() => {
    dispatch(
      activeView
        ? setActiveAnalyticsView(JSON.parse(activeView.funnelStepsJson))
        : resetAnalyticsEditor()
    );
  }, [dispatch, activeView]);

  const [focusModeEnabled, setFocusModeEnabled] = useSearchParamsState(
    focusModeEnabledPramKey,
    false
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const timeRange: TimeRange = useMemo(
    () => ({
      start: new Date(
        searchParams.get(analyticsFromParamKey) ??
          // 30 days ago
          startOfDay(subDays(new Date(), 29))
      ),
      end: new Date(
        searchParams.get(analyticsToParamKey) ?? endOfDay(new Date())
      ),
    }),
    [searchParams]
  );
  const setSelectedTimeRange = useCallback(
    (newTimeRange: TimeRange, navigateOpts?: NavigateOptions) => {
      setSearchParams(
        (currentSearchParams) => ({
          ...Object.fromEntries(currentSearchParams),
          [analyticsFromParamKey]: newTimeRange.start.toJSON(),
          [analyticsToParamKey]: newTimeRange.end.toJSON(),
        }),
        navigateOpts
      );
    },
    [setSearchParams]
  );
  useEffect(() => {
    // Ensure time range is initialized.
    if (
      isVisible &&
      (!searchParams.get(analyticsFromParamKey) ||
        !searchParams.get(analyticsToParamKey))
    ) {
      setSelectedTimeRange(timeRange, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const commitContext = useMemo(
    () => ({
      schema,
      ...implementationContext,
    }),
    [schema, implementationContext]
  );
  const selectedHasError = useMemo(
    () => funnelHasError(commitContext, editorState.steps),
    [commitContext, editorState.steps]
  );

  return (
    <div
      className={
        isVisible
          ? "flex- flex flex-grow items-stretch overflow-hidden"
          : "hidden"
      }
    >
      <SidebarContainer
        searchText={searchText}
        setSearchText={setSearchText}
        actions={
          canEdit ? (
            <div className="text-tx-default">
              <PlusButton onClick={() => dispatch(setShowNewViewModal(true))} />
            </div>
          ) : undefined
        }
        childrenClassName={views.length === 0 ? "pb-[5px]" : ""}
      >
        {views.length === 0 && (
          <EmptyStateContainer
            icon={<ChartBar />}
            content={content.emptyState().get()}
            buttonOnClick={
              canEdit ? () => dispatch(setShowNewViewModal(true)) : undefined
            }
          />
        )}
        {views
          .filter((view) => matchesSearch(searchText, [view.name]))
          .map((view) => {
            const isSelected = selectedViewId === view.id;
            return (
              <SidebarItem
                icon={
                  <ChartBar
                    color={iconColor(isSelected)}
                    weight={iconWeight(isSelected)}
                  />
                }
                title={view.name}
                onClick={() => setSelectedViewId(view.id)}
                isSelected={isSelected}
                className="px-3 py-[11px]"
              />
            );
          })}
      </SidebarContainer>
      <div className="flex h-full w-full flex-grow flex-col items-stretch overflow-hidden bg-bg-light bg-dotted">
        {selectedViewId && activeView && (
          <>
            <AnalyticsTopBar
              viewId={activeView.id}
              viewName={activeView.name}
              timeRange={timeRange}
              setSelectedTimeRange={setSelectedTimeRange}
              canEdit={canEdit}
              selectedHasError={selectedHasError}
              focusModeEnabled={focusModeEnabled}
              setFocusModeEnabled={setFocusModeEnabled}
            />
            <AnalyticsEditorInner
              meId={meId}
              projectId={projectId}
              selectedViewId={selectedViewId}
              commitContext={commitContext}
              analyticsEditorState={editorState}
              timeRange={timeRange}
              canEdit={canEdit}
              hasError={selectedHasError}
              focusModeEnabled={focusModeEnabled}
            />
          </>
        )}
      </div>
    </div>
  );
}

function AnalyticsEditorInner({
  meId,
  canEdit,
  hasError,
  projectId,
  selectedViewId,
  commitContext,
  analyticsEditorState,
  timeRange,
  focusModeEnabled,
}: {
  meId: string;
  canEdit: boolean;
  hasError: boolean;
  projectId: string;
  selectedViewId: string;
  commitContext: CommitContext;
  analyticsEditorState: AnalyticsEditorState;
  timeRange: TimeRange;
  focusModeEnabled: boolean;
}): React.ReactElement {
  const dispatch = useAppDispatch();
  const { steps, hasChanges } = analyticsEditorState;

  const input: FunnelDataJsonInput = {
    start: timeRange.start.toJSON(),
    end: timeRange.end.toJSON(),

    ...(hasChanges && !hasError
      ? { projectId, stepsJson: JSON.stringify(steps) }
      : { viewId: selectedViewId }),
  };
  const { loading, error, data } = useFunnelDataQuery({
    variables: { input },
  });

  const dbFunnelData = data
    ? // TODO: Validate JSON with zod
      (JSON.parse(data.funnelDataJson) as DbFunnelSegmentData[][])
    : null;

  return (
    <div className="h-full w-full overflow-auto p-4">
      {loading ||
      (!hasError && dbFunnelData && dbFunnelData.length !== steps.length) ? (
        <Funnel.LoadingSkeleton focusModeEnabled={focusModeEnabled} />
      ) : error ? (
        <ErrorMessageCard error={error} />
      ) : dbFunnelData ? (
        <Funnel
          meId={meId}
          canEdit={canEdit}
          commitContext={commitContext}
          steps={steps}
          setSteps={(newSteps) => {
            dispatch(setFunnelSteps(newSteps));
          }}
          data={dbFunnelData}
          focusModeEnabled={focusModeEnabled}
        />
      ) : null}
    </div>
  );
}

function funnelHasError(
  commitContext: CommitContext,
  steps: FunnelStep[]
): boolean {
  return steps.some((step) => {
    const payloadObjectTypeName = getFunnelStepPayloadObjectTypeName(
      commitContext,
      step
    );
    if (
      step.filter &&
      filterHasExpressionError(
        getFilterCommitContext(
          commitContext.schema,
          step.type,
          payloadObjectTypeName
        ),
        step.filter
      )
    ) {
      return true;
    }
    if (!step.derivedFields) {
      return false;
    }
    const stepDerivedFieldCommitContext = getDerivedFieldsCommitContext(
      commitContext.schema,
      step.type,
      payloadObjectTypeName,
      step.derivedFields ?? []
    );

    return step.derivedFields.some((derivedField) =>
      derivedFieldHasExpressionError(
        stepDerivedFieldCommitContext,
        derivedField
      )
    );
  });
}
