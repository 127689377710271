import {
  CommitData,
  EventTypeMap,
  Expression,
  Schema,
  SplitMap,
  hash,
  stableStringify,
} from "@hypertune/sdk/src/shared";
import {
  reconcileSchemaAndImplementation,
  splitSchemaCode,
  toInt,
} from "@hypertune/shared-internal";
import { CommitQuery, ProjectBranchQuery } from "../../generated/graphql";

export default function getCommitData(
  commit:
    | ProjectBranchQuery["projectBranch"]["activeCommit"]
    | CommitQuery["commit"]
): Omit<CommitData, "projectId"> & {
  readOnlySchemaCode: string;
  editableSchemaCode: string;
} {
  // Ensure that events in schema and event types always match.
  const rawSchema: Schema = JSON.parse(commit.schema.json);
  const rawEventTypes: EventTypeMap = JSON.parse(
    commit.implementation.eventTypesJson
  );
  const rawSplits: SplitMap = JSON.parse(commit.implementation.splitsJson);
  const rawExpression: Expression = JSON.parse(
    commit.implementation.expressionJson
  );
  const {
    newSchema: schema,
    newSchemaCode: schemaCode,
    newSplits: splits,
    newEventTypeMap: eventTypes,
    newExpression: expression,
  } = reconcileSchemaAndImplementation(
    rawSchema,
    rawSplits,
    rawEventTypes,
    rawExpression
  );

  const { readOnlySchemaCode, editableSchemaCode } = splitSchemaCode(
    schema,
    schemaCode
  );

  // TODO: Validate JSON with zod
  return {
    id: toInt(commit.id),
    schema,
    readOnlySchemaCode,
    editableSchemaCode,
    schemaCode,
    splits,
    eventTypes,
    expression,
    features: JSON.parse(commit.implementation.featuresJson),
    config: JSON.parse(commit.configJson),
    hash: String(
      hash(
        stableStringify({
          commitId: toInt(commit.id),
          config: JSON.parse(commit.configJson),
        })
      )
    ),
  };
}
