import {
  ChartBar,
  CursorClick,
  Eye,
  Pencil,
  User,
} from "@phosphor-icons/react";
import { useCallback } from "react";
import { useUpdateAnalyticsViewMutation } from "../../../generated/graphql";
import BigIcon from "../../../components/BigIcon";
import RenameTopBar from "../../../components/RenameTopBar";
import { whiteHex } from "../../../lib/constants";
import TimeRangePicker from "../../../components/TimeRangePicker";
import { TimeRange } from "../../../lib/types";
import SaveButton from "./SaveButton";
import DeleteButton from "./DeleteButton";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import TopBarDropdown from "../../../components/TopBarDropdown";
import {
  CountsVariant,
  countVariants,
  setCountVariant,
} from "./analyticsSlice";
import ResetButton from "./ResetButton";
import { refetchAnalyticsViewsQueries } from "../../../lib/query/refetchQueries";
import ErrorMessage from "../../../components/ErrorMessage";
import ModeSwitcher from "../../../components/ModeSwitcher";
import { useHypertune } from "../../../generated/hypertune.react";

export default function AnalyticsTopBar({
  canEdit,
  selectedHasError,
  viewId,
  viewName,
  timeRange,
  setSelectedTimeRange,
  focusModeEnabled,
  setFocusModeEnabled,
}: {
  canEdit: boolean;
  selectedHasError: boolean;
  viewId: string;
  viewName: string;
  timeRange: TimeRange;
  setSelectedTimeRange: (newTimeRange: TimeRange) => void;
  focusModeEnabled: boolean;
  setFocusModeEnabled: (enabled: boolean) => void;
}): React.ReactElement {
  const hypertune = useHypertune();
  const dispatch = useAppDispatch();
  const selectedCountVariant = useAppSelector(
    (state) => state.projectAnalytics.countVariant
  );
  const [updateView, { loading }] = useUpdateAnalyticsViewMutation({
    refetchQueries: refetchAnalyticsViewsQueries,
    awaitRefetchQueries: true,
  });

  const rename = useCallback(
    async (newName: string) => {
      const trimmedName = newName.trim();
      if (loading || !trimmedName) {
        return;
      }
      try {
        await updateView({
          variables: {
            input: {
              id: viewId,
              name: trimmedName,
            },
          },
        });
      } catch (error) {
        console.error("[updateAnalyticsView] error:", error);
      }
    },
    [updateView, loading, viewId]
  );
  const countVariantOptions = countVariants.map((variant) => ({
    value: variant,
    label: `${variant} count`,
    icon:
      variant === "Unique" ? (
        <User weight="regular" />
      ) : (
        <CursorClick weight="regular" />
      ),
    showIconWhenSelected: true,
  }));

  return (
    <RenameTopBar
      icon={
        <BigIcon
          size={28}
          icon={<ChartBar color={whiteHex} weight="fill" />}
          className="bg-type-icon-blue"
        />
      }
      label={viewName}
      entityName="funnel"
      disableConfirmDialog
      rename={rename}
      actions={
        canEdit ? (
          <div className="flex flex-row gap-2">
            {hypertune.showAnalyticsFocusMode({ fallback: false }) && (
              <ModeSwitcher
                modes={[
                  { icon: <Pencil />, value: false },
                  { icon: <Eye />, value: true },
                ]}
                selectedMode={focusModeEnabled}
                setMode={setFocusModeEnabled}
                className="shadow-button"
              />
            )}
            <DeleteButton />
            <ResetButton />
            <SaveButton disabled={selectedHasError} />
          </div>
        ) : undefined
      }
      errorMessage={
        selectedHasError && <ErrorMessage errorMessage="Funnel has error" />
      }
    >
      <div className="flex flex-row items-center gap-2">
        <TimeRangePicker range={timeRange} setRange={setSelectedTimeRange} />
        <TopBarDropdown<CountsVariant>
          value={
            countVariantOptions.find(
              (option) => option.value === selectedCountVariant
            ) || null
          }
          placeholder=""
          options={{
            type: "options",
            options: countVariantOptions,
          }}
          onChange={(newOption) => {
            if (newOption) {
              dispatch(setCountVariant(newOption.value));
            }
          }}
          dropdownStyle={{
            hideSearch: true,
            caret: "down",
            buttonClassName:
              "py-[7px] px-[8px] leading-none font-medium text-tx-default whitespace-nowrap",
            panelClassName: "-mt-4 data-top:mt-4 data-top:-mb-4",
            optionClassName: "font-medium",
          }}
        />
      </div>
    </RenameTopBar>
  );
}
