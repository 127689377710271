import { useCallback, useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { Expression, Schema } from "@hypertune/sdk/src/shared";
import {
  formatFieldSchemaName,
  queryObjectTypeName,
  rootFieldName,
  rootObjectTypeName,
  toStartCase,
  toWords,
} from "@hypertune/shared-internal";
import Modal from "../../../components/Modal";
import {
  ProjectBranchQuery,
  ProjectQuery,
  useProjectLazyQuery,
  useProjectSetupQuery,
} from "../../../generated/graphql";
import Step2GenerateClient, {
  FrameworkDefinition,
} from "./Step2GenerateClient";
import Step3UseClient from "./Step3UseClient";
import Step1CreateFlag from "./Step1CreateFlag";
import { queryPollIntervalMs } from "../../../lib/constants";
import ProgressView from "./ProgressView";
import { useAppDispatch } from "../../../app/hooks";
import useStickyState from "../../../lib/hooks/useStickyState";
import { setHideTooltips } from "../../../app/appSlice";
import HeaderSteps from "../../../components/HeaderSteps";
import Step0Welcome from "./Step0Welcome";
import useShowSetupModal, {
  showSetupModalParamName,
} from "./useShowSetupModal";
import formatSnippet from "../../../lib/formatSnippet";
import { getQueryToken } from "../../../lib/tokens";
import getCommitData from "../../../lib/query/getCommitData";
import { useHypertune } from "../../../generated/hypertune.react";
import { useProjectSelectedState } from "../projectHooks";
import { selectedFieldPathParamKey, urlPathSeparator } from "../logicHooks";

const process = "process";
const dotEnv = ".env";

export default function ProjectSetupModal({
  project,
  branch,
}: {
  project: ProjectQuery["project"];
  branch?: ProjectBranchQuery["projectBranch"];
}): React.ReactElement | null {
  const [isVisible, setIsVisible] = useShowSetupModal();
  const { setSelected } = useProjectSelectedState();

  const commitData = branch ? getCommitData(branch.activeCommit) : null;

  useEffect(() => {
    // Set whether the modal is visible on first load.
    if (project.showOnboarding) {
      setIsVisible(true, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useAppDispatch();
  const hypertune = useHypertune();
  const hypertuneIsReady = hypertune.isReady();

  const { data, startPolling, stopPolling } = useProjectSetupQuery({
    variables: { projectId: project.id },
    fetchPolicy: "no-cache",
  });
  const [refetchProject] = useProjectLazyQuery({
    variables: { projectId: project.id },
    fetchPolicy: "network-only",
  });

  const [flagName, setFlagName] = useState("");
  const [flagValue, setFlagValue] = useState(true);
  const [flagDescription, setFlagDescription] = useState("");
  const [flagCreated, setFlagCreated] = useState(false);
  const flagSchemaName = formatFieldSchemaName(flagName);

  const [selectedFrameworkIndex, setSelectedFrameworkIndex] =
    useStickyState<number>(0, "onboarding-framework-index");
  const [codeGenerated, setCodeGenerated] = useState(false);
  const [appConnected, setAppConnected] = useState(false);
  const [navigateToNewFlag] = useState(project.showOnboarding);
  const [setupCompleted, _setSetupCompleted] = useState(project.setupCompleted);
  const setSetupCompleted = useCallback(
    (newSetupCompleted: boolean) => {
      _setSetupCompleted((oldSetupCompleted: boolean) => {
        if (!oldSetupCompleted && newSetupCompleted) {
          // Refetch project on completion to avoid
          // auto-opening and confetti when opened again.
          refetchProject().catch((error) =>
            console.error(`[refetchProject] failed with: ${error}`)
          );
        }
        return newSetupCompleted;
      });
    },
    [_setSetupCompleted, refetchProject]
  );

  const [step, setStep] = useState<number>(project.showOnboarding ? 0 : 1);
  const [stepInitialized, setStepInitialized] = useState(false);

  const projectToken =
    getQueryToken(JSON.parse(project.tokensJson || "{}")) ??
    "YOUR_MAIN_PROJECT_TOKEN";

  const formatDocs = useCallback(
    (docs: string): string => {
      if (!docs) {
        return "";
      }
      return formatSnippet(
        docs
          .replaceAll("{projectToken}", projectToken)
          .replaceAll("{processEnv}", process + dotEnv)
          .replaceAll("{exampleFlag}", flagSchemaName)
          .replaceAll(
            "{example_flag}",
            toWords(flagSchemaName)
              .map((word) => word.toLowerCase())
              .join("_")
          ),
        {
          projectToken,
          variableValues: {},
          queryCode: `query TestQuery {
  root(
    context: {
      environment: "DEVELOPMENT",
      user: {
        id: "test_id"
        name: "Test"
        email: "test@test.com"
      }
    }
  ) {
    ${flagSchemaName}
  }
}`,
        }
      );
    },
    [flagSchemaName, projectToken]
  );

  useEffect(() => {
    if (isVisible && stepInitialized && hypertuneIsReady) {
      hypertune.events().setupStepViewed({ args: { stepNumber: step } });
      console.debug("ProjectSetupModal logged setupStepViewed:", { step });
    }
    // We don't want changes to root node to trigger the hook.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, stepInitialized, hypertuneIsReady, step]);

  useEffect(() => {
    if (!commitData?.schema || !flagCreated) {
      return;
    }
    const existingFlag = findExistingBooleanFlag(commitData.schema);
    if (!existingFlag) {
      setFlagCreated(false);
    }
    // Changes to flagCreated alone shouldn't trigger this hook.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commitData?.schema]);

  useEffect(() => {
    if (!commitData?.expression || !commitData.schema || !flagSchemaName) {
      return;
    }
    setFlagDescription(
      (currentDescription) =>
        commitData.schema.objects[rootObjectTypeName]?.fields?.[flagSchemaName]
          ?.description ?? currentDescription
    );
    setFlagValue(
      (currentFlagValue) =>
        findBooleanFlagValue(commitData.expression, flagSchemaName) ??
        currentFlagValue
    );
  }, [commitData?.expression, commitData?.schema, flagSchemaName]);

  useEffect(() => {
    if (!data || !commitData?.schema || !commitData?.expression) {
      return;
    }
    setCodeGenerated(codeGenerated || data.project.generatedClient);
    setAppConnected(appConnected || data.project.evaluatedFlags);

    if (!flagName) {
      const existingFlag = findExistingBooleanFlag(commitData.schema);
      if (existingFlag) {
        setFlagCreated(true);
        setFlagName(toStartCase(existingFlag.name));
        setFlagDescription(existingFlag.description);
      }
      if (!stepInitialized && existingFlag) {
        if (data.project.generatedClient) {
          setStep(3);
        } else {
          setStep(2);
        }
      }
    }
    if (!stepInitialized) {
      setStepInitialized(true);
    }
    // We don't want changes to flagName to trigger the hook.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    commitData?.schema,
    commitData?.expression,
    codeGenerated,
    appConnected,
    stepInitialized,
  ]);

  startPolling(queryPollIntervalMs);

  useEffect(() => {
    dispatch(setHideTooltips(isVisible));
  }, [dispatch, isVisible]);

  const onClose = useCallback(() => {
    if (navigateToNewFlag && flagName) {
      setSelected({
        view: "logic",
        searchParams: new URLSearchParams({
          [selectedFieldPathParamKey]: `${rootFieldName}${urlPathSeparator}${flagSchemaName}`,
          [showSetupModalParamName]: "0",
        }),
      });
      return;
    }
    setIsVisible(false);
  }, [setIsVisible, setSelected, navigateToNewFlag, flagName, flagSchemaName]);

  if (!isVisible) {
    stopPolling();
    return null;
  }
  if (!data || !branch || !commitData || !stepInitialized) {
    // Return null to avoid jumping between steps during initialization.
    return null;
  }

  const allFrameworks: FrameworkDefinition[] = [];
  const step2Data = new Map<number, string>();
  const step3Data = new Map<number, string>();

  hypertune
    .content()
    .onboarding()
    .frameworkDocs()
    .forEach((docNode, index) => {
      const doc = docNode.get();

      allFrameworks.push({
        id: doc.framework,
        label: doc.frameworkTitle,
        requireCodegen: doc.requireCodegen,
      });
      step2Data.set(index, doc.markdown1);
      step3Data.set(index, doc.markdown2);
    });

  const selectedFrameworkDef = allFrameworks[selectedFrameworkIndex];

  return (
    <Modal
      modalStyle={step === 0 ? "medium" : "large"}
      title={
        step === 0 ? (
          <div />
        ) : (
          <HeaderSteps
            selectedStep={step}
            setSelectedStep={setStep}
            steps={[
              {
                text: "Create a flag",
                isCompleted: flagCreated,
              },
              {
                text: "Generate client",
                isCompleted:
                  codeGenerated || !selectedFrameworkDef?.requireCodegen,
              },
              {
                text: "Use client",
                isCompleted: appConnected,
              },
            ]}
          />
        )
      }
      disableAwayClickClose={!setupCompleted}
      style={{ height: "100%", maxHeight: "900px", overflow: "hidden" }}
      childrenStyle={{ padding: 0, overflowX: "hidden", overflowY: "auto" }}
      onClose={onClose}
    >
      <div className="flex h-full w-[980px] flex-row">
        {step === 0 ? (
          <Step0Welcome onNext={() => setStep(1)} onClose={onClose} />
        ) : (
          <>
            <div className="flex h-full w-[60%]  flex-col border-r">
              {step === 1 && (
                <Step1CreateFlag
                  commitData={commitData}
                  project={project}
                  branch={branch}
                  onNext={() => {
                    setFlagCreated(true);
                    setStep(2);
                  }}
                  flagCreated={flagCreated}
                  flagName={flagName}
                  flagSchemaName={flagSchemaName}
                  setFlagName={setFlagName}
                  flagValue={flagValue}
                  setFlagValue={setFlagValue}
                  flagDescription={flagDescription}
                  setFlagDescription={setFlagDescription}
                />
              )}
              {step === 2 && (
                <Step2GenerateClient
                  codeGenerated={
                    codeGenerated || !selectedFrameworkDef?.requireCodegen
                  }
                  selectedFrameworkIndex={selectedFrameworkIndex}
                  setSelectedFrameworkIndex={setSelectedFrameworkIndex}
                  allFrameworks={allFrameworks}
                  data={step2Data}
                  onBack={() => setStep(1)}
                  onNext={() => setStep(3)}
                  formatDocs={formatDocs}
                />
              )}
              {step === 3 && (
                <Step3UseClient
                  appConnected={appConnected}
                  selectedFrameworkIndex={selectedFrameworkIndex}
                  data={step3Data}
                  onBack={() => setStep(2)}
                  onNext={onClose}
                  formatDocs={formatDocs}
                />
              )}
            </div>
            <div className="flex h-full w-[40%] flex-col bg-bg-light bg-dotted">
              <ProgressView
                step={step}
                flagName={flagName}
                flagValue={flagValue}
                codeGenerated={codeGenerated}
                appConnected={appConnected}
                selectedFramework={selectedFrameworkDef as FrameworkDefinition}
                setupCompleted={setupCompleted}
                setSetupCompleted={setSetupCompleted}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

function findExistingBooleanFlag(schema: Schema): {
  name: string;
  description: string;
} | null {
  const querySchema = schema.objects[queryObjectTypeName];
  if (!querySchema) {
    return null;
  }

  const rootSchema = Object.entries(querySchema.fields)
    .map(([, field]) => {
      if (
        field.valueType.type === "FunctionValueType" &&
        field.valueType.returnValueType.type === "ObjectValueType"
      ) {
        return schema.objects[field.valueType.returnValueType.objectTypeName];
      }
      return undefined;
    })
    .find(Boolean);
  if (!rootSchema) {
    return null;
  }

  return (
    Object.entries(rootSchema.fields)
      .map(([fieldName, field]) => {
        if (
          field.valueType.type === "FunctionValueType" &&
          field.valueType.returnValueType.type === "BooleanValueType" &&
          fieldName !== "exampleFlag"
        ) {
          return {
            name: fieldName,
            description: field.description ?? "",
          };
        }
        return undefined;
      })
      .find(Boolean) || null
  );
}

function findBooleanFlagValue(
  expression: Expression | null,
  flagName: string
): boolean | undefined {
  if (!expression) {
    return undefined;
  }
  if (expression.type === "ApplicationExpression") {
    return findBooleanFlagValue(expression.function, flagName);
  }
  if (expression.type === "FunctionExpression") {
    return findBooleanFlagValue(expression.body, flagName);
  }
  if (expression.type !== "ObjectExpression") {
    return undefined;
  }
  return Object.entries(expression.fields)
    .map(([, field]) => {
      if (
        field?.type === "FunctionExpression" &&
        field.body?.type === "ObjectExpression"
      ) {
        return Object.entries(field.body.fields)
          .map(([fieldName, fieldValue]) => {
            if (
              fieldName === flagName &&
              fieldValue?.type === "FunctionExpression" &&
              fieldValue.body?.type === "SwitchExpression" &&
              fieldValue.body.default?.type === "BooleanExpression"
            ) {
              // We set the value as default for the switch expression.
              return fieldValue.body.default.value;
            }
            return undefined;
          })
          .find((value) => value !== undefined);
      }
      return undefined;
    })
    .find((value) => value !== undefined);
}
