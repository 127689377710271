import {
  ApplicationExpression,
  ComparisonOperator,
  CountMap,
  DbAssignmentWithNullableEntries,
  EventTypeMap,
  Expression,
  Permissions,
  Schema,
  SplitMap,
  VariableExpression,
} from "@hypertune/sdk/src/shared";
import { DbFeaturePathValues } from "@hypertune/shared-internal";
import { Variable } from "@hypertune/shared-internal/src/expression/types";
import { Intent } from "../components/intent";

export const allPlans = ["free", "pro", "enterprise"] as const;
export type PlanType = (typeof allPlans)[number];

// Editor State

export type EditorState = {
  projectId: string;
  debug: DebugEditorState;
  logs: LogsEditorState;
};

export type ExpressionEditorState = {
  selectedItem: SelectedItem | null;
  collapsedExpressionIds: { [expressionId: string]: true };
};

export type SelectedItem = {
  type: "variable" | "expression";
  id: string;
};

export type DebugEditorState = {
  expressionEditorState: ExpressionEditorState;
  queryCode: string;
  variablesCode: string;
  markQueryFieldArgumentsPartial: boolean;
  userAgent: string;
  referer: string;
  hasChanges: boolean;
};

export type LogsEditorState = {
  selectedLogId: string | null;
};

// Utility Types

export type StrictDeepPartial<T> = T extends (infer U)[]
  ? StrictDeepPartial<U>[]
  : T extends object
    ? { [P in keyof T]?: StrictDeepPartial<T[P]> }
    : T;

export type TopLevelEnum = {
  typeName: string;
} | null;

export type EditTracking = {
  id: string;
  track: React.Dispatch<React.SetStateAction<Set<string>>>;
};

export type ImplementationContext = {
  splits: SplitMap;
  setSplits: (newSplits: SplitMap) => void;
  eventTypes: EventTypeMap;
};

export type CommitContext = {
  schema: Schema;
} & ImplementationContext;

export type ExpressionControlContext = {
  meId: string;
  commitContext: CommitContext;
  evaluations: CountMap; // Key: expressionId
  expressionEditorState: ExpressionEditorState;
  setSelectedFieldPath?: (newSelectedFieldPath: string) => void;
  setExpressionEditorState: (
    newExpressionEditorState: ExpressionEditorState
  ) => void;
  setSchemaSplitsAndEventTypes?: (
    schema: Schema,
    newSplits: SplitMap,
    newEventTypes: EventTypeMap
  ) => void;
  ignoreErrors: boolean;
  hideOptions?: boolean;
  disableVariableCreation?: boolean;
  disablePermissionsManagement?: boolean;
  readOnly: boolean;
  expandByDefault: boolean;
  disableStringListSplitAndWarnings?: boolean;
  showComparisonExpressionPanel?: boolean;
  showSwitchExpressionPanels?: boolean;
  expressionIdToIntent?: { [expressionId: string]: Intent };
  includeComparisonOperator?: (operator: ComparisonOperator) => boolean;

  // These fields need to be updated as we descend the expression tree
  fullFieldPath: string;
  resolvedPermissions: Permissions;
  keepInObjectField?: boolean;
  variableGroupIndex?: number;
};

export type IncludeExpressionOptionFunction = (args: {
  expressionOption: Expression;
  expressionOptionParent: Expression | null;
}) => boolean;

/**
 * A LiftFunction defines what should happen when an expression with an options
 * button has 'Convert to variable' clicked. The implementation should lift
 * the given expression to a variable around the parent.
 *
 * @example // Pseudocode
 * // Lifting 'a', a string expression from an object expression:
 * const parentObjExpr = { a: 'value' }
 * parentObjExpr.lift(a) == ((aArg) => ({ a: aArg }))('value');
 *
 * @example // Pseudocode
 * // Lifting 1, a float expression, from an arithmetic expression:
 * const arithmeticExpr = 1 + 2
 * arithmeticExpr.lift(1) == ((aArg) => aArg + 2)(1);
 *
 * @example // Pseudocode
 * // Lifting an arithmetic expression, from a function expr it depends on:
 * const funcExpr = ((aArg) => aArg + 2);
 * funcExpr.lift(aArg + 2) == (
 *  (arithFn) => (
 *    (aArg) => arithFn(aArg)
 *  )(aArg)
 * )((aArg) => aArg + 2);
 * */
export type LiftFunction = (child: {
  /** The expression to lift. This may be a direct child, grandchild or lower. */
  argument: Expression;
  replacedVariableIdToNewVariable: { [oldVariableId: string]: Variable };
  /** Returns the expression associated with the ExpressionControl, with the argument replaced by the given replacement */
  replaceArgument: (
    replacement: VariableExpression | ApplicationExpression
  ) => Expression;
  /** If not provided, one will automatically be generated */
  // TODO: Remove as not that useful and adds complexity
  newVariableName: string | null;
  isNew: boolean;
  keepInObjectField: boolean;
}) => void;

/**
 * This is passed from an application expression control to its children that
 * hold variables (i.e. arguments of applications that have function
 * expressions).
 */
export type VariableContext = {
  name: string;
  rename: (newName: string) => void;
  drop: () => void;
};

export type TimeRange = {
  start: Date;
  end: Date;
};

export type FunnelSegmentData = {
  breakdownPathValuesList: DbFeaturePathValues[];
  assignmentList: DbAssignmentWithNullableEntries[];
  aggregations?: { label: string; value: number }[];
  count: number;
  showPercentage: boolean;
  label: string;
  hexColor: string;
};

export type DiffCommitData = {
  schema: Schema;
  expression: Expression | null;
  splits: SplitMap;
};

export type CommitMetadata = {
  message?: string;
  createdAt?: string;
  author: {
    displayName: string;
    imageUrl: string;
  };
};
