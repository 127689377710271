import stableStringify from "./stableStringify";
import { CountMap, Event, Exposure, Expression, ReductionLogs } from "../types";

export function getEmptyLogs(): ReductionLogs {
  return {
    evaluations: {},
    events: {},
    exposures: {},
  };
}

export function getEvaluationLogs(expression: Expression): ReductionLogs {
  if (expression.isTransient) {
    return getEmptyLogs();
  }
  return {
    evaluations: { [expression.id]: 1 },
    events: {},
    exposures: {},
  };
}

export function getEventLogs(event: Event): ReductionLogs {
  return {
    evaluations: {},
    events: { [stableStringify(event)]: 1 },
    exposures: {},
  };
}

export function getExposureLogs(exposure: Exposure): ReductionLogs {
  return {
    evaluations: {},
    events: {},
    exposures: {
      [stableStringify(exposure)]: 1,
    },
  };
}

export function mergeLogs(
  a: ReductionLogs,
  ...bs: ReductionLogs[]
): ReductionLogs {
  return {
    evaluations: mergeCountMapsFromLogs("evaluations", a, bs),
    events: mergeCountMapsFromLogs("events", a, bs),
    exposures: mergeCountMapsFromLogs("exposures", a, bs),
  };
}

function mergeCountMapsFromLogs<K extends keyof ReductionLogs>(
  countMapKey: K,
  a: ReductionLogs,
  bs: ReductionLogs[]
): CountMap {
  const result: CountMap = { ...a[countMapKey] };
  bs.forEach((b) => {
    Object.entries(b[countMapKey]).forEach(([key, value]) => {
      result[key] = (result[key] || 0) + value;
    });
  });
  return result;
}
