import React, { RefObject, useEffect, useRef } from "react";
import { twJoin } from "tailwind-merge";
import ErrorTooltip from "./ErrorTooltip";
import {
  Intent,
  intentToBorderClassName,
  intentToLightBgClassName,
  intentToTextColorName,
} from "../intent";
import twMerge from "../../lib/twMerge";
import InputLabel from "./InputLabel";

const sizeClasses = {
  default: "h-[30px]",
  medium: "h-[34px] px-[14px] py-[9px]",
  large: "h-[40px]",
} as const;
type Size = keyof typeof sizeClasses;

export type InputProps = {
  type: React.HTMLInputTypeAttribute;
  label?: string;
  labelVariant?: "large" | "default" | "muted";
  value: string;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
  error?: React.ReactNode;
  errorPosition?: "top" | "top-end";
  onChange: (newValue: string) => void;
  onPaste?: (newValue: string) => boolean;
  onEnter?: () => void;
  trimOnBlur?: boolean;
  onBlur?: () => void;
  focusOnMount?: boolean;
  placeholder?: string;
  readOnly: boolean;
  autoComplete?: string;
  maxLength?: number;
  style?: React.CSSProperties;
  inputClassName?: string;
  noFocusHighlight?: boolean;
  size?: Size;
  intent?: Intent;
  textTransform?: "uppercase" | "lowercase" | "capitalize";
  elementRef?: RefObject<HTMLInputElement>;
};

export default function Input({
  type,
  label,
  labelVariant = "default",
  value,
  icon,
  endIcon,
  error,
  errorPosition = "top",
  onChange,
  onPaste,
  onEnter,
  trimOnBlur,
  onBlur,
  focusOnMount,
  placeholder,
  readOnly,
  autoComplete,
  maxLength,
  style,
  inputClassName,
  noFocusHighlight,
  size = "default",
  intent = "neutral",
  textTransform,
  elementRef,
}: InputProps): React.ReactElement {
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusOnMount) {
      const el = elementRef ? elementRef.current : input.current;
      if (!el) {
        return;
      }
      el.focus();
    }
  }, [focusOnMount, elementRef, input]);

  return (
    <>
      <InputLabel text={label} variant={labelVariant} />
      <div
        className={twJoin(
          "flex flex-row items-center",
          "rounded-lg border border-bd-darker p-2",
          sizeClasses[size],
          intent !== "neutral" && [
            intentToTextColorName(intent),
            intentToLightBgClassName(intent),
            intentToBorderClassName(intent),
          ],
          readOnly
            ? "bg-bg-light"
            : noFocusHighlight
              ? ""
              : error || intent === "danger"
                ? "border-intent-danger focus-within:border-intent-danger focus-within:shadow-inputs-sm-error"
                : "focus-within:border-base-blue focus-within:shadow-inputs hover:border-base-blue"
        )}
        style={{
          ...(style || {}),
        }}
      >
        {icon && <span>{icon}</span>}
        <input
          ref={elementRef || input}
          type={type}
          className={twMerge(
            `flex-grow bg-transparent focus:outline-none ${
              value ? textTransform || "" : ""
            }`,
            inputClassName
          )}
          value={value}
          placeholder={placeholder || label}
          disabled={readOnly}
          autoComplete={autoComplete}
          maxLength={maxLength}
          onChange={(event) => {
            const newValue = event.target.value;
            onChange(newValue);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" && onEnter) {
              onEnter();
            }
          }}
          onBlur={() => {
            if (trimOnBlur && value !== value.trim()) {
              onChange(value.trim());
            }
            onBlur?.();
          }}
          onPaste={
            onPaste
              ? (event) => {
                  if (onPaste(event.clipboardData.getData("text/plain"))) {
                    event.preventDefault();
                  }
                }
              : undefined
          }
        />
        <ErrorTooltip position={errorPosition} error={error} />
        {endIcon && <span>{endIcon}</span>}
      </div>
    </>
  );
}
