import {
  renameObjectField,
  renameObjectFieldInExpression,
  renameObjectInExpression,
  getFieldArgumentsObjectTypeName,
  formatFieldSchemaName,
} from "@hypertune/shared-internal";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import useSearchParamsState from "../../../app/useSearchParamsState";
import { setDraftCommitSchemaAndExpression } from "../projectSlice";
import { useProjectSelectedState } from "../projectHooks";

export const schemaEditorModeParamName = "schema_mode";
export const schemaEditorSelectedTypeParamName = "selected_schema_type";

export type SchemaEditorMode = "type" | "code";

export const typeOptions = [
  "object",
  "enum",
  "input",
  "event",
  "union",
] as const;
export type TypeOption = (typeof typeOptions)[number];

export type TypeDefinition = {
  type: TypeOption;
  name: string;
};

export type SelectedType = TypeDefinition & {
  selectedChildName: string | null;
  count?: number; // This is only used to force scroll to type even if it's already selected.
};

export type TypeReferencesMap = { [typeName: string]: Set<TypeDefinition> };

export function useSelectedSchemaEditorMode(): [
  selectedType: SchemaEditorMode,
  setSelectedType: (newSelectedType: SchemaEditorMode) => void,
] {
  const [selectedType, setSelectedType] =
    useSearchParamsState<SchemaEditorMode>(schemaEditorModeParamName, "type");

  return [selectedType, setSelectedType];
}

export function useSchemaEditorSelectedType(): [
  selectedType: SelectedType | null,
  setSelectedType: (newSelectedType: SelectedType | null) => void,
] {
  const [selectedType, setSelectedType] =
    useSearchParamsState<SelectedType | null>(
      schemaEditorSelectedTypeParamName,
      null
    );

  return [selectedType, setSelectedType];
}

export function useSchemaEditorSetSelectedType({
  setSchemaView,
}: { setSchemaView?: boolean } = {}): (
  newSelectedType: SelectedType | null
) => void {
  const { setSelected } = useProjectSelectedState();

  const setSelectedType = useCallback(
    (newSelectedType: SelectedType | null) =>
      setSelected({
        ...(setSchemaView ? { view: "schema" } : {}),
        searchParams: new URLSearchParams({
          [schemaEditorSelectedTypeParamName]: JSON.stringify(newSelectedType),
        }),
      }),
    [setSelected, setSchemaView]
  );

  return setSelectedType;
}

export function useRenameObjectField(): (
  objectTypeName: string,
  fieldName: string,
  newFieldName: string
) => void {
  const dispatch = useAppDispatch();
  const schema = useAppSelector((state) => state.project.draftCommit?.schema);
  const expression = useAppSelector(
    (state) => state.project.draftCommit?.expression
  );
  const rename = useCallback(
    (objectTypeName: string, fieldName: string, newFieldName: string) => {
      if (!schema || !expression) {
        return;
      }
      const newSchema = renameObjectField(
        schema,
        objectTypeName,
        fieldName,
        newFieldName
      );
      dispatch(
        setDraftCommitSchemaAndExpression({
          schema: newSchema,
          expression: renameObjectFieldInExpression(
            newSchema,
            renameObjectInExpression(
              expression,
              getFieldArgumentsObjectTypeName({
                parentObjectTypeName: objectTypeName,
                fieldName,
              }),
              getFieldArgumentsObjectTypeName({
                parentObjectTypeName: objectTypeName,
                fieldName: formatFieldSchemaName(newFieldName),
              })
            ),
            objectTypeName,
            fieldName,
            newFieldName
          ),
        })
      );
    },
    [dispatch, schema, expression]
  );

  return rename;
}
