import {
  Expression,
  ObjectSchema,
  Schema,
  ValueType,
} from "@hypertune/sdk/src/shared/types";
import {
  FunnelDerivedField,
  FunnelStep,
} from "@hypertune/shared-internal/src/types";
import { useCallback, useMemo, useState } from "react";
import {
  fixAndSimplify,
  formatFieldSchemaName,
  rootObjectTypeName,
  toStartCase,
} from "@hypertune/shared-internal";
import { Pencil } from "@phosphor-icons/react";
import { VariableMap } from "@apollo/client/core/LocalState";
import getExpressionRecursiveErrorMessages from "@hypertune/shared-internal/src/expression/getExpressionRecursiveErrorMessages";
import getConstraintFromValueType from "@hypertune/shared-internal/src/expression/constraint/getConstraintFromValueType";
import valueTypesAreEqual from "@hypertune/shared-internal/src/schema/valueTypesAreEqual";
import { useNavigate } from "react-router-dom";
import { useHypertune } from "../../../../generated/hypertune.react";
import Label from "../../../../components/Label";
import Button from "../../../../components/buttons/Button";
import { intentPrimaryHex, plusSymbol } from "../../../../lib/constants";
import Modal from "../../../../components/Modal";
import ConfigurationContainer from "./ConfigurationContainer";
import DeleteButton from "../../../../components/buttons/DeleteButton";
import TextInput from "../../../../components/input/TextInput";
import { CommitContext, ExpressionEditorState } from "../../../../lib/types";
import ValueTypeSelector, {
  valueTypeOptionGroupsFromSchema,
} from "../../schema/typeEditor/object/ValueTypeSelector";
import ExpressionTree from "../../expression/ExpressionTree";
import SchemaNameError, {
  objectFieldNameError,
} from "../../schema/typeEditor/SchemaNameError";
import ErrorCircle from "../../../../components/icons/ErrorCircle";
import ModalWithContent from "../../../../components/ModalWithContent";

export default function DerivedFields({
  meId,
  stepType,
  payloadObjectTypeName,
  schema,
  step,
  setStep,
  canEdit,
}: {
  meId: string;
  stepType: "FunnelEventStep" | "FunnelExposureStep";
  payloadObjectTypeName: string;
  schema: Schema;
  canEdit: boolean;
  step: FunnelStep;
  setStep: (newStep: FunnelStep) => void;
}): React.ReactElement | null {
  const navigate = useNavigate();
  const hypertune = useHypertune();
  const canUseDerivedFields = hypertune
    .features()
    .analyticsDerivedFieldsEnabled({ fallback: false });
  const fields = step.derivedFields ?? [];

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [draftValueIndex, setDraftValueIndex] = useState<number | null>(null);

  const commitContext: CommitContext = useMemo(() => {
    return getDerivedFieldsCommitContext(
      schema,
      stepType,
      payloadObjectTypeName,
      step.derivedFields ?? []
    );
  }, [schema, stepType, payloadObjectTypeName, step.derivedFields]);
  const fieldErrors = useMemo(
    () =>
      step.derivedFields?.map((field) =>
        derivedFieldHasExpressionError(commitContext, field)
      ) ?? [],
    [commitContext, step.derivedFields]
  );

  return (
    <ConfigurationContainer title="Derived fields">
      {fields.length > 0 ? (
        <>
          {fields.map(({ name }, index) => (
            <div
              key={`derived-field-${name}`}
              className="mb-2 flex flex-row items-center"
            >
              <DeleteButton
                className="mr-1"
                size="x-small"
                disabled={!canEdit}
                onClick={() =>
                  setStep({
                    ...step,
                    derivedFields: [
                      ...fields.slice(0, index),
                      ...fields.slice(index + 1),
                    ],
                    breakdowns: step.breakdowns?.filter(
                      (breakdown) =>
                        breakdown.type !== "derivedField" ||
                        breakdown.fieldName !== name
                    ),
                    aggregations: step.aggregations?.filter(
                      (aggregation) =>
                        aggregation.data.type !== "derivedField" ||
                        aggregation.data.fieldName !== name
                    ),
                  })
                }
              />
              <Button
                className="mr-1"
                size="x-small"
                weight="minimal"
                intent="primary"
                disabled={!canEdit}
                icon={<Pencil color={intentPrimaryHex} size={12} />}
                onClick={() => setDraftValueIndex(index)}
              />
              <div>{toStartCase(name)}</div>
              {fieldErrors[index] && <ErrorCircle />}
            </div>
          ))}
        </>
      ) : null}
      <Button
        intent="primary"
        weight="minimal"
        size="x-small"
        disabled={!canEdit}
        icon={plusSymbol}
        text="Field"
        className="mb-2"
        onClick={
          canUseDerivedFields
            ? () => setDraftValueIndex(fields?.length ?? 0)
            : () => setShowUpgradeModal(true)
        }
      />
      {draftValueIndex !== null && (
        <FieldModal
          onClose={() => setDraftValueIndex(null)}
          meId={meId}
          readOnly={!canEdit}
          commitContext={commitContext}
          draftIndex={draftValueIndex}
          existingFields={fields}
          setField={(newField) => {
            const oldField = fields[draftValueIndex];
            if (
              !oldField ||
              (newField.name === oldField.name &&
                valueTypesAreEqual(newField.valueType, oldField.valueType))
            ) {
              // Adding a new value at the end or the name and value type hasn't changed
              setStep({
                ...step,
                derivedFields: [
                  ...fields.slice(0, draftValueIndex),
                  newField,
                  ...fields.slice(draftValueIndex + 1),
                ],
              });
              return;
            }
            // Remove aggregation if field value type is no longer a number
            const aggregations =
              newField.valueType.type !== "IntValueType" &&
              newField.valueType.type !== "FloatValueType"
                ? step.aggregations?.filter(
                    (aggregation) =>
                      aggregation.data.type !== "derivedField" ||
                      aggregation.data.fieldName !== oldField.name
                  )
                : step.aggregations;

            // Update derived fields and rename derived field in breakdowns and aggregations
            setStep({
              ...step,
              derivedFields: [
                ...fields.slice(0, draftValueIndex),
                newField,
                ...fields.slice(draftValueIndex + 1),
              ],
              breakdowns: step.breakdowns?.map((breakdown) =>
                breakdown.type === "derivedField" &&
                breakdown.fieldName === oldField.name
                  ? { type: "derivedField", fieldName: newField.name }
                  : breakdown
              ),
              aggregations: aggregations?.map((aggregation) =>
                aggregation.data.type === "derivedField" &&
                aggregation.data.fieldName === oldField.name
                  ? {
                      ...aggregation,
                      data: { type: "derivedField", fieldName: newField.name },
                    }
                  : aggregation
              ),
            });
          }}
        />
      )}
      {showUpgradeModal && (
        <ModalWithContent
          content={hypertune
            .content()
            .plans()
            .analyticsAddDerivedFieldModal()
            .get()}
          onClose={() => setShowUpgradeModal(false)}
          onSave={() => navigate("/plans")}
        />
      )}
    </ConfigurationContainer>
  );
}

const funnelStepDerivedFieldNameObjectTypeName = "__FunnelDerivedField";
const funnelStepArgsObjectTypeName = "__Funnel_step_args";
const funnelVariables: VariableMap = {
  funnelStepArgs: {
    id: "funnelStepArgs",
    name: "args",
    valueType: {
      type: "ObjectValueType",
      objectTypeName: funnelStepArgsObjectTypeName,
    },
  },
};

function FieldModal({
  meId,
  readOnly,
  commitContext,
  draftIndex,
  existingFields,
  setField,
  onClose,
}: {
  meId: string;
  readOnly: boolean;
  commitContext: CommitContext;
  existingFields: FunnelDerivedField[];
  draftIndex: number;
  setField: (newField: FunnelDerivedField) => void;
  onClose: () => void;
}): React.ReactElement | null {
  const { schema } = commitContext;

  const draftBase = existingFields[draftIndex];
  const [name, setName] = useState<string>(draftBase?.name ?? "");
  const schemaName = formatFieldSchemaName(name);
  const nameError =
    schemaName && schemaName !== existingFields[draftIndex]?.name
      ? objectFieldNameError(
          schema,
          "field",
          funnelStepDerivedFieldNameObjectTypeName,
          schemaName
        )
      : null;

  const [editorState, setEditorState] = useState<ExpressionEditorState>({
    selectedItem: null,
    collapsedExpressionIds: {},
  });
  const [valueType, setValueType] = useState<ValueType>(
    draftBase?.valueType ?? { type: "BooleanValueType" }
  );
  const [draftExpression, _setDraftExpression] = useState<Expression | null>(
    draftBase?.expression ?? null
  );
  const setDraftExpression = useCallback(
    (newDraftExpression: Expression | null) =>
      _setDraftExpression(
        newDraftExpression
          ? fixAndSimplify(schema, {}, {}, null, newDraftExpression)
              .newExpression
          : null
      ),
    [schema, _setDraftExpression]
  );
  const valueTypeOptionGroups = useMemo(
    () =>
      valueTypeOptionGroupsFromSchema(
        schema,
        rootObjectTypeName,
        /* includeNewOptions */ false
      )
        .map((group) => ({
          label: group.label,
          options: group.options.filter((option) => {
            const { type } = option.value;
            return (
              type === "EnumValueType" ||
              type === "StringValueType" ||
              type === "BooleanValueType" ||
              type === "IntValueType" ||
              type === "FloatValueType"
            );
          }),
        }))
        .filter((group) => group.options.length > 0),
    [schema]
  );

  const draftField: FunnelDerivedField | null = useMemo(
    () =>
      !draftExpression
        ? null
        : {
            type: "ExpressionField",
            name,
            expression: draftExpression,
            valueType: valueType as FunnelDerivedField["valueType"],
          },
    [name, draftExpression, valueType]
  );
  const hasExpressionError = useMemo(
    () =>
      !draftField
        ? true
        : derivedFieldHasExpressionError(commitContext, draftField),
    [commitContext, draftField]
  );
  const saveDisabled = !name || nameError !== null || hasExpressionError;

  const onSave = useCallback(() => {
    if (saveDisabled || !draftField) {
      return;
    }
    setField(draftField);
    onClose();
  }, [saveDisabled, setField, draftField, onClose]);

  return (
    <Modal
      title="Configure derived field"
      onClose={onClose}
      saveWeight="filled"
      onSave={onSave}
      saveDisabled={saveDisabled}
      style={{ maxHeight: "900px", overflow: "hidden" }}
      childrenStyle={{ overflow: "auto", paddingBottom: 300 }}
    >
      <TextInput
        readOnly={readOnly}
        value={name}
        onChange={setName}
        placeholder="Add name for this field"
        onEnter={onSave}
        focusOnMount
        label="Name"
        labelVariant="muted"
        error={nameError && <SchemaNameError schemaCheckOrError={nameError} />}
      />
      {schemaName && (
        <TextInput
          value={schemaName}
          trimOnBlur={false}
          readOnly
          onChange={() => {
            // Dummy
          }}
          style={{ marginTop: 10 }}
        />
      )}
      <ValueTypeSelector
        objectTypeName={rootObjectTypeName}
        optionGroups={valueTypeOptionGroups}
        valueTypes={[valueType]}
        setValueTypes={(newValueTypes: ValueType[]) => {
          if (newValueTypes.length !== 1) {
            throw new Error(
              `Unexpected value types: ${JSON.stringify(newValueTypes)}`
            );
          }
          setValueType(newValueTypes[0]);
        }}
        dropdownStyle={{
          caret: "down",
          scrollToPosition: "center",
          showButtonSubtitle: true,
          buttonClassName: "border min-h-[46px] px-[14px] py-[12px]",
          subtitleClassName: "max-w-[320px]",
          panelClassName: "overflow-x-hidden",
        }}
      />
      <Label type="title4" className="mt-6 text-tx-muted">
        Field logic
      </Label>
      <ExpressionTree
        className="w-full px-0 py-2"
        context={{
          meId,
          commitContext,
          evaluations: {},
          expressionEditorState: editorState,
          setExpressionEditorState: setEditorState,
          ignoreErrors: false,
          readOnly,
          expandByDefault: true,
          disableVariableCreation: true,
          disablePermissionsManagement: true,
          fullFieldPath: "",
          resolvedPermissions: {
            user: {},
            group: { team: { write: "allow" } },
          },
          includeComparisonOperator: (operator) =>
            operator !== "matches" && operator !== "notMatches",
        }}
        variables={funnelVariables}
        setVariableName={{}}
        valueTypeConstraint={getConstraintFromValueType(valueType)}
        expression={draftExpression}
        setExpression={setDraftExpression}
        parentExpression={null}
        lift={() => {
          // noop
        }}
        includeExpressionOption={({ expressionOption }) => {
          if (
            expressionOption.type === "SplitExpression" ||
            expressionOption.type === "GetUrlQueryParameterExpression" ||
            (expressionOption.type === "GetFieldExpression" &&
              expressionOption.valueType.type === "ListValueType")
          ) {
            return false;
          }
          return true;
        }}
      />
    </Modal>
  );
}

export function getDerivedFieldsCommitContext(
  baseSchema: Schema,
  stepType: "FunnelEventStep" | "FunnelExposureStep",
  payloadObjectTypeName: string | null,
  existingFields: FunnelDerivedField[]
): CommitContext {
  const argsObject: ObjectSchema = {
    role: "args",
    description: null,
    fields: {
      ...(stepType === "FunnelExposureStep"
        ? {
            unitId: {
              description: null,
              valueType: { type: "StringValueType" },
            },
          }
        : {}),
      ...(payloadObjectTypeName
        ? {
            payload: {
              description: null,
              valueType: {
                type: "ObjectValueType",
                objectTypeName: payloadObjectTypeName,
              },
            },
          }
        : {}),
    },
  };
  const schema: Schema = {
    ...baseSchema,
    objects: {
      ...baseSchema.objects,
      [funnelStepArgsObjectTypeName]: argsObject,
      [funnelStepDerivedFieldNameObjectTypeName]: {
        ...argsObject,
        role: "output",
        fields: {
          ...argsObject.fields,
          ...Object.fromEntries(
            existingFields.map((field) => [
              field.name,
              { description: null, valueType: field.valueType },
            ])
          ),
        },
      },
    },
  };
  return {
    schema,
    splits: {},
    setSplits: () => {
      // noop
    },
    eventTypes: {},
  };
}

export function derivedFieldHasExpressionError(
  commitContext: CommitContext,
  field: FunnelDerivedField
): boolean {
  if (!field) {
    return false;
  }
  if (!field.name) {
    return true;
  }
  const expressionErrors = getExpressionRecursiveErrorMessages(
    commitContext.schema,
    commitContext.splits,
    funnelVariables,
    getConstraintFromValueType(field.valueType),
    null,
    field.expression
  );
  return expressionErrors.length > 0;
}
