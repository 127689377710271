import {
  getEmptyLogs,
  ComparisonExpression,
  uniqueId,
} from "@hypertune/sdk/src/shared";

export default function getComparisonExpression(): ComparisonExpression {
  const expression: ComparisonExpression = {
    id: uniqueId(),
    logs: getEmptyLogs(),
    type: "ComparisonExpression",
    valueType: { type: "BooleanValueType" },
    operator: null,
    a: null,
    b: null,
  };
  return expression;
}
