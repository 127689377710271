import React from "react";
import { getSplitWithErrorId } from "@hypertune/shared-internal/src/expression/getSplitErrorMessage";
import TopBar from "../../../components/TopBar";
import { ProjectBranchQuery, ProjectQuery } from "../../../generated/graphql";
import ProjectNameControl from "./ProjectNameControl";
import Separator from "../../../components/icons/Separator";
import CommitSelector from "./CommitSelector";
import SelectedEntityName from "./SelectedEntityName";
import EditButtons from "./EditButtons";
import Button from "../../../components/buttons/Button";
import ErrorCircle from "../../../components/icons/ErrorCircle";
import { ProjectView } from "../projectHooks";
import { useLogicSetSelectedFieldPath } from "../logicHooks";
import BranchSelector from "./BranchSelector";
import { useHypertune } from "../../../generated/hypertune.react";
import { useSplitEditorSetSelectedSplitId } from "../split/splitHooks";
import {
  ExpressionNodeMap,
  findDeepestItemWithErrorInExpressionTree,
} from "../expression/toTree";
import { DraftCommit, FullDraftCommitDerivedData } from "../projectSlice";
import { useHasVercelConnectionError } from "../../business/vercel/hooks";

export default function ProjectTopBar({
  meId,
  project,
  branch,
  canEdit,
  canContribute,
  selectedProjectId,
  selectedBranchName,
  setSelectedBranch,
  selectedView,
  setSelectedView,
  setSelectedCommitId,
  commitId,
  commitMessage,
  commit,
  expressionTree,
  commitDerivedData,
}: {
  meId?: string;
  project?: ProjectQuery["project"];
  branch?: ProjectBranchQuery["projectBranch"];
  canEdit: boolean;
  canContribute: boolean;
  selectedProjectId: string;
  selectedBranchName: string;
  setSelectedBranch: (newSelectedBranch: string) => void;
  selectedView: ProjectView;
  setSelectedView: (newView: ProjectView) => void;
  setSelectedCommitId: (newCommitId: string) => void;
  commitId: string | null;
  commitMessage?: string;
  commit?: DraftCommit;
  expressionTree?: ExpressionNodeMap;
  commitDerivedData: FullDraftCommitDerivedData;
}): React.ReactElement | null {
  const hypertune = useHypertune();
  const branchingEnabled = hypertune
    .features()
    .branchingEnabled({ fallback: false });

  const setLogicSelectedFieldPath = useLogicSetSelectedFieldPath({
    setLogicView: true,
  });
  const setSelectedSplitId = useSplitEditorSetSelectedSplitId({
    setSplitView: true,
  });

  const { defaultFieldPath, topLevelEnum } = commitDerivedData;
  const schemaError = !!commitDerivedData.schemaCodeError;
  const logicError = !!commitDerivedData.logicError;
  const splitsError = !!commitDerivedData.splitsError;
  const settingsError = useHasVercelConnectionError(project?.id);
  const hasError = schemaError || logicError || splitsError || settingsError;

  const selectedEntityName = (
    <SelectedEntityName
      selectedView={selectedView}
      expressionTree={expressionTree}
      topLevelEnumTypeName={topLevelEnum}
      defaultFieldPath={defaultFieldPath}
      splits={commit?.splits}
    />
  );
  const showProjectSelectors =
    selectedView !== "settings" &&
    selectedView !== "pull-requests" &&
    selectedView !== "analytics";

  return (
    <TopBar
      showBusinessSelector
      left={
        <>
          <Separator style={{ marginRight: 3 }} />
          <ProjectNameControl
            id={project?.id}
            name={project?.name}
            muted={showProjectSelectors}
            readOnly={!canEdit}
          />
          {showProjectSelectors && (
            <>
              <Separator style={{ marginLeft: 3 }} />
              <>
                <BranchSelector
                  selectedBranchName={selectedBranchName}
                  setSelectedBranch={setSelectedBranch}
                  project={project}
                  canAdd={canContribute}
                  showUpgradeModalOnCreate={!branchingEnabled}
                />
                <Separator style={{ marginLeft: 3 }} />
              </>
              <CommitSelector
                muted
                branch={branch}
                setSelectedCommitId={setSelectedCommitId}
              />
              {selectedEntityName}
            </>
          )}
        </>
      }
      right={
        selectedView === "logic" ||
        selectedView === "schema" ||
        selectedView === "splits" ||
        selectedView === "diff" ||
        selectedView === "settings" ? (
          <>
            {hasError && (
              <Button
                intent="danger"
                weight="elevated"
                size="large"
                text={
                  schemaError
                    ? "Schema error"
                    : splitsError
                      ? "Splits error"
                      : logicError
                        ? "Logic error"
                        : settingsError
                          ? "Settings error"
                          : ""
                }
                icon={<ErrorCircle />}
                onClick={() => {
                  if (schemaError) {
                    setSelectedView("schema");
                    return;
                  }
                  if (splitsError) {
                    if (commit?.schema && commit?.splits) {
                      setSelectedSplitId(
                        getSplitWithErrorId(commit.schema, commit.splits)
                      );
                    }
                    return;
                  }
                  if (logicError) {
                    if (expressionTree) {
                      const firstError =
                        findDeepestItemWithErrorInExpressionTree(
                          expressionTree
                        );
                      if (firstError) {
                        setLogicSelectedFieldPath(
                          firstError.item.fullFieldPath
                        );
                        return;
                      }
                    }

                    return;
                  }
                  if (settingsError) {
                    setSelectedView("settings");
                  }
                }}
                className="mr-2"
              />
            )}

            {meId &&
              selectedProjectId &&
              branch &&
              (selectedView === "logic" ||
                selectedView === "schema" ||
                selectedView === "splits" ||
                selectedView === "diff") && (
                <EditButtons
                  meId={meId}
                  projectId={selectedProjectId}
                  branch={branch}
                  branches={project?.branches}
                  pullRequests={project?.pullRequests}
                  selectedBranchName={selectedBranchName}
                  selectedView={selectedView}
                  toastDirection="below-rtl"
                  setSelectedCommitId={setSelectedCommitId}
                  commitId={commitId}
                  commitMessage={commitMessage}
                  draftCommit={commit}
                  draftCommitDerivedData={commitDerivedData}
                  canEdit={canEdit}
                  canContribute={canContribute}
                />
              )}
          </>
        ) : undefined
      }
    />
  );
}
