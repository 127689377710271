import { Plan as GraphqlPlan } from "../generated/graphql";
import { Plan } from "../generated/hypertune";

export default function parseGraphqlPlan(plan?: GraphqlPlan): Plan | null {
  if (!plan) {
    return null;
  }
  switch (plan) {
    case GraphqlPlan.Free:
      return "FREE";
    case GraphqlPlan.Pro:
      return "PRO";
    case GraphqlPlan.Business:
      return "ENTERPRISE";
    default: {
      const neverPlan: never = plan;
      throw new Error(`unexpected graphql business plan: "${neverPlan}"`);
    }
  }
}
