import { useCallback } from "react";
import useSearchParamsState from "../../../app/useSearchParamsState";
import { useProjectSelectedState } from "../projectHooks";

export const splitEditorSelectedParamName = "selected_split";

export const splitTypeOptions = ["test", "ml"] as const;
export type SplitTypeOption = (typeof splitTypeOptions)[number];

export function useSplitEditorSelectedSplitId(): [
  selectedSplitId: string,
  setSelectedSplitId: (newSelectedSplitId: string) => void,
] {
  const [selectedSplitId, setSelectedSplitId] = useSearchParamsState<string>(
    splitEditorSelectedParamName,
    ""
  );

  return [selectedSplitId, setSelectedSplitId];
}

export function useSplitEditorSetSelectedSplitId({
  setSplitView,
}: {
  setSplitView?: boolean;
}): (newSelectedSplitId: string | null) => void {
  const { setSelected } = useProjectSelectedState();

  const setSelectedSplitId = useCallback(
    (newSelectedSplitId: string | null) =>
      setSelected({
        ...(setSplitView ? { view: "splits" } : {}),
        searchParams: new URLSearchParams({
          [splitEditorSelectedParamName]: newSelectedSplitId ?? "",
        }),
      }),
    [setSelected, setSplitView]
  );

  return setSelectedSplitId;
}
