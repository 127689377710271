import {
  mapExpressionWithResult,
  getEmptyLogs,
  ApplicationExpression,
  Expression,
  FunctionExpression,
  Parameter,
  ValueType,
  VariableExpression,
  uniqueId,
} from "@hypertune/sdk/src/shared";
import { deepClonePlainObject, getNextName } from "@hypertune/shared-internal";
import getVariableNames from "@hypertune/shared-internal/src/expression/getVariableNames";
import getNewVariableName from "@hypertune/shared-internal/src/expression/getNewVariableName";
import getApplicationFunctionExpression from "@hypertune/shared-internal/src/expression/getApplicationFunctionExpression";
import {
  Variable,
  VariableMap,
} from "@hypertune/shared-internal/src/expression/types";
import { SelectedItem } from "../types";

// Expensive
export default function createApplication({
  variables,
  rawArgument,
  replacedVariableIdToNewVariable,
  valueType,
  replaceArgument,
  newVariableName,
  setExpressionEditorSelectedItem,
}: {
  variables: VariableMap;
  /** The expression to lift. This may be a direct child, grandchild or lower. */
  rawArgument: Expression;
  replacedVariableIdToNewVariable: { [oldVariableId: string]: Variable };
  valueType: ValueType;
  /** Function that returns the future application's body, with the rawArgument replaced with the given replacement */
  replaceArgument: (
    replacement: VariableExpression | ApplicationExpression
  ) => Expression;
  /** The new variable name for the argument. This will be suffixed to make it unique in the set of variables. */
  newVariableName: string | null;
  setExpressionEditorSelectedItem: (
    newSelectedItem: SelectedItem | null
  ) => void;
}): ApplicationExpression {
  const replacedVariableIdWithNewVariable: {
    replacedVariableId: string;
    newVariable: Variable;
  }[] = Object.entries(replacedVariableIdToNewVariable).map(
    ([replacedVariableId, newVariable]) => ({
      replacedVariableId,
      newVariable,
    })
  );

  const argument: Expression =
    replacedVariableIdWithNewVariable.length > 0
      ? {
          id: uniqueId(),
          logs: getEmptyLogs(),
          type: "FunctionExpression",
          valueType: {
            type: "FunctionValueType",
            parameterValueTypes: replacedVariableIdWithNewVariable.map(
              (item) => item.newVariable.valueType
            ),
            returnValueType: rawArgument.valueType,
          },
          parameters: replacedVariableIdWithNewVariable.map((item) => {
            const parameter: Parameter = {
              id: item.newVariable.id,
              name: item.newVariable.name,
            };
            return parameter;
          }),
          body: rawArgument,
        }
      : rawArgument;

  const parameter: Parameter = {
    id: uniqueId(),
    name: "parameter",
  };

  const variableId = uniqueId();
  const variable: VariableExpression | ApplicationExpression =
    replacedVariableIdWithNewVariable.length > 0 &&
    argument.type === "FunctionExpression"
      ? {
          id: uniqueId(),
          logs: getEmptyLogs(),
          type: "ApplicationExpression",
          valueType: argument.valueType.returnValueType,
          function: {
            id: uniqueId(),
            logs: getEmptyLogs(),
            type: "VariableExpression",
            valueType: argument.valueType,
            variableId: parameter.id,
          },
          arguments: replacedVariableIdWithNewVariable.map((item) => {
            const replacedVariableExpression: VariableExpression = {
              id: variableId,
              logs: getEmptyLogs(),
              type: "VariableExpression",
              valueType: item.newVariable.valueType,
              variableId: item.replacedVariableId,
            };
            return replacedVariableExpression;
          }),
        }
      : {
          id: variableId,
          logs: getEmptyLogs(),
          type: "VariableExpression",
          valueType: argument.valueType,
          variableId: parameter.id,
        };

  const body = replaceArgument(variable);

  const permissionsToMove = body.metadata?.permissions;
  const bodyWithoutPermissions = deepClonePlainObject(body);
  delete bodyWithoutPermissions.metadata?.permissions;

  const existingNames = {
    ...getVariableNames(variables),
    ...getVariableNamesDeclaredInExpression(bodyWithoutPermissions, variableId),
  };
  parameter.name = newVariableName
    ? getNextName(existingNames, newVariableName)
    : getNewVariableName(existingNames, argument.valueType);

  const functionExpression: FunctionExpression = {
    id: uniqueId(),
    logs: getEmptyLogs(),
    type: "FunctionExpression",
    valueType: {
      type: "FunctionValueType",
      parameterValueTypes: [argument.valueType],
      returnValueType: valueType,
    },
    parameters: [parameter],
    body: bodyWithoutPermissions,
  };
  setExpressionEditorSelectedItem({ type: "variable", id: parameter.id });

  return {
    id: uniqueId(),
    logs: getEmptyLogs(),
    type: "ApplicationExpression",
    valueType,
    function: functionExpression,
    arguments: [argument],
    metadata: {
      permissions: permissionsToMove,
    },
  };
}

function getVariableNamesDeclaredInExpression(
  expression: Expression,
  excludeVariableId: string
): {
  [variableName: string]: boolean;
} {
  const result = mapExpressionWithResult<{
    [variableId: string]: { name: string; include: boolean };
  }>(
    (expr) => {
      // Exclude applications where we're just reusing the same variable.
      if (expr && expr.type === "ApplicationExpression") {
        const func = getApplicationFunctionExpression(expr);
        const excludeIndex = expr.arguments.findIndex(
          (a) => a && a.id === excludeVariableId
        );
        if (func && excludeIndex !== -1) {
          const parameter = func.parameters[excludeIndex];
          return {
            newExpression: expr,
            mapResult: {
              [parameter.id]: { name: parameter.name, include: false },
            },
          };
        }
      }

      if (expr && expr.type === "FunctionExpression") {
        return {
          newExpression: expr,
          mapResult: Object.fromEntries(
            expr.parameters.map((parameter) => [
              parameter.id,
              { name: parameter.name, include: true },
            ])
          ),
        };
      }
      return { newExpression: expr, mapResult: {} };
    },
    // Reduce, preferring false over true for includes
    (...results) =>
      results.reduce((acc, cur) => {
        Object.entries(cur).forEach(([parameterId, value]) => {
          if (!acc[parameterId] || !value.include) {
            acc[parameterId] = value;
          }
        });
        return acc;
      }, {}),
    expression
  );
  return Object.fromEntries(
    Object.entries(result.mapResult).map(([, { name, include }]) => [
      name,
      include,
    ])
  );
}
