import { ValueTypeConstraint } from "@hypertune/shared-internal/src/expression/types";
import Label from "../../../components/Label";
import ExpressionControl from "../expression/ExpressionControl";
import { ExpressionControlContext } from "../../../lib/types";
import { Intent } from "../../../components/intent";
import { ExpressionNode } from "../expression/toTree";
import ValueTypeConstraintIcon from "../expression/ValueTypeConstraintIcon";
import EmptyPanel from "./EmptyPanel";
import DiffContainer from "./DiffContainer";

export type ExpressionDiffNode = {
  fullLabel: string;
  currentExpressionNode: ExpressionNode | null;
  newExpressionNode: ExpressionNode | null;
  iconProps: {
    isVariable: boolean;
    hasChildren: boolean;
    valueTypeConstraint: ValueTypeConstraint;
  };
};

export default function ExpressionDiff({
  currentContext,
  newContext,
  nodes,
  currentExpressionIntentMap,
  newExpressionIntentMap,
  selectedDiffIndex,
}: {
  currentContext: ExpressionControlContext;
  newContext: ExpressionControlContext;
  nodes: ExpressionDiffNode[];
  currentExpressionIntentMap: Record<string, Intent>;
  newExpressionIntentMap: Record<string, Intent>;
  selectedDiffIndex: number | null;
}): React.ReactElement | null {
  if (nodes.length === 0) {
    return null;
  }

  return (
    <>
      {nodes.map((node, index) => (
        <FlagDiff
          index={index}
          node={node}
          currentContext={currentContext}
          newContext={newContext}
          currentExpressionIntentMap={currentExpressionIntentMap}
          newExpressionIntentMap={newExpressionIntentMap}
          selectedDiffIndex={selectedDiffIndex}
        />
      ))}
    </>
  );
}

function FlagDiff({
  index,
  node,
  currentContext,
  newContext,
  currentExpressionIntentMap,
  newExpressionIntentMap,
  selectedDiffIndex,
}: {
  index: number;
  node: ExpressionDiffNode;
  currentContext: ExpressionControlContext;
  newContext: ExpressionControlContext;
  currentExpressionIntentMap: Record<string, Intent>;
  newExpressionIntentMap: Record<string, Intent>;
  selectedDiffIndex: number | null;
}): React.ReactElement | null {
  return (
    <DiffContainer
      header={
        <>
          <ValueTypeConstraintIcon {...node.iconProps} />
          <Label type="title3">{node.fullLabel}</Label>
        </>
      }
      left={
        <InnerExpressionControl
          context={currentContext}
          missingIntent="danger"
          expressionNode={node.currentExpressionNode}
          expressionIntentMap={currentExpressionIntentMap}
        />
      }
      right={
        <InnerExpressionControl
          context={newContext}
          missingIntent="success"
          expressionNode={node.newExpressionNode}
          expressionIntentMap={newExpressionIntentMap}
        />
      }
      isSelected={selectedDiffIndex === index}
    />
  );
}

function InnerExpressionControl({
  context,
  missingIntent,
  expressionNode,
  expressionIntentMap,
}: {
  context: ExpressionControlContext;
  missingIntent: Intent;
  expressionNode: ExpressionNode | null;
  expressionIntentMap: Record<string, Intent>;
}): React.ReactElement | null {
  if (!expressionNode) {
    return <EmptyPanel intent={missingIntent} />;
  }
  return (
    <ExpressionControl
      context={{
        ...context,
        ...expressionNode.context,
        expressionIdToIntent: expressionIntentMap,
        showComparisonExpressionPanel: true,
        showSwitchExpressionPanels: true,
      }}
      variables={expressionNode.variables}
      setVariableName={{}}
      valueTypeConstraint={expressionNode.valueTypeConstraint}
      expression={expressionNode.expression}
      setExpression={noop}
      lift={noop}
      parentExpression={null}
      includeExpressionOption={() => true}
    />
  );
}

function noop(): void {
  // Do nothing
}
