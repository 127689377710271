import {
  Expression,
  RegexExpression,
  StringExpression,
  getStringExpression,
} from "@hypertune/sdk/src/shared";

import { ExpressionControlContext } from "../../../lib/types";
import isReadOnly from "../../../lib/expression/isReadOnly";
import TextArea from "../../../components/input/TextArea";

export default function StringExpressionControl({
  context,
  expression,
  setExpression,
  parentExpression,
  setParentExpression,
  shouldStack,
  optionsButton,
}: {
  context: ExpressionControlContext;
  expression: StringExpression | RegexExpression;
  setExpression: (newExpression: Expression | null) => void;
  parentExpression?: Expression | null;
  setParentExpression?: (newExpression: Expression | null) => void;
  shouldStack: boolean;
  optionsButton: React.ReactNode;
}): React.ReactElement {
  const readOnly = isReadOnly(context);

  const { expressionEditorState } = context;

  const isExpressionSelected =
    expressionEditorState.selectedItem &&
    expressionEditorState.selectedItem.type === "expression" &&
    expressionEditorState.selectedItem.id === expression.id;

  const placeholder =
    expression.type === "RegexExpression"
      ? "Enter regex here"
      : "Enter text here";

  return (
    <TextArea
      focusOnMount={
        context.expressionEditorState.selectedItem?.id === expression.id
      }
      readOnly={readOnly}
      intent={
        context.expressionIdToIntent?.[expression.id] ??
        (getStringExpressionInAListOrComparisonExpressionWarning({
          context,
          parentExpression,
          expression,
        })
          ? "warning"
          : "neutral")
      }
      placeholder={placeholder}
      value={expression.value}
      setValue={(newValue) => {
        setExpression({
          ...expression,
          value: newValue,
        });
      }}
      selectOnMount={isExpressionSelected || false}
      shouldStack={shouldStack}
      optionsButton={optionsButton}
      onSplit={
        !context.disableStringListSplitAndWarnings &&
        parentExpression?.type === "ListExpression" &&
        setParentExpression
          ? (lines): void => {
              const { items } = parentExpression;

              const index = items.findIndex(
                (item) => item?.id === expression.id
              );

              if (index < 0) {
                return;
              }

              setParentExpression({
                ...parentExpression,
                items: [
                  ...items.slice(0, index),
                  ...lines.map((line) => getStringExpression(line)),
                  ...items.slice(index + 1),
                ],
              });
            }
          : undefined
      }
      onClick={
        !readOnly
          ? (event) => {
              context.setExpressionEditorState({
                ...context.expressionEditorState,
                selectedItem: { type: "expression", id: expression.id },
              });
              event.stopPropagation();
            }
          : undefined
      }
      onBlur={() =>
        context.setExpressionEditorState({
          ...context.expressionEditorState,
          selectedItem: null,
        })
      }
    />
  );
}

export function getStringExpressionInAListOrComparisonExpressionWarning({
  context,
  parentExpression,
  expression,
}: {
  context: ExpressionControlContext;
  parentExpression?: Expression | null;
  expression: Expression | null;
}): string | null {
  if (
    context.disableStringListSplitAndWarnings ||
    context.expressionEditorState.selectedItem?.id === expression?.id ||
    expression?.metadata?.note ||
    expression?.type !== "StringExpression" ||
    (parentExpression?.type !== "ListExpression" &&
      parentExpression?.type !== "ComparisonExpression")
  ) {
    return null;
  }
  if (!expression.value) {
    return "Empty string";
  }
  if (expression.value.trimEnd() !== expression.value) {
    return "Trailing whitespace";
  }
  if (expression.value.trimStart() !== expression.value) {
    return "Leading whitespace";
  }
  return null;
}
