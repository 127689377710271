import { getEmptyLogs, uniqueId } from "@hypertune/sdk/src/shared";
import { Expression } from "@hypertune/sdk/src/shared/types";
import {
  ExpressionMap,
  ExpressionMapPointer,
  ExpressionMapValue,
} from "./types";

export default function getExpressionFromMap(
  exprMap: ExpressionMap,
  fallbackExprMap: ExpressionMap,
  pointer: ExpressionMapPointer | null
): Expression | null {
  if (!pointer) {
    return null;
  }
  const expression = exprMap[pointer.id] ?? fallbackExprMap[pointer.id];
  if (!expression) {
    return null;
  }
  let result: Expression = getExpressionFromMapAndValue(
    exprMap,
    fallbackExprMap,
    expression
  ) as Expression;
  const variableData = [
    ...(Object.values(pointer.variablesExpressionData || {}).sort(
      // Sort in descending order of weights.
      (a, b) => b.weight - a.weight
    ) || []),
  ];
  variableData.forEach(({ id, name, arg }) => {
    const argExpr = getExpressionFromMap(
      exprMap,
      fallbackExprMap,
      arg
    ) as Expression;
    result = {
      id: uniqueId(),
      type: "ApplicationExpression",
      logs: getEmptyLogs(),
      valueType: result.valueType,
      arguments: [argExpr],
      function: {
        id: uniqueId(),
        type: "FunctionExpression",
        valueType: {
          type: "FunctionValueType",
          parameterValueTypes: [argExpr.valueType],
          returnValueType: result.valueType,
        },
        logs: getEmptyLogs(),
        parameters: [{ id, name }],
        body: result,
      },
    };
  });

  return result;
}

export function getExpressionFromMapAndValue(
  exprMap: ExpressionMap,
  fallbackExprMap: ExpressionMap,
  expression: ExpressionMapValue
): Expression | null {
  switch (expression.type) {
    case "NoOpExpression":
    case "BooleanExpression":
    case "StringExpression":
    case "IntExpression":
    case "FloatExpression":
    case "RegexExpression":
    case "EnumExpression":
    case "VariableExpression":
      return expression;

    case "ObjectExpressionMapValue":
      return {
        ...expression,
        type: "ObjectExpression",
        fields: getRecordOfExpressionsFromMap(
          exprMap,
          fallbackExprMap,
          expression.fields
        ),
      };
    case "GetFieldExpressionMapValue":
      return {
        ...expression,
        type: "GetFieldExpression",
        object: getExpressionFromMap(
          exprMap,
          fallbackExprMap,
          expression.object
        ),
      };
    case "UpdateObjectExpressionMapValue":
      return {
        ...expression,
        type: "UpdateObjectExpression",
        object: getExpressionFromMap(
          exprMap,
          fallbackExprMap,
          expression.object
        ),
        updates: getRecordOfExpressionsFromMap(
          exprMap,
          fallbackExprMap,
          expression.updates
        ),
      };
    case "ListExpressionMapValue": {
      const { itemsWeights: weights, ...expr } = expression;
      return {
        ...expr,
        type: "ListExpression",
        items: getArrayOfExpressionsFromMap(
          exprMap,
          fallbackExprMap,
          expr.items,
          weights
        ),
      };
    }

    case "SwitchExpressionMapValue": {
      const { casesWeights: weights, ...expr } = expression;
      return {
        ...expr,
        type: "SwitchExpression",
        control: getExpressionFromMap(
          exprMap,
          fallbackExprMap,
          expression.control
        ),
        cases: Object.entries(expression.cases)
          .map(([id, { when, then }]) => ({
            id,
            when: getExpressionFromMap(exprMap, fallbackExprMap, when),
            then: getExpressionFromMap(exprMap, fallbackExprMap, then),
          }))
          .sort((a, b) => weights[a.when!.id] - weights[b.when!.id]),
        default: getExpressionFromMap(
          exprMap,
          fallbackExprMap,
          expression.default
        ),
      };
    }

    case "EnumSwitchExpressionMapValue":
      return {
        ...expression,
        type: "EnumSwitchExpression",
        control: getExpressionFromMap(
          exprMap,
          fallbackExprMap,
          expression.control
        ),
        cases: getRecordOfExpressionsFromMap(
          exprMap,
          fallbackExprMap,
          expression.cases
        ),
      };
    case "ArithmeticExpressionMapValue":
      return {
        ...expression,
        type: "ArithmeticExpression",
        a: getExpressionFromMap(exprMap, fallbackExprMap, expression.a),
        b: getExpressionFromMap(exprMap, fallbackExprMap, expression.b),
      };
    case "ComparisonExpressionMapValue":
      return {
        ...expression,
        type: "ComparisonExpression",
        a: getExpressionFromMap(exprMap, fallbackExprMap, expression.a),
        b: getExpressionFromMap(exprMap, fallbackExprMap, expression.b),
      };
    case "RoundNumberExpressionMapValue":
      return {
        ...expression,
        type: "RoundNumberExpression",
        number: getExpressionFromMap(
          exprMap,
          fallbackExprMap,
          expression.number
        ),
      };
    case "StringifyNumberExpressionMapValue":
      return {
        ...expression,
        type: "StringifyNumberExpression",
        number: getExpressionFromMap(
          exprMap,
          fallbackExprMap,
          expression.number
        ),
      };
    case "StringConcatExpressionMapValue":
      return {
        ...expression,
        type: "StringConcatExpression",
        strings: getExpressionFromMap(
          exprMap,
          fallbackExprMap,
          expression.strings
        ),
      };
    case "GetUrlQueryParameterExpressionMapValue":
      return {
        ...expression,
        type: "GetUrlQueryParameterExpression",
        url: getExpressionFromMap(exprMap, fallbackExprMap, expression.url),
        queryParameterName: getExpressionFromMap(
          exprMap,
          fallbackExprMap,
          expression.queryParameterName
        ),
      };
    case "SplitExpressionMapValue":
      return {
        ...expression,
        type: "SplitExpression",
        expose: getExpressionFromMap(
          exprMap,
          fallbackExprMap,
          expression.expose
        ),
        unitId: getExpressionFromMap(
          exprMap,
          fallbackExprMap,
          expression.unitId
        ),
        dimensionMapping:
          expression.dimensionMapping.type ===
          "ExpressionMapValueDiscreteDimensionMapping"
            ? {
                ...expression.dimensionMapping,
                type: "discrete",
                cases: getRecordOfExpressionsFromMap(
                  exprMap,
                  fallbackExprMap,
                  expression.dimensionMapping.cases
                ),
              }
            : {
                ...expression.dimensionMapping,
                type: "continuous",
                function: getExpressionFromMap(
                  exprMap,
                  fallbackExprMap,
                  expression.dimensionMapping.function
                ),
              },
        eventPayload: getExpressionFromMap(
          exprMap,
          fallbackExprMap,
          expression.eventPayload
        ),
        featuresMapping: getRecordOfExpressionsFromMap(
          exprMap,
          fallbackExprMap,
          expression.featuresMapping
        ),
      };
    case "LogEventExpressionMapValue":
      return {
        ...expression,
        type: "LogEventExpression",
        unitId: getExpressionFromMap(
          exprMap,
          fallbackExprMap,
          expression.unitId
        ),
        eventPayload: getExpressionFromMap(
          exprMap,
          fallbackExprMap,
          expression.eventPayload
        ),
        featuresMapping: getRecordOfExpressionsFromMap(
          exprMap,
          fallbackExprMap,
          expression.featuresMapping
        ),
      };
    case "FunctionExpressionMapValue":
      return {
        ...expression,
        type: "FunctionExpression",
        body: getExpressionFromMap(exprMap, fallbackExprMap, expression.body),
      };

    default: {
      const neverExpression: never = expression;
      throw new Error(`unexpected expression: ${neverExpression}`);
    }
  }
}

function getArrayOfExpressionsFromMap(
  exprMap: ExpressionMap,
  fallbackExprMap: ExpressionMap,
  record: Record<string, ExpressionMapPointer>,
  weights: { [id: string]: number }
): (Expression | null)[] {
  return Object.entries(
    getRecordOfExpressionsFromMap(exprMap, fallbackExprMap, record)
  )
    .sort(([id1], [id2]) => weights[id1] - weights[id2])
    .map(([, itemExpr]) => itemExpr);
}

function getRecordOfExpressionsFromMap(
  exprMap: ExpressionMap,
  fallbackExprMap: ExpressionMap,
  record: Record<string, ExpressionMapPointer>
): Record<string, Expression | null> {
  return Object.fromEntries(
    Object.entries(record).map(([fieldName, fieldExpr]) => [
      fieldName,
      getExpressionFromMap(exprMap, fallbackExprMap, fieldExpr),
    ])
  );
}
