import React from "react";
import { Schema, ValueType } from "@hypertune/sdk/src/shared";
import {
  checkSchemaName,
  formatFieldSchemaName,
  getFieldArgumentsObjectTypeNameParts,
  valueTypeToString,
  toStartCase,
} from "@hypertune/shared-internal";
import { Variable } from "@hypertune/shared-internal/src/expression/types";
import {
  borderRadiusPx,
  normal,
  interFontFamily,
  mediumFontSize,
  downTriangleSymbol,
  rightTriangleSymbol,
  whiteBulletSymbol,
  greyHex,
} from "../../../lib/constants";
import MutableText from "../../../components/input/MutableText";
import { showSchemaNameError } from "../schema/typeEditor/SchemaNameError";
import VariableIcon from "../../../components/icons/Variable";
import TypeIcon from "../../../components/icons/TypeIcon";
import { useHypertune } from "../../../generated/hypertune.react";

export default function VariableNameControl({
  schema,
  variable,
  setVariableName,
  variableValue,
  style,
  isInput,
  readOnly,
  groupIndex = 5,
}: {
  schema: Schema;
  variable: Variable;
  setVariableName: (newVariableName: string) => void;
  variableValue?: React.ReactNode;
  style?: React.CSSProperties;
  isInput?: boolean;
  readOnly?: boolean;
  groupIndex?: number;
}): React.ReactElement {
  const styleObject: React.CSSProperties = {
    padding: 0,
    borderRadius: 2,
    font: interFontFamily,
    fontSize: mediumFontSize,
    ...(style || {}),
  };

  return (
    <div
      className={`${getVariableGroupIndexClassName(groupIndex)} ${readOnly ? "border border-transparent" : ""}`}
      style={{
        position: "relative",
        ...styleObject,
      }}
    >
      <div className="flex flex-row items-center gap-2">
        {isInput ? <TypeIcon type="input" size="small" /> : <VariableIcon />}
        <MutableText
          readOnly={readOnly}
          text={toStartCase(variable.name)}
          setText={async (newVariableName) => {
            await setVariableName(newVariableName);
          }}
          hasError={(newName) => {
            const newFormattedName = formatFieldSchemaName(newName);
            if (newFormattedName === variable.name) {
              return null;
            }
            const schemaCheck = checkSchemaName(newFormattedName);
            if (!schemaCheck.valid) {
              return schemaCheck;
            }
            return null;
          }}
          showError={showSchemaNameError}
          showPencil={false}
          style={{ marginLeft: -2 }}
        />
      </div>
      <VariableValuePreview
        groupIndex={groupIndex}
        variableValue={variableValue}
        fallback={
          <TypeInfo
            schema={schema}
            label={null}
            valueType={variable.valueType}
          />
        }
      />
    </div>
  );
}

export function VariableValuePreview({
  groupIndex,
  variableValue,
  fallback,
}: {
  groupIndex: number;
  variableValue?: React.ReactNode;
  fallback?: React.ReactNode;
}): React.ReactElement | null {
  const hypertune = useHypertune();
  const show = hypertune.showVariablePreview({ fallback: false });

  if (!show) {
    console.log("preview", { show });
    return null;
  }
  return (
    <div
      className={`absolute z-100 hidden pt-1 ${getVariableGroupIndexHoverClassName(groupIndex)}`}
    >
      <div
        className="rounded-lg shadow-dropdown"
        style={
          !variableValue
            ? {
                borderRadius: borderRadiusPx,
                backgroundColor: "white",
                padding: normal,
                paddingBottom: 0,
                border: `1px solid ${greyHex}`,
              }
            : {}
        }
      >
        {variableValue || fallback}
      </div>
    </div>
  );
}

function TypeInfo({
  schema,
  label,
  valueType,
}: {
  schema: Schema;
  label: string | null;
  valueType: ValueType;
}): React.ReactElement {
  const schemaObjectFields =
    valueType.type === "ObjectValueType"
      ? schema.objects[valueType.objectTypeName]?.fields
      : null;

  const showAnonymousObjectTypeName =
    !label &&
    valueType.type === "ObjectValueType" &&
    !!getFieldArgumentsObjectTypeNameParts(valueType.objectTypeName);

  const [isExpanded, setIsExpanded] = React.useState<boolean>(
    showAnonymousObjectTypeName
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          marginBottom: normal,
          cursor: schemaObjectFields ? "pointer" : "",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        onClick={
          schemaObjectFields
            ? (): void => setIsExpanded(!isExpanded)
            : undefined
        }
      >
        <div style={{ width: 15 }}>
          {schemaObjectFields
            ? isExpanded
              ? downTriangleSymbol
              : rightTriangleSymbol
            : whiteBulletSymbol}
        </div>
        <div style={{ whiteSpace: "nowrap" }}>
          {label ? `${label}: ` : ""}
          {showAnonymousObjectTypeName ? "{}" : valueTypeToString(valueType)}
        </div>
      </div>
      {schemaObjectFields && isExpanded ? (
        <div style={{ marginLeft: normal }}>
          {Object.entries(schemaObjectFields).map(([fieldName, field]) => (
            <TypeInfo
              key={fieldName}
              schema={schema}
              label={fieldName}
              valueType={field.valueType}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}

export function getVariableGroupIndexClassName(index: number): string {
  switch (index) {
    case 0:
      return "group/var-0";
    case 1:
      return "group/var-1";
    case 2:
      return "group/var-2";
    case 3:
      return "group/var-3";
    case 4:
      return "group/var-4";
    case 5:
      return "group/var-5";
    default:
      return "group";
  }
}

function getVariableGroupIndexHoverClassName(index: number): string {
  switch (index) {
    case 0:
      return "group-hover/var-0:block";
    case 1:
      return "group-hover/var-1:block";
    case 2:
      return "group-hover/var-2:block";
    case 3:
      return "group-hover/var-3:block";
    case 4:
      return "group-hover/var-4:block";
    case 5:
      return "group-hover/var-5:block";
    default:
      return "group-hover:block";
  }
}
