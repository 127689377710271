import { BooleanExpression, Expression } from "@hypertune/sdk/src/shared";
import { normal, singlePanelInnerHeight, small } from "../../../lib/constants";
import { ExpressionControlContext } from "../../../lib/types";
import Toggle from "../../../components/Toggle";
import isReadOnly from "../../../lib/expression/isReadOnly";

export default function BooleanExpressionControl({
  expression,
  context,
  setExpression,
  optionsButton,
}: {
  expression: BooleanExpression;
  context: ExpressionControlContext;
  setExpression: (newExpression: Expression | null) => void;
  optionsButton: React.ReactNode;
}): React.ReactElement {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: normal,
        marginLeft: -(normal - small),
        height: singlePanelInnerHeight,
      }}
    >
      <Toggle
        key={expression.id}
        disabled={isReadOnly(context)}
        className="mx-[1.5px]"
        value={expression.value}
        setValue={(newValue) => {
          setExpression({
            ...expression,
            value: newValue,
          });
        }}
      />
      {optionsButton && <div>{optionsButton}</div>}
    </div>
  );
}
