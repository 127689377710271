import { Intent } from "../../../components/intent";
import Panel from "../expression/Panel";

export default function EmptyPanel({
  intent,
  className,
}: {
  intent: Intent;
  className?: string;
}): React.ReactElement | null {
  return (
    <div className={className}>
      <Panel header={null} message={null} shouldStack={false} intent={intent}>
        None
      </Panel>
    </div>
  );
}
