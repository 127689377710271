import { useEffect } from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import { asError } from "@hypertune/sdk/src/shared";
import { useAppDispatch } from "./hooks";
import {
  resetLogicEditorState,
  setActiveCommit,
} from "../features/project/projectSlice";
import ModalWithContent from "../components/ModalWithContent";
import { useHypertune } from "../generated/hypertune.react";

export default function ErrorPage(): React.ReactElement {
  const content = useHypertune().content();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const routeError = useRouteError();
  const error = asError(routeError);

  useEffect(() => {
    console.error("App crash caught", error);
    dispatch(resetLogicEditorState());
    dispatch(setActiveCommit(undefined));
  }, [dispatch, error]);

  return (
    <ModalWithContent
      content={content.errorModal().get()}
      onClose={() => {
        navigate("/", { replace: true });
      }}
      onSave={() => {
        navigate("/", { replace: true });
      }}
    />
  );
}
