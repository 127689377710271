import { useCallback } from "react";
import { useUpgradePlanMutation } from "../../../generated/graphql";

export default function useUpgradeToProAction(): {
  upgrade: (businessId: string) => Promise<void>;
  loading: boolean;
} {
  const [upgradeMutation, { loading }] = useUpgradePlanMutation();

  const upgrade = useCallback(
    async (businessId: string) => {
      try {
        const result = await upgradeMutation({
          variables: { input: { businessId } },
        });
        if (result.data?.upgradePlan.url) {
          window.location.href = result.data?.upgradePlan.url;
        }
      } catch (error) {
        console.error("Error upgrading to pro", { error });
      }
    },
    [upgradeMutation]
  );
  return { upgrade, loading };
}
