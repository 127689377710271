import { Config } from "tailwindcss";

const config: Config = {
  content: ["./index.html", "./src/**/*.{js,ts,jsx,tsx}"],
  theme: {
    data: {
      top: 'anchor~="top"',
    },
    fontFamily: {
      inter: ["Inter"],
    },
    colors: {
      transparent: "transparent",
      current: "currentColor",
      white: "#ffffff",
      black: "#000000",
      base: {
        blue: "#5B67E7",
        green: "#3b9e62",
        red: "#ed544b",
        black: "#000000",
        white: "#ffffff",
        orange: "#ee954c",
        purple: "#754cdf",
        pink: "#e153b7",
        "dark-grey": "#303036",
      },
      intent: {
        neutral: { DEFAULT: "#4e576a", "05": "#F6F6F7" },
        warning: { DEFAULT: "#ee954c", 10: "#FDF4ED" },
        danger: { DEFAULT: "#ed544b", "05": "#FEF6F6" },
        success: { DEFAULT: "#3b9e62", "05": "#F5FAF7" },
        primary: { DEFAULT: "#5b67e7", "05": "#F6F7FD" },
      },
      "type-icon": {
        yellow: "#F3B03D",
        "light-blue": "#6B92C7",
        blue: "#636FE9",
        green: "#71AD77",
        purple: "#9682C9",
        lavender: "#b273e0",
      },
      "base-grey": {
        1: {
          light: "#fcfcfd",
          medium: "#eaecf1",
          dark: "#7a8396",
        },
        2: {
          light: "#f9f9fb",
          medium: "#c5c9d3",
          dark: "#4e576a",
        },
        3: {
          light: "#f4f5f8",
          medium: "#99a1b2",
          dark: "#22252b",
        },
      },

      // Text
      tx: {
        default: "#22252b",
        disabled: "#c5c9d3",
        muted: "#7a8396",
      },
      // Borders
      bd: {
        default: "#f4f5f8",
        light: "#f9f9fb",
        darker: "#eaecf1",
      },
      // Backgrounds
      bg: {
        default: "#ffffff",
        light: "#fcfcfd",
        medium: "#f9f9fb",
        hover: "#eaecf1",
        pressed: "#eaecf180",
        primary: "#5B67E71A",
        blurred: "rgba(0, 0, 0, 0.5)",
      },
    },
    fontSize: {
      "2xs": "0.625rem", // 10px
      xs: "0.688rem", // 11px
      sm: "0.75rem", // 12px
      base: "0.8125rem", // 13px
      md: "0.875rem", // 14px
      lg: "0.9375rem", // 15px
      h5: "1rem", // 16px
      h4: "1.125rem", // 18px
      h3: "1.25rem", // 20px
      h2: "1.5rem", // 24px
      h1: "1.625rem", // 26px
    },
    fontWeight: {
      light: "450",
      medium: "500",
      semibold: "600",
    },
    extend: {
      backgroundImage: {
        dotted: "url('/DottedBackground.png')",
      },
      borderColor: {
        DEFAULT: "#eaecf1",
      },
      textColor: {
        DEFAULT: "#22252b",
      },
      borderRadius: {
        "2xl": "14px",
      },
      boxShadow: {
        "notification-neutral": "0px 0px 0px 4px rgba(234, 236, 241, 0.70)",
        "notification-danger": "0px 0px 0px 4px rgba(237, 84, 75, 0.15)",
        "notification-warning": "0px 0px 0px 4px rgba(238, 149, 76, 0.15)",
        "notification-success": "0px 0px 0px 4px rgba(59, 158, 98, 0.15)",
        "notification-primary": "0px 0px 0px 4px rgba(91, 103, 231, 0.15)",
        inputs: "0px 0px 0px 2px rgba(91, 103, 231, 0.15)",
        "inputs-sm": "0px 0px 0px 2px rgba(67, 84, 255, 0.15)",
        "inputs-sm-error": "0px 0px 0px 2px rgba(237, 84, 75, 0.15)",
        "inputs-lg": "0px 0px 0px 3px rgba(91, 103, 231, 0.15)",
        dropdown: "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
        button: "0px 1px 3px 0px rgba(0, 0, 0, 0.06)",
        container: "0px 1px 5px 0px rgba(0, 0, 0, 0.04)",
        elevated: "0px 4px 30px 0px rgba(48, 48, 54, 0.08)",
        "progress-dot":
          "0px 0px 0px 3px rgba(91, 103, 231, 0.10), 0px 1px 5px 0px rgba(0, 0, 0, 0.04)",
      },
      flexShrink: {
        2: "2",
      },
      screens: {
        "md-h": { raw: "(min-height:700px)" },
        "lg-h": { raw: "(min-height:900px)" },
      },
      zIndex: {
        100: "100",
        200: "200",
      },
    },
  },
  plugins: [],
};

export default config;
