import { getWebEnvironment } from "@hypertune/shared-internal";
import prod from "./env.prod";
import staging from "./env.staging";
import local from "./env.local";
import { Env } from "./type";

function getEnv(): Env {
  return { local, staging, prod }[getWebEnvironment()];
}

const env: Env = getEnv();

export default env;
