import { MagnifyingGlass } from "@phosphor-icons/react";
import { lighterGreyHex } from "../lib/constants";
import TextInput from "./input/TextInput";

export default function SearchInput({
  searchText,
  setSearchText,
  style,
  focusOnMount,
  noFocusHighlight,
  action,
}: {
  searchText: string;
  setSearchText?: (text: string) => void;
  style?: React.CSSProperties;
  focusOnMount?: boolean;
  noFocusHighlight?: boolean;
  action?: React.ReactNode;
}): React.ReactElement {
  return (
    <TextInput
      focusOnMount={focusOnMount}
      size="medium"
      icon={
        <MagnifyingGlass
          color={lighterGreyHex}
          weight="regular"
          style={{ marginRight: 8 }}
        />
      }
      endIcon={action}
      style={{
        padding: 8,
        height: 32,
        color: lighterGreyHex,
        ...(style || {}),
      }}
      noFocusHighlight={noFocusHighlight}
      value={searchText}
      onChange={
        setSearchText ||
        ((newText) => {
          // Dummy
        })
      }
      trimOnBlur={false}
      placeholder="Search..."
      readOnly={setSearchText === undefined}
    />
  );
}
