import React from "react";
import { EnumExpression, Expression } from "@hypertune/sdk/src/shared";
import {
  borderRadiusPx,
  optionsButtonRight,
  optionsButtonTop,
  singlePanelHeight,
} from "../../../lib/constants";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
} from "../../../lib/types";
import toLabeledOption from "../../../lib/toLabeledOption";
import Dropdown from "../../../components/Dropdown";
import isReadOnly from "../../../lib/expression/isReadOnly";

export default function EnumExpressionControl({
  context,
  expression,
  setExpression,
  parentExpression,
  includeExpressionOption,
  shouldStack,
  optionsButton,
}: {
  context: ExpressionControlContext;
  expression: EnumExpression;
  setExpression: (newExpression: Expression | null) => void;
  parentExpression: Expression | null;
  includeExpressionOption: IncludeExpressionOptionFunction;
  shouldStack: boolean;
  optionsButton: React.ReactNode;
}): React.ReactElement {
  const schemaEnumValues =
    context.commitContext.schema.enums[expression.valueType.enumTypeName]
      ?.values || {};

  const options = Object.entries(schemaEnumValues)
    .filter(
      ([enumValue, valueSchema]) =>
        valueSchema.deprecationReason === undefined &&
        includeExpressionOption({
          expressionOption: {
            ...expression,
            value: enumValue,
          },
          expressionOptionParent: parentExpression,
        })
    )
    .map(([valueName]) => toLabeledOption(valueName));

  const readOnly = isReadOnly(context);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Dropdown
        focusOnMount={
          context.expressionEditorState.selectedItem?.id === expression.id
        }
        intent={context.expressionIdToIntent?.[expression.id] ?? "neutral"}
        disabled={readOnly}
        hideIndicator={readOnly}
        style={{ flexGrow: 1 }}
        height={singlePanelHeight}
        indicatorsContainerWidthOffset={optionsButton ? 28 : 0}
        controlStyle={{
          borderRadius: shouldStack
            ? `0 0 ${borderRadiusPx}px ${borderRadiusPx}px`
            : borderRadiusPx,
        }}
        options={{ type: "options", options }}
        value={toLabeledOption(expression.value)}
        onChange={(option) => {
          if (!option) {
            return;
          }
          setExpression({
            ...expression,
            value: option.value,
          });
        }}
        placeholder="Select enum value..."
        noOptionsMessage="No enum values found"
      />
      {optionsButton ? (
        <div
          style={{
            position: "absolute",
            top: optionsButtonTop,
            right: optionsButtonRight,
          }}
        >
          {optionsButton}
        </div>
      ) : null}
    </div>
  );
}
