import { Schema } from "@hypertune/sdk/src/shared";
import { NotePencil } from "@phosphor-icons/react";
import {
  removeEnumValue,
  setEnumValueDeprecationReason,
  setEnumValueDescription,
  toStartCase,
} from "@hypertune/shared-internal";
import { useAppDispatch } from "../../../../../app/hooks";
import { setDraftCommitSchema } from "../../../projectSlice";
import DeleteWithConfirmationButton from "../DeleteWithConfirmationButton";
import TypeIcon from "../../../../../components/icons/TypeIcon";
import {
  Description,
  DetailContainer,
  Status,
} from "../../../../../components/Details";
import StatusChangeButton from "../StatusChangeButton";

export default function EnumValueDetails({
  readOnly,
  schema,
  enumTypeName,
  enumValueName,
  onClose,
  trackEditing,
}: {
  readOnly: boolean;
  schema: Schema;
  enumTypeName: string;
  enumValueName: string;
  onClose: () => void;
  trackEditing?: React.Dispatch<React.SetStateAction<Set<string>>>;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const enumSchema = schema.enums[enumTypeName];
  const enumValue = enumSchema.values[enumValueName];
  const disableActions = readOnly || Object.keys(enumSchema).length <= 1;

  return (
    <>
      <Description
        editTracking={
          trackEditing
            ? {
                id: "enum-value-description",
                track: trackEditing,
              }
            : undefined
        }
        readOnly={readOnly}
        icon={<TypeIcon type="enum" />}
        title={toStartCase(enumValueName)}
        text={enumValue.description || ""}
        onClose={onClose}
        setText={(newDescription) =>
          dispatch(
            setDraftCommitSchema(
              setEnumValueDescription(
                schema,
                enumTypeName,
                enumValueName,
                newDescription || null
              )
            )
          )
        }
      />
      <Status
        currentStatus={
          enumValue.deprecationReason === undefined ? "Active" : "Deprecated"
        }
        intent={
          enumValue.deprecationReason === undefined ? "success" : "neutral"
        }
      />
      {!disableActions && (
        <DetailContainer icon={<NotePencil />} title="Actions">
          <div className="flex flex-row gap-2">
            <StatusChangeButton
              deprecationReason={enumValue.deprecationReason}
              setDeprecationReason={(reason) => {
                dispatch(
                  setDraftCommitSchema(
                    setEnumValueDeprecationReason(
                      schema,
                      enumTypeName,
                      enumValueName,
                      reason
                    )
                  )
                );
              }}
            />
            <DeleteWithConfirmationButton
              entityName="value"
              onClick={() => {
                dispatch(
                  setDraftCommitSchema(
                    removeEnumValue(schema, enumTypeName, enumValueName)
                  )
                );
                onClose();
              }}
            />
          </div>
        </DetailContainer>
      )}
    </>
  );
}
