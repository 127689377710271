import {
  getEmptyLogs,
  FunctionExpression,
  ValueType,
  uniqueId,
  SwitchExpression,
  Expression,
  getBooleanExpression,
  getStringExpression,
  getIntExpression,
  getFloatExpression,
  Schema,
} from "@hypertune/sdk/src/shared";
import getParameters from "./getParameters";
import getDefaultExpression from "./getDefaultExpression";
import getConstraintFromValueType from "./constraint/getConstraintFromValueType";
import getLogEventExpression from "./getLogEventExpression";
import { VariableMap } from "./types";
import getContextExpression from "./getContextExpression";

export type DefaultValues = {
  boolean?: boolean;
  string?: string;
  int?: number;
  float?: number;
  eventTypeNameAndId?: {
    id: string;
    name: string;
    payload?: {
      contextTypeName: string;
      rootArgsVariableId: string;
    };
  };

  disableIf?: boolean;
  complexTypes?: boolean;
};

export default function getEmptyExpression(
  schema: Schema,
  variables: VariableMap,
  valueType: ValueType,
  defaultValues: DefaultValues | null
): null | FunctionExpression {
  if (valueType.type !== "FunctionValueType") {
    return null;
  }

  const parameters = getParameters(variables, valueType.parameterValueTypes);
  const functionExpression: FunctionExpression = {
    id: uniqueId(),
    logs: getEmptyLogs(),
    type: "FunctionExpression",
    valueType,
    parameters,
    body: null,
  };
  if (defaultValues) {
    switch (valueType.returnValueType.type) {
      case "BooleanValueType":
        if (defaultValues.boolean !== undefined) {
          const booleanExpression = getBooleanExpression(defaultValues.boolean);
          functionExpression.body = defaultValues.disableIf
            ? booleanExpression
            : emptySwitchExpression(
                valueType.returnValueType,
                booleanExpression
              );
        }
        break;
      case "StringValueType":
        if (defaultValues.string !== undefined) {
          const stringExpression = getStringExpression(defaultValues.string);
          functionExpression.body = defaultValues.disableIf
            ? stringExpression
            : emptySwitchExpression(
                valueType.returnValueType,
                stringExpression
              );
        }
        break;
      case "IntValueType":
        if (defaultValues.int !== undefined) {
          const intExpression = getIntExpression(defaultValues.int);
          functionExpression.body = defaultValues.disableIf
            ? intExpression
            : emptySwitchExpression(valueType.returnValueType, intExpression);
        }
        break;
      case "FloatValueType":
        if (defaultValues.float !== undefined) {
          const floatExpression = getFloatExpression(defaultValues.float);
          functionExpression.body = defaultValues.disableIf
            ? floatExpression
            : emptySwitchExpression(valueType.returnValueType, floatExpression);
        }
        break;
      case "VoidValueType":
        if (defaultValues.eventTypeNameAndId) {
          const { name, id, payload } = defaultValues.eventTypeNameAndId;
          functionExpression.body = getLogEventExpression(name, id);
          functionExpression.body.unitId = getStringExpression("");
          if (payload) {
            functionExpression.body.eventPayload = {
              id: uniqueId(),
              logs: getEmptyLogs(),
              type: "ObjectExpression",
              valueType: { type: "ObjectValueType", objectTypeName: name },
              objectTypeName: name,
              fields: {
                context: getContextExpression(
                  payload.contextTypeName,
                  payload.rootArgsVariableId
                ),
              },
            };
          }
        }
        break;
      case "EnumValueType":
        if (defaultValues.complexTypes) {
          const enumExpression = getDefaultExpression(
            schema,
            variables,
            getConstraintFromValueType(valueType.returnValueType),
            new Set<string>()
          ) as Expression;

          functionExpression.body = defaultValues.disableIf
            ? enumExpression
            : emptySwitchExpression(valueType.returnValueType, enumExpression);
        }
        break;

      case "ObjectValueType":
      case "ListValueType":
        if (defaultValues.complexTypes) {
          functionExpression.body = getDefaultExpression(
            schema,
            variables,
            getConstraintFromValueType(valueType.returnValueType),
            new Set<string>()
          );
        }
        break;

      default:
    }
  }
  return functionExpression;
}

function emptySwitchExpression(
  valueType: ValueType,
  defaultValue: Expression
): SwitchExpression {
  return {
    id: uniqueId(),
    logs: getEmptyLogs(),
    type: "SwitchExpression",
    valueType,
    control: getBooleanExpression(true),
    cases: [],
    default: defaultValue,
  };
}
