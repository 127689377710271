import React from "react";
import { Code as CodeIcon, SealCheck, TextT } from "@phosphor-icons/react";
import Label from "./Label";
import CodeEditor from "./CodeEditor";
import CloseButton from "./buttons/CloseButton";
import { EditTracking } from "../lib/types";
import MutableTextArea from "./MutableTextArea";
import { useAppSelector } from "../app/hooks";
import { Intent } from "./intent";
import Tag from "./Tag";
import twMerge from "../lib/twMerge";

const descriptionPlaceholder = "Add description...";
const readOnlyDescriptionPlaceholder = "No description.";

export function Description({
  readOnly,
  icon,
  title,
  text,
  setText,
  onClose,
  editTracking,
}: {
  readOnly?: boolean;
  icon?: React.ReactNode;
  title?: string;
  text: string;
  setText?: (newText: string) => void;
  onClose?: () => void;
  editTracking?: EditTracking;
}): React.ReactElement {
  const placeholder = readOnly
    ? readOnlyDescriptionPlaceholder
    : descriptionPlaceholder;

  return (
    <DetailContainer
      icon={icon || <TextT weight="regular" />}
      title={title || "Description"}
      onClose={onClose}
    >
      <MutableTextArea
        readOnly={readOnly}
        placeholder={placeholder}
        text={text}
        setText={setText}
        editTracking={editTracking}
        className="-mx-2"
      />
    </DetailContainer>
  );
}

export function Status({
  currentStatus,
  intent,
}: {
  currentStatus: string;
  intent: Intent;
}): React.ReactElement | null {
  return (
    <DetailContainer>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          <SealCheck weight="regular" />
          <Label type="title3" className="py-[6px] leading-4 text-tx-default">
            Status
          </Label>
        </div>

        <Tag text={currentStatus} intent={intent} />
      </div>
    </DetailContainer>
  );
}

export function Code({ code }: { code: string }): React.ReactElement {
  return (
    <DetailContainer icon={<CodeIcon weight="regular" />} title="Schema">
      <CodeEditor
        code={code}
        readOnly
        setCode={() => {
          // Dummy
        }}
        language="graphql"
        className="w-full overflow-x-auto rounded-xl border bg-bg-light p-2"
        style={{
          minWidth:
            Math.max(...code.split("\n").map((line) => line.length)) * 8.5,
        }}
      />
    </DetailContainer>
  );
}

export function DetailContainer({
  icon,
  title,
  children,
  onClose,
}: {
  icon?: React.ReactNode;
  title?: string;
  children: React.ReactNode;
  onClose?: () => void;
}): React.ReactElement {
  return (
    <ContentContainer>
      <div className="flex flex-col gap-[15px] p-[25px]">
        {(icon || title || onClose) && (
          <div className="flex flex-row items-end justify-between">
            {(icon || title) && (
              <div className="flex max-w-full flex-row items-center gap-2 overflow-hidden">
                {icon || null}
                {title && (
                  <Label
                    type="title3"
                    className="max-w-full truncate leading-4 text-tx-default"
                  >
                    {title}
                  </Label>
                )}
              </div>
            )}
            {onClose && <CloseButton onClick={onClose} />}
          </div>
        )}

        {children}
      </div>
    </ContentContainer>
  );
}

export function ContentContainer({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}): React.ReactElement | null {
  return (
    <div
      className={twMerge(
        "flex flex-col items-stretch border-b border-bd-darker last:border-b-0",
        className
      )}
    >
      {children}
    </div>
  );
}

export function DetailsContainer({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}): React.ReactElement | null {
  const show = useAppSelector((state) => state.project.showDetails);
  if (!show) {
    return null;
  }
  return (
    <DetailsContainerBase className={className}>
      {children}
    </DetailsContainerBase>
  );
}

export function DetailsContainerBase({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}): React.ReactElement | null {
  return (
    <div
      className={twMerge(
        "min-w-[350px] max-w-[350px] overflow-y-auto overflow-x-hidden border-l border-bd-darker bg-white",
        className
      )}
    >
      {children}
    </div>
  );
}
