import {
  SwitchExpression,
  ValueType,
  getBooleanExpression,
  getEmptyLogs,
  uniqueId,
} from "@hypertune/sdk/src/shared";
import getComparisonExpression from "./getComparisonExpression";

export default function getIfExpression(
  valueType: ValueType
): SwitchExpression {
  const trueExpression = getBooleanExpression(true);
  const initialCase = {
    id: uniqueId(),
    when: getComparisonExpression(),
    then:
      valueType.type === "BooleanValueType" ? getBooleanExpression(true) : null,
  };
  const defaultExpression =
    valueType.type === "BooleanValueType" ? getBooleanExpression(false) : null;
  const ifElseExpression: SwitchExpression = {
    id: uniqueId(),
    logs: getEmptyLogs(),
    type: "SwitchExpression",
    valueType,
    control: trueExpression,
    cases: [initialCase],
    default: defaultExpression,
  };
  return ifElseExpression;
}
