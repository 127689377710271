import { lighterGreyHex } from "../../lib/constants";

export default function RightSidebar({
  className,
}: {
  className?: string;
}): React.ReactElement {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1848_6136)">
        <rect x="8.5" y="1.5" width="5" height="15" fill={lighterGreyHex} />
        <rect x="8.5" y="1.5" width="5" height="15" stroke={lighterGreyHex} />
      </g>
      <rect
        x="1"
        y="1"
        width="12"
        height="12"
        rx="3.5"
        stroke={lighterGreyHex}
      />
      <defs>
        <clipPath id="clip0_1848_6136">
          <rect x="0.5" y="0.5" width="13" height="13" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
