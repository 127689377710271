import { rootObjectTypeNameFromSchema } from "@hypertune/shared-internal";
import { fieldPathSeparator } from "@hypertune/sdk/src/shared";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setObjectAddFieldModalState } from "../projectSlice";
import Tooltip from "../../../components/tooltips/Tooltip";
import PlusButton from "../../../components/buttons/PlusButton";
import { useHypertune } from "../../../generated/hypertune.react";

export default function NewFlagButton({
  defaultFieldPath,
}: {
  defaultFieldPath: string[];
}): React.ReactElement | null {
  const content = useHypertune().content();
  const dispatch = useAppDispatch();
  const schema = useAppSelector((state) => state.project.draftCommit?.schema);
  const expression = useAppSelector(
    (state) => state.project.draftCommit?.expression
  );

  if (!schema || !expression) {
    return null;
  }

  const rootObjectTypeName = rootObjectTypeNameFromSchema(schema);

  return (
    <Tooltip id="logic" step={1} allSteps={content.logic().tooltips()}>
      <PlusButton
        onClick={() =>
          dispatch(
            setObjectAddFieldModalState({
              objectTypeName: rootObjectTypeName,
              fieldPosition: "first",
              entity: {
                name: "flag",
                parentFieldPath: defaultFieldPath.join(fieldPathSeparator),
              },
            })
          )
        }
      />
    </Tooltip>
  );
}
