import {
  getEmptyLogs,
  getStringExpression,
  LogEventExpression,
  uniqueId,
} from "@hypertune/sdk/src/shared";

export default function getLogEventExpression(
  eventObjectTypeName: string | null,
  eventTypeId: string | null
): LogEventExpression {
  return {
    id: uniqueId(),
    logs: getEmptyLogs(),
    type: "LogEventExpression",
    valueType: { type: "VoidValueType" },
    eventTypeId,
    unitId: getStringExpression(""),
    featuresMapping: {},
    eventObjectTypeName,
    eventPayload: null,
  };
}
