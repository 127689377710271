import { Schema, Expression } from "@hypertune/sdk/src/shared";
import { useCallback } from "react";
import ObjectFieldDetails from "./object/ObjectFieldDetails";
import { SelectedType, TypeReferencesMap } from "../schemaHooks";
import EnumValueDetails from "./enum/EnumValueDetails";
import TypeDetails from "./TypeDetails";
import { DetailsContainer } from "../../../../components/Details";

export default function TypeEditorDetails({
  readOnly,
  schema,
  expression,
  selectedType,
  setSelectedType,
  trackEditing,
  setErrorMessage,
  disableActions,
  typeReferences,
  isSelectedTypeRoot,
  isSelectedTypeContext,
}: {
  readOnly: boolean;
  schema: Schema;
  expression: Expression;
  selectedType: SelectedType;
  setSelectedType: (newSelectedType: SelectedType | null) => void;
  trackEditing: React.Dispatch<React.SetStateAction<Set<string>>>;
  setErrorMessage: (newErrorMessage: string | null) => void;
  disableActions: boolean;
  typeReferences: TypeReferencesMap;
  isSelectedTypeRoot: boolean;
  isSelectedTypeContext: boolean;
}): React.ReactElement | null {
  const selectedChildExists =
    selectedType === null || selectedType.selectedChildName === null
      ? false
      : selectedType.type === "enum"
        ? !!schema.enums[selectedType.name] &&
          !!schema.enums[selectedType.name].values[
            selectedType.selectedChildName
          ]
        : !!schema.objects[selectedType.name] &&
          !!schema.objects[selectedType.name].fields[
            selectedType.selectedChildName
          ];
  const deselectChild = useCallback(() => {
    setSelectedType({
      ...selectedType,
      selectedChildName: null,
    });
  }, [setSelectedType, selectedType]);

  return (
    <DetailsContainer>
      {!selectedChildExists && (
        <TypeDetails
          readOnly={readOnly}
          schema={schema}
          expression={expression}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          setErrorMessage={setErrorMessage}
          disableActions={disableActions}
          typeReferences={typeReferences}
          isSelectedTypeRoot={isSelectedTypeRoot}
          isSelectedTypeContext={isSelectedTypeContext}
        />
      )}
      {selectedType.selectedChildName && selectedChildExists && (
        <>
          {selectedType.type !== "enum" && (
            <ObjectFieldDetails
              readOnly={readOnly}
              view="schema"
              schema={schema}
              expression={expression}
              objectTypeName={selectedType.name}
              fieldName={selectedType.selectedChildName}
              onDelete={deselectChild}
              onClose={deselectChild}
              trackEditing={trackEditing}
            />
          )}
          {selectedType.type === "enum" && (
            <EnumValueDetails
              readOnly={readOnly}
              schema={schema}
              enumTypeName={selectedType.name}
              enumValueName={selectedType.selectedChildName}
              onClose={deselectChild}
              trackEditing={trackEditing}
            />
          )}
        </>
      )}
    </DetailsContainer>
  );
}
