import {
  buildSchema as buildGraphQLSchema,
  GraphQLNonNull,
  GraphQLSchema,
  GraphQLType,
  isEnumType,
  isInputObjectType,
  isInterfaceType,
  isNonNullType,
  isObjectType,
  isScalarType,
  isUnionType,
} from "graphql";
import isValidGraphqlInputType from "./isValidGraphqlInputType";
import { GraphQLScalarTypeName, graphqlScalarTypeNames } from "../types";
import {
  queryObjectTypeName,
  reservedTypeNames,
  rootFieldName,
} from "../constants";

export default function getGraphqlSchema(schemaCode: string): GraphQLSchema {
  const graphqlSchema = buildGraphQLSchema(
    addScalarTypesAndDirectivesToSchemaCode(schemaCode)
  );

  validateGraphqlSchema(graphqlSchema);
  return graphqlSchema;
}

export function addScalarTypesAndDirectivesToSchemaCode(code: string): string {
  return `${graphqlScalarTypeNames
    .map((s) => `scalar ${s}`)
    .join("\n")}\n\ndirective @event on INPUT_OBJECT\n\n${code}`;
}

/**
 * TODO
 * - Prevent circular non-null input object types
 * - Prevent enums with no values
 */
function validateGraphqlSchema(graphqlSchema: GraphQLSchema): void {
  const queryType = graphqlSchema.getQueryType();
  if (!queryType) {
    throw new Error(`You must have a root "${queryObjectTypeName}" type.`);
  }

  const queryTypeFieldNames = Object.keys(queryType.getFields());
  if (
    queryTypeFieldNames.length !== 1 ||
    queryTypeFieldNames[0] !== rootFieldName
  ) {
    throw new Error(
      `The "${queryObjectTypeName}" type must have exactly one field called "${rootFieldName}".`
    );
  }

  const typeMap = graphqlSchema.getTypeMap();
  Object.keys(typeMap).forEach((typeName) => {
    if (typeName.startsWith("__")) {
      return;
    }

    if (typeName.includes("_")) {
      throw new Error(
        `You cannot use underscores in type names. Please rename type "${typeName}".`
      );
    }

    if (reservedTypeNames.includes(typeName)) {
      throw new Error(
        `Type name "${typeName}" is reserved. Please rename this type.`
      );
    }

    const type = typeMap[typeName];

    if (isScalarType(type)) {
      if (
        !graphqlScalarTypeNames.includes(type.name as GraphQLScalarTypeName)
      ) {
        throw new Error(`Unsupported scalar type: "${type.name}"`);
      }
    }

    if (isEnumType(type)) {
      if (type.getValues().length === 0) {
        throw new Error(
          `You must add at least one value to the enum "${typeName}".`
        );
      }
    }

    if (isObjectType(type)) {
      const fieldMap = type.getFields();
      Object.keys(fieldMap).forEach((fieldName) => {
        const field = fieldMap[fieldName];

        if (field.name.startsWith("__")) {
          throw new Error(
            `You cannot start field names with '__'. Please rename field "${field.name}".`
          );
        }

        if (!isNonNullType(field.type)) {
          throw new Error(
            `All fields must have a non-null type. Append '!' to the type of field "${field.name}" of object type "${typeName}".`
          );
        }

        if (
          isInputObjectType((field.type as GraphQLNonNull<GraphQLType>).ofType)
        ) {
          throw new Error(
            `Invalid type for field "${fieldName}" of object type "${typeName}". Input types cannot be used in output types.`
          );
        }

        field.args.forEach((arg) => {
          if (!isValidGraphqlInputType(arg.type)) {
            throw new Error(
              `Invalid type for argument "${arg.name}" of field "${fieldName}" of object type "${typeName}". Field arguments must be non-nullable scalars, enums or input object types.`
            );
          }
        });
      });
    }

    if (isUnionType(type)) {
      const types = type.getTypes();

      if (types.length === 0) {
        throw new Error(
          `Union type "${type.name}" must have at least one member.`
        );
      }

      types.forEach((member: GraphQLType) => {
        if (!isObjectType(member)) {
          throw new Error(
            `The "${
              type.name
            }" union type contains an invalid member "${member.toString()}". Union types can only contain object types.`
          );
        }
      });
    }

    if (isInterfaceType(type)) {
      throw new Error(`Interface types like "${typeName}" are not allowed.`);
    }

    if (isInputObjectType(type)) {
      const isEvent = isEventType(type);
      const fieldMap = type.getFields();
      Object.keys(fieldMap).forEach((fieldName) => {
        const field = fieldMap[fieldName];

        if (field.name.startsWith("__")) {
          throw new Error(
            `You cannot start field names with '__'. Please rename field ${field.name}`
          );
        }

        if (!isNonNullType(field.type)) {
          throw new Error(
            `All fields must have a non-null type. Append '!' to the type of field "${field.name}" of input object type "${typeName}".`
          );
        }

        if (!isValidGraphqlInputType(field.type)) {
          throw new Error(
            `Invalid type for field "${fieldName}" of input object type "${typeName}". Input object fields must be non-nullable scalars, enums or input object types.`
          );
        }

        if (field.deprecationReason) {
          throw new Error(
            `Deprecated field "${fieldName}" of input object type "${typeName}". Fields of input objects can't be marked as deprecated, delete them instead.`
          );
        }

        if (
          !isEvent &&
          isInputObjectType(field.type.ofType) &&
          isEventType(field.type.ofType)
        ) {
          throw new Error(
            `Invalid type for field "${fieldName}" of input object type "${typeName}". Input object fields can't be events.`
          );
        }
      });
    }
  });
}

export function isEventType(type: GraphQLType): boolean {
  return (
    (isInputObjectType(type) &&
      type.astNode?.directives?.some(
        (directive) => directive.name.value === "event"
      )) ??
    false
  );
}
