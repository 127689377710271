import {
  FunctionValueType,
  ObjectFieldSchema,
  ObjectValueType,
  Schema,
  ValueType,
} from "@hypertune/sdk/src/shared/types";
import { toStartCaseWords } from "../toStartCase";
import { queryObjectTypeName, rootFieldName } from "../constants";
import isSchemaNameValid, {
  alreadyExistsMessage,
  schemaNameErrorMessage,
} from "./isSchemaNameValid";
import {
  getFieldArgumentsObjectTypeName,
  getFieldArgumentsObjectTypeNameParts,
} from "./fieldArgumentsObjectTypeName";
import { renameObjectInValueType } from "./valueTypeOperations";

export type FieldPosition = "first" | "last";

export function addEmptyObject(
  schema: Schema,
  objectTypeName: string,
  role: "input" | "output" | "event",
  description: string | null
): Schema {
  if (!isSchemaNameValid(objectTypeName)) {
    throw new Error(schemaNameErrorMessage("Object", objectTypeName));
  }
  if (
    schema.objects[objectTypeName] ||
    schema.unions[objectTypeName] ||
    schema.enums[objectTypeName]
  ) {
    throw new Error(alreadyExistsMessage("Type", objectTypeName));
  }

  return {
    ...schema,
    objects: {
      ...schema.objects,
      [objectTypeName]: {
        description,
        role,
        fields: {},
      },
    },
  };
}

export function addDefaultEvent(
  schema: Schema,
  objectTypeName: string,
  description: string | null
): Schema {
  if (!isSchemaNameValid(objectTypeName)) {
    throw new Error(schemaNameErrorMessage("Object", objectTypeName));
  }
  if (
    schema.objects[objectTypeName] ||
    schema.unions[objectTypeName] ||
    schema.enums[objectTypeName]
  ) {
    throw new Error(alreadyExistsMessage("Type", objectTypeName));
  }
  const contextObjectTypeName = rootContextTypeNameFromSchema(schema);
  const newSchema: Schema = {
    ...schema,
    objects: {
      ...schema.objects,
      [objectTypeName]: {
        description,
        role: "event",
        fields: {},
      },
    },
  };

  return contextObjectTypeName
    ? addFieldToBasicObject(
        newSchema,
        objectTypeName,
        "context",
        { type: "ObjectValueType", objectTypeName: contextObjectTypeName },
        null,
        "first"
      )
    : newSchema;
}

export function renameObject(
  schema: Schema,
  oldObjectTypeName: string,
  rawNewObjectTypeName: string
): Schema {
  const newObjectTypeName = formatTypeSchemaName(rawNewObjectTypeName);
  if (oldObjectTypeName === newObjectTypeName) {
    return schema;
  }

  if (!isSchemaNameValid(newObjectTypeName)) {
    throw new Error(schemaNameErrorMessage("Object", newObjectTypeName));
  }
  if (!schema.objects[oldObjectTypeName]) {
    throw new Error(`oldObjectTypeName does not exist: ${oldObjectTypeName}`);
  }
  if (
    schema.objects[newObjectTypeName] ||
    schema.unions[newObjectTypeName] ||
    schema.enums[newObjectTypeName]
  ) {
    throw new Error(alreadyExistsMessage("Type", newObjectTypeName));
  }

  return {
    ...schema,
    objects: Object.fromEntries(
      Object.entries(schema.objects).map(([objectName, objectSchema]) => {
        const newObjectSchema = {
          ...objectSchema,
          fields: Object.fromEntries(
            Object.entries(objectSchema.fields).map(
              ([fieldName, fieldSchema]) => [
                fieldName,
                {
                  ...fieldSchema,
                  valueType: renameObjectInValueType(
                    fieldSchema.valueType,
                    oldObjectTypeName,
                    newObjectTypeName
                  ),
                },
              ]
            )
          ),
        };
        if (objectName === oldObjectTypeName) {
          return [newObjectTypeName, newObjectSchema];
        }

        const argsObjectParts =
          getFieldArgumentsObjectTypeNameParts(objectName);
        if (
          argsObjectParts !== null &&
          argsObjectParts.parentObjectTypeName === oldObjectTypeName
        ) {
          return [
            getFieldArgumentsObjectTypeName({
              parentObjectTypeName: newObjectTypeName,
              fieldName: argsObjectParts.fieldName,
            }),
            newObjectSchema,
          ];
        }
        return [objectName, newObjectSchema];
      })
    ),
    unions: Object.fromEntries(
      Object.entries(schema.unions).map(([unionName, unionSchema]) => [
        unionName,
        {
          ...unionSchema,
          variants: Object.fromEntries(
            Object.entries(unionSchema.variants).map(
              ([variantName, variantValue]) => [
                variantName === oldObjectTypeName
                  ? newObjectTypeName
                  : variantName,
                variantValue,
              ]
            )
          ),
        },
      ])
    ),
  };
}

export type RemovedFields = { [objectTypeName: string]: string[] };

export function removeObject(
  schema: Schema,
  objectTypeName: string
): { schema: Schema; removedFields: RemovedFields } {
  if (!schema.objects[objectTypeName]) {
    throw new Error(`objectTypeName does not exist: ${objectTypeName}`);
  }
  Object.entries(schema.unions).forEach(([unionName, unionSchema]) => {
    if (
      unionSchema.variants[objectTypeName] &&
      Object.keys(unionSchema.variants).length === 1
    ) {
      throw new Error(
        `Object "${objectTypeName}" is the only variant of a union "${unionName}" add more variants or remove the union first`
      );
    }
  });

  const fieldsToRemove: RemovedFields = {};
  Object.entries(schema.objects)
    .filter(([objectName]) => objectName !== objectTypeName)
    .forEach(([objectName, objectType]) => {
      const objectFields = Object.keys(objectType.fields);
      const objectFieldsToRemove = objectFields.filter((fieldName) =>
        valueTypeReturnsObject(
          objectType.fields[fieldName].valueType,
          objectTypeName
        )
      );
      if (
        getFieldArgumentsObjectTypeNameParts(objectName) === null &&
        objectFieldsToRemove.length > 0 &&
        objectFields.length - objectFieldsToRemove.length === 0
      ) {
        throw new Error(
          `Removing object "${objectTypeName}" would result in object "${objectName}" being empty. Delete it or add other fields to it first.`
        );
      }
      if (objectFieldsToRemove.length > 0) {
        fieldsToRemove[objectName] = objectFieldsToRemove;
      }
    });
  fieldsToRemove[objectTypeName] = Object.keys(
    schema.objects[objectTypeName].fields
  );

  return {
    schema: {
      ...schema,
      objects: Object.fromEntries(
        Object.entries(
          removeFieldsFromObjects(schema, fieldsToRemove).objects
        ).filter(([objectName]) => objectName !== objectTypeName)
      ),
      unions: Object.fromEntries(
        Object.entries(schema.unions).map(([unionName, unionSchema]) => [
          unionName,
          {
            ...unionSchema,
            variants: Object.fromEntries(
              Object.entries(unionSchema.variants).filter(
                ([variantName]) => variantName !== objectTypeName
              )
            ),
          },
        ])
      ),
    },
    removedFields: fieldsToRemove,
  };
}

export function cloneObject(
  schema: Schema,
  sourceObjectTypeName: string,
  newObjectTypeName: string,
  newObjectRole: "input" | "output" | "event"
): Schema {
  if (!schema.objects[sourceObjectTypeName]) {
    throw new Error(
      `sourceObjectTypeName does not exist: ${sourceObjectTypeName}`
    );
  }
  if (schema.objects[sourceObjectTypeName].role === "args") {
    throw new Error(
      `can't clone args objects explicitly: ${sourceObjectTypeName}`
    );
  }

  return Object.entries(
    schema.objects[sourceObjectTypeName].fields
  ).reduce<Schema>(
    (newSchema, [fieldName, fieldSchema]) => {
      const valueType =
        fieldSchema.valueType.type === "FunctionValueType"
          ? fieldSchema.valueType.returnValueType
          : fieldSchema.valueType;

      return addFieldToObject(
        newSchema,
        newObjectTypeName,
        fieldName,
        valueType,
        fieldSchema.description,
        "last"
      );
    },
    addEmptyObject(schema, newObjectTypeName, newObjectRole, null)
  );
}

export function setObjectDescription(
  schema: Schema,
  objectTypeName: string,
  description: string | null
): Schema {
  return {
    ...schema,
    objects: Object.fromEntries(
      Object.entries(schema.objects).map(([objectName, objectSchema]) => [
        objectName,
        objectName === objectTypeName
          ? { ...objectSchema, description }
          : objectSchema,
      ])
    ),
  };
}

export function setObjectFieldDescription(
  schema: Schema,
  objectTypeName: string,
  fieldName: string,
  description: string | null
): Schema {
  return setObjectFieldSchema(
    schema,
    objectTypeName,
    fieldName,
    (fieldSchema) => {
      return {
        ...fieldSchema,
        description,
      };
    }
  );
}

export function setObjectFieldDeprecationReason(
  schema: Schema,
  objectTypeName: string,
  fieldName: string,
  deprecationReason: string | undefined
): Schema {
  return setObjectFieldSchema(
    schema,
    objectTypeName,
    fieldName,
    (fieldSchema) => {
      const { deprecationReason: _, ...baseFieldSchema } = fieldSchema;
      return {
        ...baseFieldSchema,
        ...(deprecationReason === undefined ? {} : { deprecationReason }),
      };
    }
  );
}

// eslint-disable-next-line max-params
export function addFieldToObject(
  schema: Schema,
  objectTypeName: string,
  rawNewFieldName: string,
  valueType: ValueType,
  description: string | null,
  position: FieldPosition = "first"
): Schema {
  const newFieldName = formatFieldSchemaName(rawNewFieldName);

  if (!isSchemaNameValid(newFieldName)) {
    throw new Error(schemaNameErrorMessage("Field", newFieldName));
  }
  if (!schema.objects[objectTypeName]) {
    throw new Error(`objectTypeName doesn't exist: ${objectTypeName}`);
  }
  if (schema.objects[objectTypeName].fields[newFieldName]) {
    throw new Error(alreadyExistsMessage("Field", newFieldName));
  }

  if (schema.objects[objectTypeName].role === "output") {
    return addFieldToOutputObject(
      schema,
      objectTypeName,
      newFieldName,
      valueType,
      description,
      position
    );
  }
  return addFieldToBasicObject(
    schema,
    objectTypeName,
    newFieldName,
    valueType,
    description,
    position
  );
}

// eslint-disable-next-line max-params
function addFieldToOutputObject(
  schema: Schema,
  parentObjectTypeName: string,
  fieldName: string,
  valueType: ValueType,
  description: string | null,
  position: FieldPosition
): Schema {
  const argsObjectName = getFieldArgumentsObjectTypeName({
    parentObjectTypeName,
    fieldName,
  });

  return addFieldToBasicObject(
    // Add args object to schema before adding the field in.
    {
      ...schema,
      objects: {
        ...schema.objects,
        [argsObjectName]: {
          description: null,
          role: "args",
          fields: {},
        },
      },
    },
    parentObjectTypeName,
    fieldName,
    {
      type: "FunctionValueType",
      parameterValueTypes: [
        {
          type: "ObjectValueType",
          objectTypeName: argsObjectName,
        },
      ],
      returnValueType: valueType,
    },
    description,
    position
  );
}
// eslint-disable-next-line max-params
function addFieldToBasicObject(
  schema: Schema,
  parentObjectTypeName: string,
  fieldName: string,
  valueType: ValueType,
  description: string | null,
  position: FieldPosition
): Schema {
  const newField = { [fieldName]: { description, valueType } };

  return {
    ...schema,
    objects: Object.fromEntries(
      Object.entries(schema.objects).map(([objectTypeName, objectType]) => [
        objectTypeName,
        objectTypeName === parentObjectTypeName
          ? {
              ...objectType,
              fields: {
                ...(position === "first" ? newField : {}),
                ...objectType.fields,
                ...(position === "last" ? newField : {}),
              },
            }
          : objectType,
      ])
    ),
  };
}

export function renameObjectField(
  schema: Schema,
  objectTypeName: string,
  oldFieldName: string,
  rawNewFieldName: string
): Schema {
  const newFieldName = formatFieldSchemaName(rawNewFieldName);
  if (oldFieldName === newFieldName) {
    return schema;
  }

  if (!isSchemaNameValid(newFieldName)) {
    throw new Error(schemaNameErrorMessage("Field", newFieldName));
  }
  if (!schema.objects[objectTypeName]) {
    throw new Error(`objectTypeName does not exist: ${objectTypeName}`);
  }
  if (!schema.objects[objectTypeName].fields[oldFieldName]) {
    throw new Error(`fieldName does not exist: ${oldFieldName}`);
  }
  if (schema.objects[objectTypeName].fields[newFieldName]) {
    throw new Error(alreadyExistsMessage("Field", newFieldName));
  }

  const oldArgsObjectName = argsNameFromValueType(
    schema.objects[objectTypeName].fields[oldFieldName].valueType
  );
  const newArgsObjectName = getFieldArgumentsObjectTypeName({
    parentObjectTypeName: objectTypeName,
    fieldName: newFieldName,
  });

  return {
    ...schema,
    objects: Object.fromEntries(
      Object.entries(schema.objects).map(([objectName, objectType]) =>
        objectName === oldArgsObjectName
          ? [newArgsObjectName, objectType]
          : objectName !== objectTypeName
            ? [objectName, objectType]
            : [
                objectName,
                {
                  ...objectType,
                  fields: Object.fromEntries(
                    Object.entries(objectType.fields).map(
                      ([fieldName, fieldSchema]) => [
                        oldFieldName === fieldName ? newFieldName : fieldName,
                        oldFieldName === fieldName && oldArgsObjectName
                          ? {
                              ...fieldSchema,
                              valueType: renameObjectInValueType(
                                fieldSchema.valueType,
                                oldArgsObjectName,
                                newArgsObjectName
                              ),
                            }
                          : fieldSchema,
                      ]
                    )
                  ),
                },
              ]
      )
    ),
  };
}

export function changeObjectFieldType(
  schema: Schema,
  objectTypeName: string,
  fieldName: string,
  newValueType: ValueType
): Schema {
  return setObjectFieldSchema(
    schema,
    objectTypeName,
    fieldName,
    (fieldSchema) => {
      return {
        ...fieldSchema,
        valueType:
          fieldSchema.valueType.type === "FunctionValueType"
            ? {
                ...fieldSchema.valueType,
                returnValueType: newValueType,
              }
            : newValueType,
      };
    }
  );
}

function setObjectFieldSchema(
  schema: Schema,
  objectTypeName: string,
  fieldName: string,
  newFieldSchema: (currentFieldSchema: ObjectFieldSchema) => ObjectFieldSchema
): Schema {
  if (!schema.objects[objectTypeName]) {
    throw new Error(`objectTypeName does not exist: ${objectTypeName}`);
  }
  if (!schema.objects[objectTypeName].fields[fieldName]) {
    throw new Error(`fieldName does not exist: ${fieldName}`);
  }

  return {
    ...schema,
    objects: Object.fromEntries(
      Object.entries(schema.objects).map(([objectName, objectType]) =>
        objectName !== objectTypeName
          ? [objectName, objectType]
          : [
              objectName,
              {
                ...objectType,
                fields: Object.fromEntries(
                  Object.entries(objectType.fields).map(
                    ([objectFieldName, fieldSchema]) => [
                      objectFieldName,
                      objectFieldName === fieldName
                        ? newFieldSchema(fieldSchema)
                        : fieldSchema,
                    ]
                  )
                ),
              },
            ]
      )
    ),
  };
}

export function removeFieldFromObject(
  schema: Schema,
  objectTypeName: string,
  fieldName: string
): Schema {
  if (!schema.objects[objectTypeName]) {
    throw new Error(`objectTypeName does not exist: ${objectTypeName}`);
  }
  if (!schema.objects[objectTypeName].fields[fieldName]) {
    throw new Error(`fieldName does not exist: ${fieldName}`);
  }

  return removeFieldsFromObjects(schema, { [objectTypeName]: [fieldName] });
}

export function removeFieldsFromObjects(
  schema: Schema,
  fieldsToRemove: RemovedFields
): Schema {
  return {
    ...schema,
    objects: Object.fromEntries(
      Object.entries(schema.objects)
        .filter(([objectName]) => {
          // Remove args objects from schema if they exist.

          const argsObjectParts =
            getFieldArgumentsObjectTypeNameParts(objectName);

          if (!argsObjectParts) {
            return true; // Not an args object.
          }
          if (!fieldsToRemove[argsObjectParts.parentObjectTypeName]) {
            return true; // No fields removed from parent object.
          }

          return (
            // Check if this field is being removed. If it its index in the array won't be -1.
            fieldsToRemove[argsObjectParts.parentObjectTypeName].indexOf(
              argsObjectParts.fieldName
            ) === -1
          );
        })
        .map(([objectName, objectSchema]) => [
          objectName,
          !fieldsToRemove[objectName]
            ? objectSchema
            : {
                ...objectSchema,
                fields: Object.fromEntries(
                  Object.entries(objectSchema.fields).filter(
                    ([fieldName]) =>
                      fieldsToRemove[objectName].indexOf(fieldName) === -1
                  )
                ),
              },
        ])
    ),
  };
}

export function rootObjectTypeNameFromSchema(schema: Schema): string {
  return (
    (
      schema.objects[queryObjectTypeName].fields[rootFieldName]
        .valueType as FunctionValueType
    ).returnValueType as ObjectValueType
  ).objectTypeName;
}

export function rootContextTypeNameFromSchema(schema: Schema): string | null {
  const argsObjectFields = Object.entries(
    schema.objects[
      (
        (
          schema.objects[queryObjectTypeName].fields[rootFieldName]
            .valueType as FunctionValueType
        ).parameterValueTypes[0] as ObjectValueType
      ).objectTypeName
    ].fields
  );
  if (argsObjectFields.length === 0) {
    return null;
  }
  const [, fieldSchema] = argsObjectFields[0];

  if (fieldSchema.valueType.type !== "ObjectValueType") {
    return null;
  }
  return fieldSchema.valueType.objectTypeName;
}

export function formatTypeSchemaName(name: string): string {
  return toStartCaseWords(name).join("");
}

export function formatFieldSchemaName(name: string): string {
  return toStartCaseWords(name)
    .map((word, index) => (index === 0 ? word.toLowerCase() : word))
    .join("");
}

function argsNameFromValueType(valueType: ValueType): string | null {
  return valueType.type === "FunctionValueType" &&
    valueType.parameterValueTypes.length === 1 &&
    valueType.parameterValueTypes[0].type === "ObjectValueType"
    ? valueType.parameterValueTypes[0].objectTypeName
    : null;
}

function valueTypeReturnsObject(
  valueType: ValueType,
  objectTypeName: string
): boolean {
  switch (valueType.type) {
    case "ObjectValueType":
      return valueType.objectTypeName === objectTypeName;

    case "FunctionValueType":
      return valueTypeReturnsObject(valueType.returnValueType, objectTypeName);

    case "ListValueType":
      return valueTypeReturnsObject(valueType.itemValueType, objectTypeName);

    default:
      return false;
  }
}
