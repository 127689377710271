import {
  fieldPathSeparator,
  Expression,
  Schema,
} from "@hypertune/sdk/src/shared";
import {
  isFieldArgumentsObjectVariableExpression,
  toStartCase,
} from "@hypertune/shared-internal";
import getNewVariables from "@hypertune/shared-internal/src/expression/getNewVariables";

import { VariableMap } from "@hypertune/shared-internal/src/expression/types";
import getExpressionLabel from "./getExpressionLabel";

export default function getExpressionOptionLabel(
  schema: Schema,
  variables: VariableMap,
  expression: Expression | null
): string {
  if (!expression) {
    return "null";
  }

  if (expression.type === "GetFieldExpression") {
    const fieldPathLabel = toStartCase(expression.fieldPath || "");
    if (isFieldArgumentsObjectVariableExpression(expression.object)) {
      return fieldPathLabel;
    }
    return `${getExpressionOptionLabel(
      schema,
      variables,
      expression.object
    )}${fieldPathSeparator}${fieldPathLabel}`;
  }

  if (expression.type === "FunctionExpression" && expression.body) {
    return getExpressionOptionLabel(
      schema,
      {
        ...variables,
        ...getNewVariables(
          expression.parameters,
          expression.valueType.parameterValueTypes
        ),
      },
      expression.body
    );
  }

  if (expression.type === "VariableExpression") {
    const variable = variables[expression.variableId];
    if (!variable) {
      throw new Error(`invalid variable ID: ${expression.variableId}`);
    }
    return toStartCase(variable.name);
  }

  if (expression.type === "ApplicationExpression") {
    return `${getExpressionOptionLabel(
      schema,
      variables,
      expression.function
    )}(${expression.arguments
      .map((argument) => getExpressionOptionLabel(schema, variables, argument))
      .join(", ")})`;
  }

  return getExpressionLabel(expression);
}
