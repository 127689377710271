import {
  ApplicationExpression,
  Expression,
  ObjectExpression,
  Schema,
  VariableExpression,
} from "@hypertune/sdk/src/shared";
import toStartCase from "@hypertune/shared-internal/src/toStartCase";
import getConstraintFromValueType from "@hypertune/shared-internal/src/expression/constraint/getConstraintFromValueType";
import { VariableMap } from "@hypertune/shared-internal/src/expression/types";
import { getObjectFieldPath } from "@hypertune/shared-internal/src/expression/fieldPath";
import createApplication from "../../../lib/expression/createApplication";
import getTextLabel from "../../../lib/generic/getTextLabel";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
  LiftFunction,
  SelectedItem,
} from "../../../lib/types";
import ExpressionControl from "./ExpressionControl";
import LabeledExpressionControlList, {
  LabeledExpressionControlListItemControlType,
} from "./LabeledExpressionControlList";
import isReadOnly from "../../../lib/expression/isReadOnly";
import DeleteButton from "../../../components/buttons/DeleteButton";

export default function ObjectExpressionControl({
  context,
  variables,
  setVariableName,
  expression,
  setExpression,
  includeExpressionOption,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  expression: ObjectExpression;
  setExpression: (newExpression: Expression | null) => void;
  includeExpressionOption: IncludeExpressionOptionFunction;
}): React.ReactElement {
  const schemaObjectFields =
    context.commitContext.schema.objects[expression.objectTypeName]?.fields ||
    {};

  const fieldPosition = Object.fromEntries(
    [
      ...Object.keys(expression.fields).filter(
        (fieldName) => !schemaObjectFields[fieldName]
      ),
      ...Object.keys(schemaObjectFields),
    ].map((fieldName, index) => [fieldName, index])
  );

  const readOnly = isReadOnly(context);

  return (
    <div style={{ paddingTop: 2 }}>
      <LabeledExpressionControlList
        useArrow={false}
        items={Object.entries(expression.fields)
          .sort(([a], [b]) => fieldPosition[a] - fieldPosition[b])
          .map(([fieldName, fieldExpression]) => {
            const schemaFieldValueType =
              schemaObjectFields[fieldName]?.valueType;

            return {
              type: LabeledExpressionControlListItemControlType,
              label: getTextLabel(toStartCase(fieldName)),
              count: fieldExpression
                ? context.evaluations[fieldExpression.id]
                : undefined,
              intent: !schemaFieldValueType
                ? "danger"
                : context.expressionIdToIntent?.[expression.id] ?? "neutral",
              isRed: !schemaFieldValueType,
              options:
                !readOnly && !schemaFieldValueType
                  ? [
                      <DeleteButton
                        size="x-small"
                        key={`delete${fieldName}`}
                        onClick={(): void => {
                          const { [fieldName]: _, ...fields } =
                            expression.fields;
                          setExpression({ ...expression, fields });
                        }}
                      />,
                    ]
                  : [],
              expressionControl: (
                <ExpressionControl
                  context={{
                    ...context,
                    fullFieldPath: getObjectFieldPath(
                      context.fullFieldPath,
                      fieldName
                    ),
                  }}
                  variables={variables}
                  setVariableName={setVariableName}
                  valueTypeConstraint={
                    schemaFieldValueType
                      ? getConstraintFromValueType(schemaFieldValueType)
                      : { type: "ErrorValueTypeConstraint" }
                  }
                  expression={fieldExpression}
                  setExpression={(newExpression: Expression | null): void =>
                    setExpression({
                      ...expression,
                      fields: {
                        ...expression.fields,
                        [fieldName]: newExpression,
                      },
                    })
                  }
                  lift={getObjectExpressionFieldLiftFunction({
                    schema: context.commitContext.schema,
                    variables,
                    fieldName,
                    expression,
                    setExpression,
                    setExpressionEditorSelectedItem: (newSelectedItem) =>
                      context.setExpressionEditorState({
                        ...context.expressionEditorState,
                        selectedItem: newSelectedItem,
                      }),
                  })}
                  parentExpression={expression}
                  setParentExpression={setExpression}
                  includeExpressionOption={includeExpressionOption}
                />
              ),
            };
          })}
      />
    </div>
  );
}

export function getObjectExpressionFieldLiftFunction({
  schema,
  variables,
  fieldName,
  expression,
  setExpression,
  setExpressionEditorSelectedItem,
}: {
  schema: Schema;
  variables: VariableMap;
  fieldName: string;
  expression: ObjectExpression;
  setExpression: (newExpression: Expression | null) => void;
  setExpressionEditorSelectedItem: (
    newSelectedItem: SelectedItem | null
  ) => void;
}): LiftFunction {
  return (child): void => {
    if (child.keepInObjectField) {
      setExpression({
        ...expression,
        fields: {
          ...expression.fields,
          [fieldName]: createApplication({
            variables,
            rawArgument: child.argument,
            replacedVariableIdToNewVariable:
              child.replacedVariableIdToNewVariable,
            valueType:
              expression.fields[fieldName]?.valueType ??
              schema.objects[expression.objectTypeName].fields[fieldName]
                .valueType,
            replaceArgument: child.replaceArgument,
            newVariableName: child.newVariableName, // ?? `${toCamelCase(fieldName)}Var`
            setExpressionEditorSelectedItem,
          }),
        },
      });
      return;
    }
    function replaceArgument(
      variable: VariableExpression | ApplicationExpression
    ): ObjectExpression {
      const newExpression: ObjectExpression = {
        ...expression,
        fields: {
          ...expression.fields,
          [fieldName]: child.replaceArgument(variable),
        },
      };
      return newExpression;
    }
    const applicationExpression = createApplication({
      variables,
      rawArgument: child.argument,
      replacedVariableIdToNewVariable: child.replacedVariableIdToNewVariable,
      valueType: expression.valueType,
      replaceArgument,
      newVariableName: child.newVariableName, // ?? `${toCamelCase(fieldName)}Var`
      setExpressionEditorSelectedItem,
    });
    setExpression(applicationExpression);
  };
}
