import { Expression } from "@hypertune/sdk/src/shared";
import {
  queryObjectTypeName,
  sourceObjectTypeName,
  toStartCase,
} from "@hypertune/shared-internal";
import getApplicationFunctionExpression from "@hypertune/shared-internal/src/expression/getApplicationFunctionExpression";
import isIfElseExpression from "@hypertune/shared-internal/src/expression/isIfElseExpression";

export default function getExpressionLabel(expression: Expression): string {
  switch (expression.type) {
    case "NoOpExpression":
      return "No Op";
    case "BooleanExpression":
      return "Toggle";
    case "IntExpression":
      return "Number";
    case "FloatExpression":
      return "Number";
    case "StringExpression":
      return "Text";
    case "RegexExpression":
      return "Regex";
    case "EnumExpression":
      return toStartCase(expression.valueType.enumTypeName);
    case "ObjectExpression":
      return toStartCase(
        expression.objectTypeName === queryObjectTypeName
          ? sourceObjectTypeName
          : expression.objectTypeName
      );
    case "GetFieldExpression":
      return "Get Field";
    case "UpdateObjectExpression":
      return `Update ${toStartCase(expression.valueType.objectTypeName)}`;
    case "ListExpression":
      return "List";
    case "SwitchExpression":
      return isIfElseExpression(expression) ? "If / Else" : "Match Case";
    case "ComparisonExpression":
      return "Comparison";
    case "ArithmeticExpression":
      return "Arithmetic";
    case "RoundNumberExpression":
      return "Round";
    case "StringifyNumberExpression":
      return "Text From Number";
    case "StringConcatExpression":
      return "Text Chain";
    case "GetUrlQueryParameterExpression":
      return "Get URL Query Param";
    case "EnumSwitchExpression":
      return "Exhaustive Enum Switch";
    case "SplitExpression":
      return "Split Control";
    case "LogEventExpression":
      return "Log Event";
    case "VariableExpression":
      return "Variable";
    case "FunctionExpression":
      return "Function";
    case "ApplicationExpression":
      return getApplicationFunctionExpression(expression)
        ? "Block with Variables"
        : "Function Call";
    default: {
      const neverExpression: never = expression;
      throw new Error(
        `Expression with unexpected type: ${JSON.stringify(neverExpression)}`
      );
    }
  }
}
