import {
  ApplicationExpression,
  ComparisonExpression,
  ComparisonOperator,
  Expression,
  ValueType,
  VariableExpression,
  getBooleanExpression,
} from "@hypertune/sdk/src/shared";
import getComparisonBValueTypeConstraint from "@hypertune/shared-internal/src/expression/constraint/getComparisonBValueTypeConstraint";
import getComparisonOperators from "@hypertune/shared-internal/src/expression/getComparisonOperators";
import getDefaultExpression from "@hypertune/shared-internal/src/expression/getDefaultExpression";
import isValueTypeValid from "@hypertune/shared-internal/src/expression/isValueTypeValid";
import isEmptyExpression from "@hypertune/shared-internal/src/expression/isEmptyExpression";
import isValueTypeCompatible from "@hypertune/shared-internal/src/expression/constraint/isValueTypeCompatible";
import { VariableMap } from "@hypertune/shared-internal/src/expression/types";
import getOperatorLabel from "../../../lib/expression/getOperatorLabel";
import {
  small,
  liftPermissionsDeniedErrorMessage,
  singlePanelInnerHeight,
  singlePanelHeight,
  greyHex,
  borderRadiusPx,
} from "../../../lib/constants";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
  LiftFunction,
} from "../../../lib/types";
import Dropdown, { LabeledOption } from "../../../components/Dropdown";
import ExpressionControl from "./ExpressionControl";
import Panel from "./Panel";
import isReadOnly from "../../../lib/expression/isReadOnly";
import setSelectedExpressionId from "../../../lib/expression/setSelectedExpressionId";

const restrictedBooleanOperators: ComparisonOperator[] = ["==", "!="];

export default function ComparisonExpressionControl({
  context,
  variables,
  setVariableName,
  expression,
  setExpression,
  lift,
  optionsButton,
  includeExpressionOption,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  expression: ComparisonExpression;
  setExpression: (newExpression: Expression | null) => void;
  /**
   * Parent lift function. Comparisons should lift subexpressions to their
   * parents as variable blocks inside comparisons/if-elses is confusing and
   * usually not what the user intends. */
  lift: LiftFunction;
  optionsButton: React.ReactNode;
  includeExpressionOption: IncludeExpressionOptionFunction;
}): React.ReactElement {
  function getValidValueType(expr: Expression | null): ValueType | null {
    return expr &&
      isValueTypeValid(context.commitContext.schema, expr.valueType)
      ? expr.valueType
      : null;
  }

  // These functions determine what value should be set for the operator and B
  // expression when other parts of the comparison expression change.
  function getNewOperator(
    a: Expression | null,
    operator: ComparisonOperator | null,
    b: Expression | null
  ): ComparisonOperator | null {
    if (isEmptyExpression(a) && isEmptyExpression(b)) {
      return null;
    }

    if (operator) {
      return operator;
    }

    // If a is set, choose a default operator based on a. This helps users enter
    // comparisons quicker.
    if (a && a.valueType) {
      if (a.valueType.type === "BooleanValueType") {
        return "==";
      }
      const operators = getComparisonOperators(a.valueType);
      if (operators.length > 0) {
        return operators[0];
      }
    }

    return null;
  }
  function getNewB(
    a: Expression | null,
    operator: ComparisonOperator | null,
    b: Expression | null
  ): Expression | null {
    // If the user has spent time inputting something, e.g. a list of user ids,
    // use that input. This enables them to swap the A expression without losing
    // their progress (e.g. after a schema rename).
    if (!isEmptyExpression(b)) {
      return b;
    }

    const { schema } = context.commitContext;
    const bValueTypeConstraint = getComparisonBValueTypeConstraint(
      getValidValueType(a),
      operator
    );

    // If we already have a compatible expression, no need to change it.
    if (
      b &&
      bValueTypeConstraint.type !== "ErrorValueTypeConstraint" &&
      isValueTypeCompatible(schema, bValueTypeConstraint, b.valueType)
    ) {
      return b;
    }

    // If there's nothing important to keep, replace b with a default.
    // This makes entering expressions quicker and avoids unnecessary type errors.
    if (bValueTypeConstraint.type === "BooleanValueTypeConstraint") {
      // Default to 'true' (rather than getDefaultExpression) for booleans, as
      // this is the most common use case when comparing boolean variable.
      return getBooleanExpression(true);
    }
    return getDefaultExpression(
      schema,
      variables,
      bValueTypeConstraint,
      new Set()
    );
  }

  const aValueType = getValidValueType(expression.a);
  // Restrict options for boolean comparisons, which don't
  // contain nested comparisons themselves.
  const operatorOptions = (
    aValueType?.type === "BooleanValueType" &&
    expression.a?.type !== "ComparisonExpression"
      ? restrictedBooleanOperators
      : getComparisonOperators(aValueType)
  )
    .filter(
      (operator) =>
        !context.includeComparisonOperator ||
        context.includeComparisonOperator(operator)
    )
    .map(toLabeledOption);

  const readOnly = isReadOnly(context);
  const operatorIntent =
    context.expressionIdToIntent?.[`${expression.id}operator`] ?? "neutral";

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: small }}>
      <ExpressionControl
        context={context}
        variables={variables}
        setVariableName={setVariableName}
        valueTypeConstraint={{ type: "AnyValueTypeConstraint" }}
        expression={expression.a}
        setExpression={(newExpression: Expression | null): void => {
          if (!newExpression && expression.b?.type === "ComparisonExpression") {
            setExpression(expression.b);
            return;
          }

          const newOperator = getNewOperator(
            newExpression,
            expression.operator,
            expression.b
          );
          const newB = getNewB(newExpression, newOperator, expression.b);
          if (expression.b?.id !== newB?.id) {
            setSelectedExpressionId(context, newB?.id ?? null, newB);
          }
          setExpression({
            ...expression,
            a: newExpression,
            operator: newOperator,
            b: newB,
          });
        }}
        lift={(child): void => {
          if (readOnly) {
            // eslint-disable-next-line no-alert
            alert(liftPermissionsDeniedErrorMessage);
            return;
          }
          function replaceArgument(
            variable: VariableExpression | ApplicationExpression
          ): ComparisonExpression {
            const newExpression: ComparisonExpression = {
              ...expression,
              a: child.replaceArgument(variable),
            };
            return newExpression;
          }
          lift({
            argument: child.argument,
            replacedVariableIdToNewVariable:
              child.replacedVariableIdToNewVariable,
            replaceArgument,
            newVariableName: child.newVariableName,
            isNew: child.isNew,
            keepInObjectField: child.keepInObjectField,
          });
        }}
        parentExpression={expression}
        setParentExpression={setExpression}
        includeExpressionOption={includeExpressionOption}
        // Don't use insert for nested comparisons and in in read only mode.
        useInsert={expression.a?.type !== "ComparisonExpression" && !readOnly}
      />
      {readOnly ? (
        <Panel
          header={null}
          message={null}
          shouldStack={false}
          intent={operatorIntent}
        >
          <div
            style={{
              height: singlePanelInnerHeight,
              display: "flex",
              alignItems: "center",
            }}
          >
            {expression.operator
              ? getOperatorLabel(expression.operator)
              : "(not set)"}
          </div>
        </Panel>
      ) : (
        <Dropdown<ComparisonOperator>
          intent={operatorIntent}
          height={singlePanelHeight}
          options={{
            type: "options",
            options: operatorOptions,
          }}
          value={
            expression.operator ? toLabeledOption(expression.operator) : null
          }
          placeholder="Select operator..."
          noOptionsMessage="No operators"
          onChange={(option) => {
            if (!option) {
              return;
            }
            const newB = getNewB(expression.a, option.value, expression.b);
            if (expression.b?.id !== newB?.id) {
              setSelectedExpressionId(context, newB?.id ?? null, newB);
            }

            setExpression({
              ...expression,
              operator: option.value,
              b: newB,
            });
          }}
        />
      )}
      <ExpressionControl
        context={context}
        variables={variables}
        setVariableName={setVariableName}
        valueTypeConstraint={getComparisonBValueTypeConstraint(
          aValueType,
          expression.operator
        )}
        expression={expression.b}
        setExpression={(newExpression: Expression | null): void => {
          if (!newExpression && expression.a?.type === "ComparisonExpression") {
            setExpression(expression.a);
            return;
          }

          setExpression({
            ...expression,
            operator:
              !expression.a && !newExpression ? null : expression.operator,
            b: newExpression,
          });
        }}
        lift={(child): void => {
          if (readOnly) {
            // eslint-disable-next-line no-alert
            alert(liftPermissionsDeniedErrorMessage);
            return;
          }
          function replaceArgument(
            variable: VariableExpression | ApplicationExpression
          ): ComparisonExpression {
            const newExpression: ComparisonExpression = {
              ...expression,
              b: child.replaceArgument(variable),
            };
            return newExpression;
          }
          lift({
            argument: child.argument,
            replacedVariableIdToNewVariable:
              child.replacedVariableIdToNewVariable,
            replaceArgument,
            newVariableName: child.newVariableName,
            isNew: child.isNew,
            keepInObjectField: child.keepInObjectField,
          });
        }}
        parentExpression={expression}
        setParentExpression={setExpression}
        includeExpressionOption={includeExpressionOption}
      />
      {optionsButton && !context.ignoreErrors && (
        <div
          style={{
            border: `1px solid ${greyHex}`,
            borderRadius: borderRadiusPx,
            alignSelf: "flex-start",
            padding: "4px 8px",
          }}
        >
          {optionsButton}
        </div>
      )}
    </div>
  );
}

function toLabeledOption(
  operator: ComparisonOperator
): LabeledOption<ComparisonOperator> {
  return { value: operator, label: getOperatorLabel(operator) };
}
