import { useCallback, useMemo } from "react";
import { Expression, Split } from "@hypertune/sdk/src/shared";

import getSplitErrorMessage from "@hypertune/shared-internal/src/expression/getSplitErrorMessage";
import { useAppDispatch } from "../../../../app/hooks";
import RenameTopBar from "../../../../components/RenameTopBar";
import TypeIcon from "../../../../components/icons/TypeIcon";
import { setDraftCommitSplits, toggleShowDetails } from "../../projectSlice";
import SelectedSplitEditor from "./SelectedSplitEditor";
import SelectedSplitDetails from "./SelectedSplitDetails";
import { CommitContext } from "../../../../lib/types";
import { showSchemaNameError } from "../../schema/typeEditor/SchemaNameError";
import NameError from "./NameError";
import ErrorMessage from "../../../../components/ErrorMessage";

export default function SplitEditorMain({
  readOnly,
  commitContext,
  expression,
  selectedSplit,
  setSelectedSplitId,
}: {
  readOnly: boolean;
  commitContext: CommitContext;
  expression: Expression;
  selectedSplit: Split | null;
  setSelectedSplitId: (newSelectedSplitId: string) => void;
}): React.ReactElement | null {
  const { splits } = commitContext;

  const dispatch = useAppDispatch();

  const errorMessage = useMemo(
    () =>
      selectedSplit
        ? getSplitErrorMessage(commitContext.schema, selectedSplit)
        : null,
    [commitContext.schema, selectedSplit]
  );

  const renameSplit = useCallback(
    (newSplitName: string): Promise<void> => {
      if (selectedSplit !== null) {
        dispatch(
          setDraftCommitSplits({
            ...splits,
            [selectedSplit.id]: {
              ...splits[selectedSplit.id],
              name: newSplitName,
            },
          })
        );
      }

      return Promise.resolve();
    },
    [dispatch, splits, selectedSplit]
  );

  const setSplit = useCallback(
    (newSplit: Split) =>
      dispatch(
        setDraftCommitSplits({
          ...splits,
          [newSplit.id]: {
            ...newSplit,
          },
        })
      ),
    [dispatch, splits]
  );

  return (
    <div className="flex h-full w-full flex-col overflow-hidden bg-bg-light bg-dotted">
      {!!selectedSplit && (
        <RenameTopBar
          icon={<TypeIcon type={selectedSplit.type} size="large" />}
          label={selectedSplit.name}
          entityName="split"
          rename={!readOnly ? renameSplit : undefined}
          hasError={(newName) => {
            if (newName === selectedSplit.name) {
              return null;
            }
            return NameError({
              idMap: splits,
              newName,
              noun: "Split",
            });
          }}
          showError={showSchemaNameError}
          toggleSidebar={() => dispatch(toggleShowDetails())}
          disableConfirmDialog
          errorMessage={<ErrorMessage errorMessage={errorMessage} />}
        />
      )}
      <div className="flex h-full w-full flex-row overflow-hidden">
        <div className="grid w-full items-start overflow-y-auto px-[48px] py-[37px]">
          {selectedSplit ? (
            <SelectedSplitEditor
              splits={splits}
              split={selectedSplit}
              expression={expression}
              setSplit={setSplit}
              readOnly={readOnly}
            />
          ) : null}
        </div>
        {selectedSplit ? (
          <SelectedSplitDetails
            readOnly={readOnly}
            hasError={!!errorMessage}
            commitContext={commitContext}
            split={selectedSplit}
            setSplit={setSplit}
            setSelectedSplitId={setSelectedSplitId}
          />
        ) : null}
      </div>
    </div>
  );
}
