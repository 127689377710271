import { Key, ReactElement } from "react";

import Card, { BaseCardProps, CardLayout } from "./Card";
import twMerge from "../lib/twMerge";

export type CardGroupItem = BaseCardProps & { key: Key };

const layoutClasses = {
  list: "divide-y divide-bd-default rounded-lg border border-bd-default shadow-sm",
  grid: "grid grid-cols-2 gap-2 pb-2",
  leftAlignedGrid: "flex flex-row flex-wrap gap-2 justify-start",
} as const;
export type CardGroupLayout = keyof typeof layoutClasses;

type CardGroupProps = {
  className?: string;
  layout: CardGroupLayout;
  cardLayout: CardLayout;
  loadingCount?: number;
  cards: CardGroupItem[];
};

export default function CardGroup({
  className,
  layout,
  cardLayout,
  loadingCount,
  cards,
}: CardGroupProps): ReactElement | null {
  const items: CardGroupItem[] = loadingCount
    ? Array.from({ length: loadingCount }).map((_, index) => ({
        key: index as Key,
        isLoading: true,
        children: null,
      }))
    : cards;

  if (items.length === 0) {
    return null;
  }
  return (
    <div className={twMerge(layoutClasses[layout], className)}>
      {items.map((card) => (
        <Card
          {...card}
          layout={cardLayout}
          isListItem={layout === "list"}
          className={card.className}
        />
      ))}
    </div>
  );
}
