import {
  getBooleanExpression,
  ApplicationExpression,
  Expression,
  SwitchExpression,
  VariableExpression,
  uniqueId,
} from "@hypertune/sdk/src/shared";
import getComparisonExpression from "@hypertune/shared-internal/src/expression/getComparisonExpression";
import getConstraintFromValueType from "@hypertune/shared-internal/src/expression/constraint/getConstraintFromValueType";
import isIfElseExpression from "@hypertune/shared-internal/src/expression/isIfElseExpression";
import isValueTypeValid from "@hypertune/shared-internal/src/expression/isValueTypeValid";
import {
  ValueTypeConstraint,
  VariableMap,
} from "@hypertune/shared-internal/src/expression/types";
import { kilSwitchExpressionNote } from "@hypertune/shared-internal";
import createApplication from "../../../lib/expression/createApplication";
import getTextLabel from "../../../lib/generic/getTextLabel";
import {
  normal,
  greyHex,
  liftPermissionsDeniedErrorMessage,
  plusSymbol,
  upArrowSymbol,
  downArrowSymbol,
} from "../../../lib/constants";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
  LiftFunction,
} from "../../../lib/types";
import ExpressionControl from "./ExpressionControl";
import LabeledExpressionControl from "./LabeledExpressionControl";
import isReadOnly from "../../../lib/expression/isReadOnly";
import DeleteButton from "../../../components/buttons/DeleteButton";
import {
  Intent,
  intentToBorderClassName,
  intentToHexColor,
  intentToLightBgClassName,
  intentToTextColorName,
} from "../../../components/intent";
import Button from "../../../components/buttons/Button";
import twMerge from "../../../lib/twMerge";

export default function SwitchExpressionControl({
  context,
  variables,
  setVariableName,
  expression,
  setExpression,
  lift,
  includeExpressionOption,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  expression: SwitchExpression;
  setExpression: (newExpression: Expression | null) => void;
  lift: LiftFunction;
  includeExpressionOption: IncludeExpressionOptionFunction;
}): React.ReactElement {
  const readOnly = isReadOnly(context);
  const intent = context.expressionIdToIntent?.[expression.id] ?? "neutral";

  const thenValueType = isValueTypeValid(
    context.commitContext.schema,
    expression.valueType
  )
    ? expression.valueType
    : null;

  const thenValueTypeConstraint: ValueTypeConstraint = thenValueType
    ? getConstraintFromValueType(thenValueType)
    : { type: "ErrorValueTypeConstraint" };

  const whenValueTypeConstraint: ValueTypeConstraint =
    expression.control &&
    isValueTypeValid(context.commitContext.schema, expression.control.valueType)
      ? getConstraintFromValueType(expression.control.valueType)
      : { type: "ErrorValueTypeConstraint" };

  const isIfElse = isIfElseExpression(expression);
  const isBooleanExpression = expression.valueType.type === "BooleanValueType";

  const controlLabel = getTextLabel("Control");
  const firstWhenLabel = getTextLabel(isIfElse ? "If" : "When");
  const whenLabel = getTextLabel(isIfElse ? "Else If" : "When");
  const thenLabel = getTextLabel("Then");
  const defaultLabel = getTextLabel("Default");

  const newCaseText = isIfElse ? "Rule" : "Case";

  const labelArrowMinWidth =
    52 +
    Math.max(
      ...[
        controlLabel,
        thenLabel,
        expression.cases.length <= 1 ? firstWhenLabel : whenLabel,
        defaultLabel,
      ].map((label) => label.width)
    );

  const divider = (
    <div
      style={{
        backgroundColor:
          intent !== "neutral" ? intentToHexColor(intent) : greyHex,
        height: 1,
        marginLeft: normal * -1,
        marginRight: normal * -1,
      }}
    />
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: normal }}>
      {isIfElse ? null : (
        <Wrapper intent={intent} show={context.showSwitchExpressionPanels}>
          <LabeledExpressionControl
            label={controlLabel.component}
            useArrow
            arrowMinWidth={labelArrowMinWidth}
            intent={intent}
            options={[]}
            expressionControl={
              <ExpressionControl
                context={context}
                variables={variables}
                setVariableName={setVariableName}
                valueTypeConstraint={{ type: "AnyValueTypeConstraint" }}
                expression={expression.control}
                setExpression={(newExpression: Expression | null): void =>
                  setExpression({ ...expression, control: newExpression })
                }
                lift={(child): void => {
                  if (readOnly) {
                    // eslint-disable-next-line no-alert
                    alert(liftPermissionsDeniedErrorMessage);
                    return;
                  }
                  function replaceArgument(
                    variable: VariableExpression | ApplicationExpression
                  ): SwitchExpression {
                    const newExpression: SwitchExpression = {
                      ...expression,
                      control: child.replaceArgument(variable),
                    };
                    return newExpression;
                  }
                  const applicationExpression = createApplication({
                    variables,
                    rawArgument: child.argument,
                    replacedVariableIdToNewVariable:
                      child.replacedVariableIdToNewVariable,
                    valueType: expression.valueType,
                    replaceArgument,
                    newVariableName: child.newVariableName,
                    setExpressionEditorSelectedItem: (newSelectedItem) =>
                      context.setExpressionEditorState({
                        ...context.expressionEditorState,
                        selectedItem: newSelectedItem,
                      }),
                  });
                  setExpression(applicationExpression);
                }}
                parentExpression={expression}
                setParentExpression={setExpression}
                includeExpressionOption={includeExpressionOption}
              />
            }
          />
        </Wrapper>
      )}
      {!isIfElse && !context.showSwitchExpressionPanels ? divider : null}
      {expression.cases.map((item, index) => (
        <>
          <SwitchExpressionCaseControl
            context={context}
            variables={variables}
            setVariableName={setVariableName}
            expression={expression}
            setExpression={setExpression}
            lift={lift}
            index={index}
            labelArrowMinWidth={labelArrowMinWidth}
            whenLabel={
              index === 0 ? firstWhenLabel.component : whenLabel.component
            }
            thenLabel={thenLabel.component}
            whenValueTypeConstraint={whenValueTypeConstraint}
            thenValueTypeConstraint={thenValueTypeConstraint}
            includeExpressionOption={includeExpressionOption}
          />
          {!context.showSwitchExpressionPanels && divider}
        </>
      ))}
      {!readOnly && thenValueType ? (
        <>
          <Button
            intent="primary"
            weight="minimal"
            text={newCaseText}
            icon={plusSymbol}
            onClick={() =>
              setExpression({
                ...expression,
                cases: [
                  ...expression.cases,
                  {
                    id: uniqueId(),
                    when: isIfElse ? getComparisonExpression() : null,
                    then: isBooleanExpression
                      ? getBooleanExpression(true)
                      : null,
                  },
                ],
              })
            }
          />
          {!context.showSwitchExpressionPanels && divider}
        </>
      ) : null}
      <Wrapper intent={intent} show={context.showSwitchExpressionPanels}>
        <LabeledExpressionControl
          label={defaultLabel.component}
          count={
            expression.default
              ? context.evaluations[expression.default.id]
              : undefined
          }
          useArrow
          arrowMinWidth={labelArrowMinWidth}
          intent={intent}
          options={[]}
          expressionControl={
            <ExpressionControl
              context={context}
              variables={variables}
              setVariableName={setVariableName}
              valueTypeConstraint={thenValueTypeConstraint}
              expression={expression.default}
              setExpression={(newExpression: Expression | null): void =>
                setExpression({
                  ...expression,
                  default: newExpression,
                })
              }
              lift={(child): void => {
                if (readOnly) {
                  // eslint-disable-next-line no-alert
                  alert(liftPermissionsDeniedErrorMessage);
                  return;
                }
                function replaceArgument(
                  variable: VariableExpression | ApplicationExpression
                ): SwitchExpression {
                  const newExpression: SwitchExpression = {
                    ...expression,
                    default: child.replaceArgument(variable),
                  };
                  return newExpression;
                }
                // We let the parent lift the default
                lift({
                  argument: child.argument,
                  replacedVariableIdToNewVariable:
                    child.replacedVariableIdToNewVariable,
                  replaceArgument,
                  newVariableName: child.newVariableName,
                  isNew: child.isNew,
                  keepInObjectField: child.keepInObjectField,
                });
              }}
              parentExpression={expression}
              setParentExpression={setExpression}
              includeExpressionOption={includeExpressionOption}
            />
          }
        />
      </Wrapper>
    </div>
  );
}

function SwitchExpressionCaseControl({
  context,
  variables,
  setVariableName,
  expression,
  setExpression,
  lift,
  index,
  labelArrowMinWidth,
  whenLabel,
  thenLabel,
  whenValueTypeConstraint,
  thenValueTypeConstraint,
  includeExpressionOption,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  /* The parent switch expression this case belongs to. */
  expression: SwitchExpression;
  /* Replace the parent switch expression this case belongs to. */
  setExpression: (newExpression: Expression | null) => void;
  lift: LiftFunction;
  index: number;
  labelArrowMinWidth: number;
  whenLabel: React.ReactNode;
  thenLabel: React.ReactNode;
  whenValueTypeConstraint: ValueTypeConstraint;
  thenValueTypeConstraint: ValueTypeConstraint;
  includeExpressionOption: IncludeExpressionOptionFunction;
}): React.ReactElement {
  const item = expression.cases[index];
  const readOnly = isReadOnly(context);
  const intent =
    context.expressionIdToIntent?.[`${expression.id}case${item.id}`] ??
    "neutral";

  return (
    <Wrapper intent={intent} show={context.showSwitchExpressionPanels}>
      <LabeledExpressionControl
        label={whenLabel}
        useArrow
        arrowMinWidth={labelArrowMinWidth}
        intent={intent}
        options={[]}
        expressionControl={
          <ExpressionControl
            context={context}
            variables={variables}
            setVariableName={setVariableName}
            valueTypeConstraint={whenValueTypeConstraint}
            expression={item.when}
            setExpression={(newExpression: Expression | null): void =>
              setExpression({
                ...expression,
                cases: [
                  ...expression.cases.slice(0, index),
                  {
                    ...item,
                    when: newExpression,
                  },
                  ...expression.cases.slice(index + 1),
                ],
              })
            }
            lift={(child) => {
              if (readOnly) {
                // eslint-disable-next-line no-alert
                alert(liftPermissionsDeniedErrorMessage);
                return;
              }
              function replaceArgument(
                variable: VariableExpression | ApplicationExpression
              ): SwitchExpression {
                const newExpression: SwitchExpression = {
                  ...expression,
                  cases: [
                    ...expression.cases.slice(0, index),
                    {
                      ...item,
                      when: child.replaceArgument(variable),
                    },
                    ...expression.cases.slice(index + 1),
                  ],
                };
                return newExpression;
              }
              // We let the parent lift the when
              lift({
                argument: child.argument,
                replacedVariableIdToNewVariable:
                  child.replacedVariableIdToNewVariable,
                replaceArgument,
                newVariableName: child.newVariableName,
                isNew: child.isNew,
                keepInObjectField: child.keepInObjectField,
              });
            }}
            parentExpression={expression}
            setParentExpression={setExpression}
            includeExpressionOption={includeExpressionOption}
          />
        }
      />
      <LabeledExpressionControl
        label={thenLabel}
        count={item.then ? context.evaluations[item.then.id] : undefined}
        useArrow
        arrowMinWidth={labelArrowMinWidth}
        intent={intent}
        options={[]}
        expressionControl={
          <ExpressionControl
            context={context}
            variables={variables}
            setVariableName={setVariableName}
            valueTypeConstraint={thenValueTypeConstraint}
            expression={item.then}
            setExpression={(newExpression: Expression | null): void =>
              setExpression({
                ...expression,
                cases: [
                  ...expression.cases.slice(0, index),
                  {
                    ...item,
                    then: newExpression,
                  },
                  ...expression.cases.slice(index + 1),
                ],
              })
            }
            lift={(child): void => {
              if (readOnly) {
                // eslint-disable-next-line no-alert
                alert(liftPermissionsDeniedErrorMessage);
                return;
              }
              function replaceArgument(
                variable: VariableExpression | ApplicationExpression
              ): SwitchExpression {
                const newExpression: SwitchExpression = {
                  ...expression,
                  cases: [
                    ...expression.cases.slice(0, index),
                    {
                      ...item,
                      then: child.replaceArgument(variable),
                    },
                    ...expression.cases.slice(index + 1),
                  ],
                };
                return newExpression;
              }
              // We let the parent lift the then
              lift({
                argument: child.argument,
                replacedVariableIdToNewVariable:
                  child.replacedVariableIdToNewVariable,
                replaceArgument,
                newVariableName: child.newVariableName,
                isNew: child.isNew,
                keepInObjectField: child.keepInObjectField,
              });
            }}
            parentExpression={expression}
            setParentExpression={setExpression}
            includeExpressionOption={includeExpressionOption}
          />
        }
      />
      {readOnly ? null : (
        <div
          style={{
            marginLeft: labelArrowMinWidth,
          }}
          className="flex flex-row gap-1"
        >
          <Button
            key={`up${index.toString()}`}
            intent="primary"
            weight="minimal"
            size="x-small"
            onClick={(): void => {
              if (index < 1) {
                return;
              }
              setExpression({
                ...expression,
                // Decrease case index by 1
                cases: [
                  ...expression.cases.slice(0, index - 1),
                  expression.cases[index],
                  expression.cases[index - 1],
                  ...expression.cases.slice(index + 1),
                ],
              });
            }}
            disabled={
              index === 0 ||
              isKillSwitch(expression.cases[index].when) ||
              isKillSwitch(expression.cases[index - 1].when)
            }
            icon={upArrowSymbol}
          />
          <Button
            key={`down${index.toString()}`}
            intent="primary"
            weight="minimal"
            size="x-small"
            onClick={(): void => {
              if (index >= expression.cases.length - 1) {
                return;
              }
              setExpression({
                ...expression,
                // Increase case index by 1
                cases: [
                  ...expression.cases.slice(0, index),
                  expression.cases[index + 1],
                  expression.cases[index],
                  ...expression.cases.slice(index + 2),
                ],
              });
            }}
            disabled={
              index === expression.cases.length - 1 ||
              isKillSwitch(expression.cases[index].when)
            }
            icon={downArrowSymbol}
          />

          <DeleteButton
            size="x-small"
            key={`delete${index.toString()}`}
            className="mr-1"
            onClick={(): void =>
              setExpression({
                ...expression,
                cases: [
                  ...expression.cases.slice(0, index),
                  ...expression.cases.slice(index + 1),
                ],
              })
            }
          />
        </div>
      )}
    </Wrapper>
  );
}

function isKillSwitch(expression: Expression | null): boolean {
  return (
    !!expression &&
    expression.type === "BooleanExpression" &&
    expression.metadata?.note === kilSwitchExpressionNote
  );
}

function Wrapper({
  intent,
  show,
  children,
}: {
  intent: Intent;
  show?: boolean;
  children: React.ReactNode;
}): React.ReactElement | null {
  return (
    <div
      className={twMerge(
        "flex flex-col gap-4",
        intentToLightBgClassName(intent),
        intentToTextColorName(intent),
        intent !== "neutral" ? intentToBorderClassName(intent) : undefined,
        show ? "rounded-lg border p-2" : undefined
      )}
    >
      {children}
    </div>
  );
}
