import {
  EventTypeMap,
  Expression,
  Schema,
  SplitMap,
  asError,
} from "@hypertune/sdk/src/shared";
import {
  formatTypeSchemaName,
  getFullSchemaCode,
  renameEnum,
  renameEnumInExpression,
  renameObject,
  renameObjectInExpression,
  rootContextTypeNameFromSchema,
  rootObjectTypeNameFromSchema,
  splitSchemaCode,
  toStartCase,
} from "@hypertune/shared-internal";
import { useCallback, useState } from "react";
import { SelectedType, TypeReferencesMap } from "../schemaHooks";
import EnumEditor from "./enum/EnumEditor";
import TypeIcon from "../../../../components/icons/TypeIcon";

import ObjectEditor from "./object/ObjectEditor";
import { useAppDispatch } from "../../../../app/hooks";
import {
  setDraftCommit,
  setDraftCommitSchemaAndExpression,
  toggleShowDetails,
} from "../../projectSlice";
import Modal from "../../../../components/Modal";
import TypeEditorDetails from "./TypeEditorDetails";
import RenameTopBar from "../../../../components/RenameTopBar";
import { showSchemaNameError, typeNameError } from "./SchemaNameError";
import ErrorMessage from "../../../../components/ErrorMessage";

export default function TypeEditor({
  isVisible,
  readOnly,
  schema,
  splits,
  eventTypeMap,
  expression,
  selectedType,
  setSelectedType,
  fieldsAndValuesSearchText,
  setFieldsAndValuesSearchText,
  typeReferences,
}: {
  isVisible: boolean;
  readOnly: boolean;
  schema: Schema;
  splits: SplitMap;
  eventTypeMap: EventTypeMap;
  expression: Expression;
  selectedType: SelectedType | null;
  setSelectedType: (newSelectedType: SelectedType | null) => void;
  fieldsAndValuesSearchText: string;
  setFieldsAndValuesSearchText: (newSearchText: string) => void;
  typeReferences: TypeReferencesMap;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();

  const [isEditing, setIsEditing] = useState<Set<string>>(new Set());
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const selectedTypeExists =
    selectedType === null
      ? false
      : selectedType.type === "enum"
        ? !!schema.enums[selectedType.name]
        : !!schema.objects[selectedType.name];

  const renameType = useCallback(
    async (rawNewTypeName: string): Promise<void> => {
      if (selectedType === null) {
        return;
      }
      const newTypeName = formatTypeSchemaName(rawNewTypeName);

      try {
        switch (selectedType.type) {
          case "enum":
            await dispatch(
              setDraftCommitSchemaAndExpression({
                schema: renameEnum(schema, selectedType.name, newTypeName),
                expression: renameEnumInExpression(
                  expression,
                  selectedType.name,
                  newTypeName
                ),
              })
            );
            break;
          case "input":
          case "object":
          case "event": {
            const newSchema = renameObject(
              schema,
              selectedType.name,
              newTypeName
            );
            const { readOnlySchemaCode, editableSchemaCode } =
              splitSchemaCode(schema);
            await dispatch(
              setDraftCommit({
                schema: newSchema,
                schemaCode: getFullSchemaCode(
                  readOnlySchemaCode,
                  editableSchemaCode
                ),
                readOnlySchemaCode,
                editableSchemaCode,
                expression: renameObjectInExpression(
                  expression,
                  selectedType.name,
                  newTypeName
                ),
                eventTypes:
                  selectedType.type === "event"
                    ? renameEventTypeInEventTypeMap(
                        eventTypeMap,
                        selectedType.name,
                        newTypeName
                      )
                    : eventTypeMap,
                splits: renameEventTypeInSplitMap(
                  splits,
                  selectedType.name,
                  newTypeName
                ),
              })
            );
            break;
          }
          default:
            throw new Error(
              `Unexpected selected type option: "${selectedType.type}"`
            );
        }
        setSelectedType({ ...selectedType, name: newTypeName });
      } catch (error) {
        setErrorMessage(asError(error).message);
      }
    },
    [
      dispatch,
      setSelectedType,
      selectedType,
      schema,
      eventTypeMap,
      splits,
      expression,
    ]
  );

  const hasReferences =
    selectedType &&
    (selectedType.type === "event" ||
      (typeReferences[selectedType.name] &&
        typeReferences[selectedType.name].size > 0));
  const warningMessage =
    (selectedType?.type === "object" || selectedType?.type === "input") &&
    schema.objects[selectedType.name] &&
    Object.keys(schema.objects[selectedType.name].fields).length === 0
      ? `${toStartCase(selectedType.type)} has no fields`
      : !hasReferences
        ? `Type is unused`
        : null;

  const isSelectedTypeRoot =
    selectedType?.type === "object" &&
    selectedType.name === rootObjectTypeNameFromSchema(schema);
  const isSelectedTypeContext =
    selectedType?.type === "input" &&
    selectedType.name === rootContextTypeNameFromSchema(schema);
  const isSelectedTypeRootOrContext =
    isSelectedTypeRoot || isSelectedTypeContext;

  return (
    <>
      {errorMessage && (
        <Modal
          title="Error"
          saveIntent="danger"
          saveText="Dismiss"
          closeOnEsc
          onClose={() => setErrorMessage(null)}
          onSave={() => setErrorMessage(null)}
          childrenStyle={{ maxWidth: 450 }}
        >
          {errorMessage}
        </Modal>
      )}
      <div
        className={` ${
          isVisible ? "flex" : "hidden"
        } h-full w-full flex-col overflow-hidden bg-bg-light bg-dotted`}
      >
        {selectedType && selectedTypeExists && (
          <RenameTopBar
            icon={<TypeIcon type={selectedType.type} size="large" />}
            label={toStartCase(selectedType.name)}
            entityName="type"
            rename={
              !readOnly && !isSelectedTypeRootOrContext ? renameType : undefined
            }
            hasError={(newName) => {
              const newFormattedName = formatTypeSchemaName(newName);
              if (newFormattedName === selectedType.name) {
                return null;
              }
              return typeNameError(schema, newFormattedName);
            }}
            showError={showSchemaNameError}
            toggleSidebar={() => dispatch(toggleShowDetails())}
            errorMessage={
              warningMessage ? (
                <ErrorMessage
                  intent="warning"
                  errorMessage={warningMessage}
                  style={{ whiteSpace: "nowrap" }}
                />
              ) : undefined
            }
          />
        )}
        <div className="flex h-full w-full flex-row overflow-hidden">
          <div
            className="flex flex-grow flex-col items-stretch gap-3 overflow-y-auto px-12 py-[37px]"
            onMouseDown={() => {
              if (isEditing.size === 0 && selectedType !== null) {
                setSelectedType({ ...selectedType, selectedChildName: null });
              }
            }}
          >
            {selectedType !== null && selectedTypeExists && (
              <>
                {selectedType.type === "enum" ? (
                  <EnumEditor
                    readOnly={readOnly}
                    schema={schema}
                    expression={expression}
                    enumTypeName={selectedType.name}
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}
                    setErrorMessage={setErrorMessage}
                    fieldsAndValuesSearchText={fieldsAndValuesSearchText}
                    setFieldsAndValuesSearchText={setFieldsAndValuesSearchText}
                  />
                ) : (
                  <ObjectEditor
                    readOnly={readOnly}
                    schema={schema}
                    objectTypeName={selectedType.name}
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}
                    setErrorMessage={setErrorMessage}
                    fieldsAndValuesSearchText={fieldsAndValuesSearchText}
                    setFieldsAndValuesSearchText={setFieldsAndValuesSearchText}
                  />
                )}{" "}
              </>
            )}
          </div>
          {selectedType && selectedTypeExists && (
            <TypeEditorDetails
              readOnly={readOnly}
              schema={schema}
              expression={expression}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              setErrorMessage={setErrorMessage}
              trackEditing={setIsEditing}
              disableActions={isSelectedTypeRootOrContext}
              typeReferences={typeReferences}
              isSelectedTypeRoot={isSelectedTypeRoot}
              isSelectedTypeContext={isSelectedTypeContext}
            />
          )}
        </div>
      </div>
    </>
  );
}

function renameEventTypeInEventTypeMap(
  eventTypeMap: EventTypeMap,
  currentName: string,
  newName: string
): EventTypeMap {
  return Object.fromEntries(
    Object.entries(eventTypeMap).map(([id, eventType]) => [
      id,
      {
        ...eventType,
        name: eventType.name === currentName ? newName : eventType.name,
      },
    ])
  );
}

function renameEventTypeInSplitMap(
  splits: SplitMap,
  currentEventName: string,
  newEventName: string
): SplitMap {
  return Object.fromEntries(
    Object.entries(splits).map(([id, split]) => [
      id,
      {
        ...split,
        eventObjectTypeName:
          split.eventObjectTypeName === currentEventName
            ? newEventName
            : split.eventObjectTypeName,
      },
    ])
  );
}
