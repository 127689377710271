import {
  Arm,
  DiscreteDimension,
  Split,
  uniqueId,
} from "@hypertune/sdk/src/shared";

export function createSplit(): Split {
  const splitId = uniqueId();
  const dimension = createDiscreteDimension(splitId, 0);
  return {
    id: splitId,
    type: "test",
    name: "",
    description: "",
    dimensions: { [dimension.id]: dimension },
    eventObjectTypeName: null,
    featureIds: {},
  };
}

export function createDiscreteDimension(
  splitId: string,
  index: number
): DiscreteDimension {
  const dimensionId = uniqueId();
  const arm1 = createArm(dimensionId, 0, "Test", 0.5);
  const arm2 = createArm(dimensionId, 1, "Control", 0.5);
  return {
    id: dimensionId,
    splitId,
    index,
    name: `Dimension ${index + 1}`,
    type: "discrete",
    arms: { [arm1.id]: arm1, [arm2.id]: arm2 },
  };
}

export function createArm(
  dimensionId: string,
  index: number,
  name = "",
  allocation = 0
): Arm {
  return {
    id: uniqueId(),
    dimensionId,
    index,
    name,
    allocation,
  };
}
