import { CaretDown, CaretUpDown, Check, Plus } from "@phosphor-icons/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  lighterGreyHex,
  greyHex,
  lightGrey1Hex,
  blueHex,
  interFontFamily,
  mediumFontSize,
} from "../lib/constants";
import SearchInput from "./SearchInput";
import matchesSearch from "../lib/generic/matchesSearch";
import { EditTracking } from "../lib/types";
import twMerge from "../lib/twMerge";

type Options<TValue> =
  | { type: "options"; options: LabeledOption<TValue>[] }
  | { type: "groups"; groups: LabeledOptionGroup<TValue>[] };

export type LabeledOption<TValue> = {
  label: string;
  subtitle?: string;
  icon?: React.ReactNode;
  selectedIcon?: React.ReactNode;
  value: TValue;
  disabled?: boolean;
  disabledMessage?: string;
  showIconWhenSelected?: boolean;
};

export type LabeledOptionGroup<TValue> = {
  label: string;
  newOption?: () => void;
  options: LabeledOption<TValue>[];
};

export type DropdownStyle = {
  muted?: "none" | "button" | "all";
  caret?: "upDown" | "down";
  alignment?: "start" | "end";
  minWidth?: number;
  sectionMaxHeight?: number;
  buttonPrefix?: React.ReactNode;
  buttonClassName?: string;
  subtitleClassName?: string;
  panelClassName?: string;
  optionClassName?: string;
  hideSearch?: boolean;
  showButtonSubtitle?: boolean;
  scrollToPosition?: ScrollLogicalPosition;
};

export type TopBarDropDownProps<TValue> = {
  readOnly?: boolean;
  value: LabeledOption<TValue> | null;
  isLoading?: boolean;
  placeholder: string;
  options: Options<TValue>;
  onChange: (newOption: LabeledOption<TValue> | null) => void;
  dropdownStyle?: DropdownStyle;
  editTracking?: EditTracking;
};

export default function TopBarDropdown<TValue>(
  props: TopBarDropDownProps<TValue>
): React.ReactElement {
  return (
    <Popover className="relative flex flex-col items-stretch">
      {({ open }) => {
        return <TopBarDropdownInner {...props} isOpen={open} />;
      }}
    </Popover>
  );
}

function TopBarDropdownInner<TValue>({
  isOpen,
  readOnly,
  value,
  isLoading,
  placeholder,
  options,
  onChange,
  dropdownStyle,
  editTracking,
}: TopBarDropDownProps<TValue> & {
  isOpen: boolean;
}): React.ReactElement {
  useEffect(() => {
    if (editTracking) {
      editTracking.track((previous) => {
        if (isOpen && !previous.has(editTracking.id)) {
          return new Set([...previous, editTracking.id]);
        }
        if (!isOpen && previous.has(editTracking.id)) {
          return new Set([...previous].filter((id) => id !== editTracking.id));
        }
        return previous;
      });
    }
    if (!isOpen) {
      setFocusedIndex(null);
    }
  }, [editTracking, isOpen]);

  const {
    sectionMaxHeight,
    minWidth: baseMinWidth,
    muted = "none",
    caret = "upDown",
    alignment = "start",
    buttonPrefix,
    buttonClassName,
    subtitleClassName,
    panelClassName,
    optionClassName,
    hideSearch,
    showButtonSubtitle,
    scrollToPosition,
  } = dropdownStyle || {};

  const [searchText, _setSearchText] = useState("");
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  function setSearchText(newText: string): void {
    setFocusedIndex(null);
    _setSearchText(newText);
  }
  function setOption(newOption: LabeledOption<TValue> | null): void {
    setSearchText("");
    onChange(newOption);
  }

  function filterOptions(
    opts: LabeledOption<TValue>[]
  ): LabeledOption<TValue>[] {
    return opts.filter((option) =>
      matchesSearch(searchText, [
        option.label,
        ...(option.subtitle ? [option.subtitle] : []),
      ])
    );
  }

  const filteredOptions: Options<TValue> =
    options.type === "options"
      ? { type: "options", options: filterOptions(options.options) }
      : {
          type: "groups",
          groups: options.groups.map((group) => {
            return { ...group, options: filterOptions(group.options) };
          }),
        };
  const filteredValues: LabeledOption<TValue>[] =
    filteredOptions.type === "options"
      ? filteredOptions.options.map((option) => option)
      : filteredOptions.groups.flatMap((group) =>
          group.options.map((option) => option)
        );

  const focusedValue =
    focusedIndex !== null
      ? focusedIndex < filteredValues.length
        ? filteredValues[focusedIndex].value
        : null
      : null;

  const [minWidth, setMinWidth] = useState(baseMinWidth);
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (!buttonRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      setMinWidth(buttonRef.current?.offsetWidth ?? minWidth);
    });
    resizeObserver.observe(buttonRef.current);
    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PopoverButton
        ref={buttonRef}
        disabled={readOnly || isLoading}
        className={twMerge(
          `flex select-none flex-row items-center gap-2 rounded-md px-2 py-[8px] font-inter
          text-base font-semibold leading-[17px] outline-none
          ${
            !readOnly && !isLoading ? "cursor-pointer hover:bg-bg-hover/50" : ""
          }
            ${muted !== "none" || !value?.label ? "text-tx-muted" : ""}`,
          buttonClassName || ""
        )}
        style={{
          justifyContent: "space-between",
          ...(minWidth ? { minWidth } : {}),
        }}
      >
        <div className="grid grid-cols-[auto,1fr] items-center gap-2">
          {isLoading ? (
            <Skeleton
              className="col-span-2 rounded-lg"
              height={13}
              width={80}
            />
          ) : (
            <>
              {buttonPrefix || null}
              {value?.icon && value.showIconWhenSelected ? value.icon : null}
              <div className="grid auto-rows-[1fr]">
                <div className="truncate text-start">
                  {value?.label || placeholder}
                </div>
                {showButtonSubtitle && value?.subtitle && (
                  <div
                    className={`truncate text-sm font-medium text-tx-muted 
                           ${subtitleClassName || ""}`}
                  >
                    {value.subtitle}
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {isLoading || readOnly ? null : caret === "upDown" ? (
          <CaretUpDown color={lighterGreyHex} weight="bold" />
        ) : (
          <CaretDown color={lighterGreyHex} weight="fill" size={8} />
        )}
      </PopoverButton>

      <PopoverPanel
        anchor={alignment === "start" ? "bottom start" : "bottom end"}
        className={twMerge(
          "absolute z-200 -mx-5 -mt-3 select-none p-5 font-inter data-top:-mb-3 data-top:mt-3",
          panelClassName || ""
        )}
      >
        {({ close }) => (
          <div
            style={{ minWidth }}
            className="flex flex-col overflow-hidden rounded-[10px] border bg-white shadow-dropdown"
            onKeyDown={(event) => {
              if (filteredValues.length === 0) {
                return;
              }
              if (event.key === "Enter" && focusedIndex !== null) {
                event.preventDefault();
                setOption(filteredValues[focusedIndex]);
                close();
                return;
              }
              if (event.key === "ArrowDown") {
                if (
                  focusedIndex === null ||
                  focusedIndex === filteredValues.length - 1
                ) {
                  setFocusedIndex(0);
                  return;
                }
                setFocusedIndex(focusedIndex + 1);
                return;
              }

              if (event.key === "ArrowUp") {
                if (focusedIndex === null || focusedIndex === 0) {
                  setFocusedIndex(filteredValues.length - 1);
                  return;
                }
                setFocusedIndex(focusedIndex - 1);
              }
            }}
          >
            {!hideSearch && (
              <SearchInput
                focusOnMount
                searchText={searchText}
                setSearchText={setSearchText}
                noFocusHighlight
                style={{
                  border: "none",
                  borderRadius: 0,
                  borderBottom: `1px solid ${greyHex}`,
                  maxWidth: "inherit",
                  backgroundColor: lightGrey1Hex,
                  fontFamily: interFontFamily,
                  fontSize: mediumFontSize,
                }}
              />
            )}
            <div
              className={`flex flex-col p-1 ${
                !sectionMaxHeight ? "max-h-[280px] overflow-y-auto" : ""
              }`}
            >
              {filteredOptions.type === "groups" && (
                <div className="flex flex-col">
                  {filteredOptions.groups.map((group) => (
                    <>
                      <div
                        className="mb-2 mt-2 flex flex-row items-center justify-between px-2
                             text-base leading-3 text-tx-muted"
                      >
                        {group.label}
                        {group.newOption && (
                          <div
                            className="cursor-pointer rounded p-[2px] hover:bg-bg-hover/50"
                            onClick={() => {
                              group.newOption?.();
                              close();
                            }}
                          >
                            <Plus
                              weight="bold"
                              color={lighterGreyHex}
                              size={12}
                            />
                          </div>
                        )}
                      </div>
                      <TopBarOptions
                        maxHeight={sectionMaxHeight}
                        value={value?.value || null}
                        focusedValue={focusedValue}
                        options={group.options}
                        onChange={(option) => {
                          setOption(option as LabeledOption<TValue>);
                          close();
                        }}
                        optionClassName={optionClassName}
                        subtitleClassName={subtitleClassName}
                        muted={muted === "all"}
                        scrollToPosition={scrollToPosition}
                      />
                    </>
                  ))}
                </div>
              )}
              {filteredOptions.type === "options" && (
                <TopBarOptions
                  value={value?.value || null}
                  focusedValue={focusedValue}
                  options={filteredOptions.options}
                  onChange={(option) => {
                    setOption(option as LabeledOption<TValue>);
                    close();
                  }}
                  maxHeight={sectionMaxHeight}
                  optionClassName={optionClassName}
                  subtitleClassName={subtitleClassName}
                  muted={muted === "all"}
                  scrollToPosition={scrollToPosition}
                />
              )}
            </div>
          </div>
        )}
      </PopoverPanel>
    </>
  );
}

function TopBarOptions<TValue>({
  maxHeight,
  value,
  focusedValue,
  options,
  onChange,
  muted,
  optionClassName,
  subtitleClassName,
  scrollToPosition,
}: {
  maxHeight?: number;
  value: TValue | null;
  focusedValue: TValue;
  options: LabeledOption<TValue>[];
  onChange: (newOption: LabeledOption<TValue> | null) => void;
  muted: boolean;
  optionClassName?: string;
  subtitleClassName?: string;
  scrollToPosition?: ScrollLogicalPosition;
}): React.ReactElement {
  return (
    <div
      className="flex flex-col gap-[2px] overflow-auto"
      style={{ maxHeight }}
    >
      {options.length === 0 && (
        <div className="py-2 text-center text-sm italic leading-normal text-tx-muted">
          No search matches
        </div>
      )}
      {options.map((option) => (
        <OptionRow
          value={value}
          focusedValue={focusedValue}
          option={option}
          onChange={onChange}
          optionClassName={optionClassName}
          subtitleClassName={subtitleClassName}
          muted={muted}
          scrollToPosition={scrollToPosition}
        />
      ))}
    </div>
  );
}

function OptionRow<TValue>({
  value,
  focusedValue,
  option,
  onChange,
  optionClassName,
  subtitleClassName,
  muted,
  scrollToPosition,
}: {
  value: TValue | null;
  focusedValue: TValue | null;
  option: LabeledOption<TValue>;
  onChange: (newOption: LabeledOption<TValue> | null) => void;
  optionClassName?: string;
  subtitleClassName?: string;
  muted: boolean;
  scrollToPosition?: ScrollLogicalPosition;
}): React.ReactElement {
  const isSelected = option.value === value;
  const isFocused = option.value === focusedValue;
  const isDisabled = option.disabled;

  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isSelected || isFocused) {
      const el = divRef.current;
      if (el) {
        el.scrollIntoView({
          block: scrollToPosition || (isSelected ? "start" : "center"),
        });
      }
    }
  }, [scrollToPosition, isSelected, isFocused]);

  return (
    <div
      ref={divRef}
      className={twMerge(
        `grid grid-cols-[1fr,auto] items-center justify-between gap-2 rounded-md py-[8px] pl-2
         pr-[10px] text-base font-semibold leading-normal
          ${
            isSelected
              ? "text-intent-primary"
              : muted || isDisabled
                ? "text-tx-muted"
                : ""
          }
          ${
            isFocused
              ? "bg-bg-hover/50 "
              : isSelected
                ? "bg-bg-primary"
                : focusedValue === null && !isDisabled
                  ? " hover:bg-bg-hover/50"
                  : ""
          }
          ${
            isDisabled
              ? "cursor-not-allowed"
              : !isSelected
                ? "cursor-pointer"
                : ""
          }`,
        optionClassName || ""
      )}
      onClick={!isSelected && !isDisabled ? () => onChange(option) : undefined}
    >
      <div className="grid grid-cols-[auto,1fr] items-center gap-2">
        {(isSelected && option.selectedIcon) || option.icon || null}
        <div className="grid auto-rows-[1fr] leading-[17px]">
          <span className="truncate">{option.label}</span>

          {(option.subtitle || (isDisabled && option.disabledMessage)) && (
            <p
              className={`${
                isSelected ? "text-intent-primary" : "text-tx-muted"
              } truncate pr-px text-sm font-medium
              ${subtitleClassName || ""}
              ${isDisabled ? "italic" : ""}`}
            >
              {isDisabled ? option.disabledMessage : option.subtitle}
            </p>
          )}
        </div>
      </div>
      {isSelected && <Check color={blueHex} weight="bold" size={12} />}
    </div>
  );
}
