import React from "react";
import { Info, WarningCircle } from "@phosphor-icons/react";
import {
  borderRadiusPx,
  greyHex,
  intentPrimaryHex,
  intentWarningHex,
  whiteHex,
} from "../lib/constants";
import SuccessCircle from "./icons/SuccessCircle";
import ErrorCircle from "./icons/ErrorCircle";

export type ToastType = "success" | "error" | "warning" | "info";

export type ToastMessage = {
  type: ToastType;
  text: string;
};

export default function Toast({
  messages,
  style,
}: {
  messages: ToastMessage[];
  style?: React.CSSProperties;
}): React.ReactElement {
  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: whiteHex,
        border: `1px solid ${greyHex}`,
        borderRadius: borderRadiusPx,
        padding: "4px 8px",
        display: "flex",
        flexDirection: "column",
        gap: 4,
        zIndex: 999,
        ...(style || {}),
      }}
    >
      {messages.map((message) => (
        <InnerMessage message={message} />
      ))}
    </div>
  );
}

function InnerMessage({
  message,
}: {
  message: ToastMessage;
}): React.ReactElement | null {
  return (
    <div className="flex flex-row items-center gap-1">
      {message.type === "success" ? (
        <SuccessCircle />
      ) : message.type === "error" ? (
        <ErrorCircle />
      ) : message.type === "warning" ? (
        <WarningCircle size={14.5} color={intentWarningHex} weight="regular" />
      ) : (
        <Info size={14.5} color={intentPrimaryHex} weight="regular" />
      )}
      <div
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          maxWidth: 400,
        }}
      >
        {message.text}
      </div>
    </div>
  );
}
