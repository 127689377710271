import { useMemo } from "react";
import {
  ProjectBranchQuery,
  ProjectQuery,
  PullRequestStatus,
} from "../../../generated/graphql";
import ActivateButton from "./ActivateButton";
import Tooltip from "../../../components/tooltips/Tooltip";
import { ProjectView } from "../projectHooks";
import GoToDraftButton from "./GoToDraftButton";
import GoToPullRequestButton from "./GoToPullRequestButton";
import { useHypertune } from "../../../generated/hypertune.react";
import CreatePullRequestButton from "./CreatePullRequestButton";
import { DraftCommit, FullDraftCommitDerivedData } from "../projectSlice";
import DeleteBranchButton from "../pull-request/DeleteBranchButton";
import ResetButton from "./ResetButton";
import ViewChangesButton from "./ViewChangesButton";

export default function EditButtons({
  meId,
  projectId,
  branch,
  branches,
  pullRequests,
  selectedBranchName,
  selectedView,
  toastDirection = "below-ltr",
  setSelectedCommitId,
  commitId,
  commitMessage,
  draftCommit,
  draftCommitDerivedData,
  canEdit,
  canContribute,
}: {
  meId: string;
  projectId: string;
  branch: ProjectBranchQuery["projectBranch"];
  branches?: ProjectQuery["project"]["branches"];
  pullRequests?: ProjectQuery["project"]["pullRequests"];
  selectedBranchName: string;
  selectedView: ProjectView;
  toastDirection?: "below-ltr" | "below-rtl" | "above-rtl";
  setSelectedCommitId: (newCommitId: string) => void;
  commitId: string | null;
  commitMessage?: string;
  draftCommit?: DraftCommit;
  draftCommitDerivedData: FullDraftCommitDerivedData;
  canEdit: boolean;
  canContribute: boolean;
}): React.ReactElement | null {
  const content = useHypertune().content();

  const branchIdToPRId = useMemo(
    () =>
      Object.fromEntries(
        pullRequests
          ?.filter(
            (pullRequest) =>
              pullRequest.status === PullRequestStatus.Open ||
              pullRequest.status === PullRequestStatus.Approved
          )
          .map((pullRequest) => [pullRequest.fromBranch.id, pullRequest.id]) ??
          []
      ),
    [pullRequests]
  );
  const pullRequest = pullRequests?.find(
    (pr) =>
      (pr.status === PullRequestStatus.Open ||
        pr.status === PullRequestStatus.Approved) &&
      pr.fromBranch.name === selectedBranchName
  );

  const isViewingDraft = commitId === null;
  const isCommitAlreadyActive =
    !isViewingDraft && commitId === branch?.activeCommit.id;

  return (
    <div className="mr-1 flex flex-row items-center gap-2">
      {!isViewingDraft && (
        <GoToDraftButton setSelectedCommitId={setSelectedCommitId} />
      )}
      {pullRequest && <GoToPullRequestButton pullRequest={pullRequest} />}
      <DeleteBranchButton
        branch={{
          id: branch.id,
          name: branch.name,
          authorId: branch.author?.id ?? null,
          hasOpenPR: !!branchIdToPRId[branch.id],
        }}
        meId={meId}
        canEdit={canEdit}
        canContribute={canContribute}
        text="Delete branch"
        weight="elevated"
        hideIcon
        size="large"
      />
      <CreatePullRequestButton
        pullRequest={pullRequest}
        projectId={projectId}
        branches={branches}
        selectedBranchName={branch.name}
        canContribute={canContribute}
      />
      {!isCommitAlreadyActive && (
        <div className="mx-[4px] h-[32px] border-l first:hidden" />
      )}
      {isViewingDraft && (
        <>
          <ViewChangesButton hasChanges={draftCommitDerivedData.hasChanges} />
          <ResetButton branch={branch} />
        </>
      )}
      <Tooltip
        id="logic"
        step={3}
        allSteps={content.logic().tooltips()}
        isVisible={selectedView === "logic"}
        placement="bottom-end"
        dotPlacement="bottom-start"
        topArrowOffset={-18}
        topOffset={19}
        leftOffset={-18.5}
      >
        <ActivateButton
          projectId={projectId}
          branch={branch}
          isAlreadyActive={isCommitAlreadyActive}
          toastDirection={toastDirection}
          setSelectedCommitId={setSelectedCommitId}
          commitId={commitId}
          commitMessage={commitMessage}
          draftCommit={draftCommit}
          draftCommitDerivedData={draftCommitDerivedData}
        />
      </Tooltip>
    </div>
  );
}
