import React from "react";
import {
  LogLevel,
  ProjectBranchQuery,
  useCreateCommitMutation,
} from "../../../generated/graphql";
import Toast, { ToastMessage } from "../../../components/Toast";
import getErrorMessage from "../../../lib/query/getErrorMessage";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  DraftCommit,
  FullDraftCommitDerivedData,
  setDraftCommit,
  setIsActivating,
  setRebaseState,
} from "../projectSlice";
import Button from "../../../components/buttons/Button";
import { draftCommitId } from "../../../lib/constants";
import getCreateCommitInput from "../../../lib/query/getCreateCommitInput";
import { newCommitRefetchQueries } from "../../../lib/query/refetchQueries";
import { useProjectSelectedState } from "../projectHooks";
import { getPullRequestParams } from "../pull-request/pullRequestHooks";
import { useHypertune } from "../../../generated/hypertune.react";

export default function ActivateButton({
  projectId,
  branch,
  isAlreadyActive,
  toastDirection = "below-ltr",
  setSelectedCommitId,
  commitId,
  commitMessage,
  draftCommit,
  draftCommitDerivedData,
}: {
  projectId: string;
  branch: ProjectBranchQuery["projectBranch"];
  isAlreadyActive: boolean;
  toastDirection?: "below-ltr" | "below-rtl" | "above-rtl";
  setSelectedCommitId: (newCommitId: string) => void;
  commitId: string | null;
  commitMessage?: string;
  draftCommit?: DraftCommit;
  draftCommitDerivedData: FullDraftCommitDerivedData;
}): React.ReactElement | null {
  const hypertune = useHypertune();
  const { setSelected } = useProjectSelectedState();
  const dispatch = useAppDispatch();
  const rebase = useAppSelector((state) => state.project.rebase);
  const isCreatingNewCommit = commitId === null;
  const isActivating = useAppSelector((state) => state.project.isActivating);
  const hasError =
    !!draftCommitDerivedData.schemaCodeError ||
    !!draftCommitDerivedData.logicError ||
    !!draftCommitDerivedData.splitsError;

  const disabledMessage = isActivating
    ? "Saving..."
    : isAlreadyActive
      ? "Already active"
      : !draftCommit
        ? "Loading..."
        : // If it's a draft, require a change and validation
          isCreatingNewCommit && !draftCommitDerivedData.hasChanges
          ? "No changes"
          : isCreatingNewCommit && hasError
            ? "Draft has errors"
            : undefined;
  const disabled = disabledMessage !== undefined;

  const [createCommit] = useCreateCommitMutation({
    refetchQueries: newCommitRefetchQueries,
    awaitRefetchQueries: true,
  });

  const [toast, internalSetToast] = React.useState<{
    messages: ToastMessage[];
    intervalId: NodeJS.Timeout;
  } | null>(null);

  function setToast(messages: ToastMessage[]): void {
    if (toast) {
      clearInterval(toast.intervalId);
    }
    const intervalId = setTimeout(() => {
      internalSetToast(null);
    }, 3000);
    internalSetToast({ messages, intervalId });
  }

  if (!branch) {
    return null;
  }

  const nextCommitVersion = branch.commits.length + 1;
  const activeCommitId = branch.activeCommit.id;

  async function activate(): Promise<void> {
    let message: string;

    if (!draftCommit) {
      setToast([{ type: "error", text: "Missing data for commit." }]);
      return;
    }

    if (isCreatingNewCommit && draftCommit) {
      message = `v${nextCommitVersion}`;
    } else {
      // Rollback
      if (!commitId || !commitMessage) {
        setToast([
          { type: "error", text: "Missing data for commit to roll back to." },
        ]);
        return;
      }
      message = `v${nextCommitVersion} (rollback to ${commitMessage})`;
    }
    const input = getCreateCommitInput({
      projectId,
      message,
      draftCommit,
      parentId: activeCommitId,
      branchName: branch.name,
    });
    if (rebase) {
      input.message = rebase.commitMessage;
      input.rebaseBranchName = rebase.branchName;
    }

    const resp = await createCommit({ variables: { input } });
    setToast([
      { type: "success", text: `Activated new commit "${message}".` },
      ...(resp.data?.createCommit.messages
        ? graphqlMessagesToToastMessages(resp.data.createCommit.messages)
        : []),
    ]);
    if (!isCreatingNewCommit && !resp.data?.createCommit.newBranchName) {
      dispatch(setDraftCommit(draftCommit));
      setSelectedCommitId(draftCommitId);
    }
    if (rebase) {
      dispatch(setRebaseState(undefined));
    }
    if (
      resp.data?.createCommit.newBranchName &&
      resp.data?.createCommit.newPullRequestId
    ) {
      setSelected({
        branchName: resp.data.createCommit.newBranchName,
        view: "pull-requests",
        searchParams: getPullRequestParams(
          resp.data.createCommit.newPullRequestId.toString(),
          /* showShareModal */ true
        ),
      });
    }
  }

  async function onSubmit(): Promise<void> {
    if (disabled) {
      return;
    }

    dispatch(setIsActivating(true));
    try {
      await activate();
      hypertune.events().commitCreated();
      console.debug("ActivateButton logged commitCreated");
    } catch (error) {
      console.error("Activate commit error:", error);
      setToast([{ type: "error", text: getErrorMessage(error) }]);
    } finally {
      dispatch(setIsActivating(false));
    }
  }

  return (
    <div
      style={{
        position: "relative",
        display: commitId === branch.activeCommit.id ? "none" : "block",
      }}
    >
      <Button
        intent="primary"
        weight="elevated"
        size="large"
        disabled={disabled}
        title={disabledMessage}
        onClick={onSubmit}
        text={
          rebase
            ? "Squash and rebase"
            : isCreatingNewCommit
              ? "Save"
              : "Roll back"
        }
        loading={isActivating}
      />
      {toast ? (
        <Toast
          style={
            toastDirection === "below-ltr"
              ? {
                  top: 36,
                  left: 0,
                }
              : toastDirection === "below-rtl"
                ? {
                    top: 36,
                    right: 0,
                  }
                : {
                    bottom: 36,
                    right: 0,
                  }
          }
          messages={toast.messages}
        />
      ) : null}
    </div>
  );
}

function graphqlMessagesToToastMessages(
  messages: { logLevel: LogLevel; body: string }[]
): ToastMessage[] {
  return messages.map((message) => {
    switch (message.logLevel) {
      case LogLevel.Warn:
        return { type: "warning", text: message.body };
      case LogLevel.Error:
        return { type: "error", text: message.body };
      default:
        return { type: "info", text: message.body };
    }
  });
}
