import {
  ConstValueNode,
  FragmentDefinitionNode,
  GraphQLSchema,
  GraphQLType,
  isInputType,
  Kind,
  OperationDefinitionNode,
  parse,
  SelectionSetNode,
  typeFromAST,
  TypeNode,
  ValueNode,
  VariableDefinitionNode,
} from "graphql";
import {
  Schema,
  ObjectValueType,
  Query,
  UnionValueType,
  ValueType,
  QueryVariable,
  ValueWithVariables,
  ObjectValueWithVariables,
  isQueryVariable,
  FieldQuery,
  InlineFragment,
  VariableDefinitions,
  Fragment,
  FragmentDefinitions,
} from "@hypertune/sdk/src/shared/types";
import {
  graphqlTypeNameKey,
  isPartialObjectKey,
  isQueryVariableKey,
} from "@hypertune/sdk/src/shared/constants";
import throws from "@hypertune/sdk/src/shared/helpers/throws";
import nullThrows from "@hypertune/sdk/src/shared/helpers/nullThrows";
import { Value } from "@hypertune/sdk";
import { getFieldArgumentsObjectTypeName } from "./schema/fieldArgumentsObjectTypeName";
import getValueTypeFromGraphqlType from "./schema/getValueTypeFromGraphqlType";
import isValidGraphqlInputType from "./schema/isValidGraphqlInputType";
import valueTypeToString from "./schema/valueTypeToString";
import getSchema from "./schema/getSchema";
import valueTypesAreEqual from "./schema/valueTypesAreEqual";
import { queryObjectTypeName } from "./constants";
import getDefaultQuery from "./schema/getDefaultQuery";

const allFieldsKey = "__allNestedFields__";

export default function getSchemaAndQuery({
  graphqlSchema,
  queryCode,
  silentlySkipInvalidFieldNames,
  markQueryFieldArgumentsPartial,
  useSharedFragments,
}: {
  graphqlSchema: GraphQLSchema;
  queryCode: string;
  silentlySkipInvalidFieldNames: boolean; // Used by init request
  markQueryFieldArgumentsPartial: boolean; // Used by codegen and init requests
  useSharedFragments: boolean;
}): {
  schema: Schema;
  query: Query<ObjectValueWithVariables>;
  queryName?: string;
} {
  const document = parse(queryCode.replaceAll("*", allFieldsKey));

  const queryDefinitions: OperationDefinitionNode[] = [];
  const graphqlFragmentDefinitions: { [name: string]: FragmentDefinitionNode } =
    {};

  document.definitions.forEach((definition) => {
    const { kind } = definition;
    switch (definition.kind) {
      case Kind.OPERATION_DEFINITION: {
        if (definition.operation !== "query") {
          throw new Error("You can only define a query operation");
        }
        queryDefinitions.push(definition);
        return;
      }
      case Kind.FRAGMENT_DEFINITION: {
        graphqlFragmentDefinitions[definition.name.value] = definition;
        return;
      }
      default: {
        throw new Error(`Unexpected definition kind: ${kind}`);
      }
    }
  });

  if (queryDefinitions.length !== 1) {
    throw new Error("You must define exactly 1 query");
  }
  const queryDefinition = queryDefinitions[0];
  const queryName = queryDefinition.name?.value;

  const { variableDefinitions } = getVariableDefinitions(
    graphqlSchema,
    queryDefinition.variableDefinitions ?? []
  );

  const schema = getSchema(graphqlSchema);

  const query = getQueryFromSelectionSet({
    schema,
    silentlySkipInvalidFieldNames,
    markQueryFieldArgumentsPartial,
    useSharedFragments,
    graphqlFragmentDefinitions,
    selectionSet: queryDefinition.selectionSet,
    valueType: { type: "ObjectValueType", objectTypeName: queryObjectTypeName },
    variableDefinitions,
  });

  return { schema, query, queryName };
}

function getQueryFromSelectionSet({
  schema,
  silentlySkipInvalidFieldNames,
  markQueryFieldArgumentsPartial,
  useSharedFragments,
  graphqlFragmentDefinitions,
  selectionSet,
  valueType,
  variableDefinitions,
}: {
  schema: Schema;
  silentlySkipInvalidFieldNames: boolean;
  markQueryFieldArgumentsPartial: boolean;
  useSharedFragments: boolean;
  graphqlFragmentDefinitions: {
    [fragmentName: string]: FragmentDefinitionNode;
  };
  selectionSet: SelectionSetNode;
  valueType: ObjectValueType | UnionValueType;
  variableDefinitions: VariableDefinitions;
}): Query<ObjectValueWithVariables> {
  const fragmentDefinitions: FragmentDefinitions<ObjectValueWithVariables> = {};
  return {
    variableDefinitions,
    fragmentDefinitions: useSharedFragments ? fragmentDefinitions : {},
    fieldQuery: getFieldQueryFromSelectionSet({
      schema,
      silentlySkipInvalidFieldNames,
      markQueryFieldArgumentsPartial,
      useSharedFragments,
      fragmentDefinitions,
      graphqlFragmentDefinitions,
      selectionSet,
      valueType,
      variableDefinitions,
    }),
  };
}

function getFieldQueryFromSelectionSet({
  schema,
  silentlySkipInvalidFieldNames,
  markQueryFieldArgumentsPartial,
  useSharedFragments,
  fragmentDefinitions,
  graphqlFragmentDefinitions,
  selectionSet,
  valueType,
  variableDefinitions,
}: {
  schema: Schema;
  silentlySkipInvalidFieldNames: boolean;
  markQueryFieldArgumentsPartial: boolean;
  useSharedFragments: boolean;
  fragmentDefinitions: FragmentDefinitions<ObjectValueWithVariables>;
  graphqlFragmentDefinitions: {
    [fragmentName: string]: FragmentDefinitionNode;
  };
  selectionSet: SelectionSetNode;
  valueType: ObjectValueType | UnionValueType;
  variableDefinitions: VariableDefinitions;
}): FieldQuery<ObjectValueWithVariables> {
  const query: FieldQuery<ObjectValueWithVariables> = {};

  selectionSet.selections.forEach((selection) => {
    const { kind } = selection;
    switch (kind) {
      case Kind.FIELD: {
        if (selection.alias) {
          throw new Error("Field aliases are not supported");
        }
        if (valueType.type === "UnionValueType") {
          throw new Error(
            `You cannot directly select subfields on union type "${valueType.unionTypeName}". You can use inline or named fragments on the concrete types in the union instead.`
          );
        }

        const { objectTypeName } = valueType;

        const schemaObject = nullThrows(
          schema.objects[objectTypeName]?.fields,
          `No concrete type "${objectTypeName}".`
        );

        const fieldName = selection.name.value;
        if (fieldName === graphqlTypeNameKey) {
          return;
        }
        if (fieldName === allFieldsKey) {
          const fullQuery = nullThrows(
            getDefaultQuery({
              schema,
              includeDeprecated: false,
              includeArguments: false,
              markQueryFieldArgumentsPartial,
              objectTypeNames: [objectTypeName],
              useSharedFragments:
                // Only use shared fragments if there are no fragments
                // specified in the GraphQL to prevent potential collisions.
                useSharedFragments &&
                Object.keys(graphqlFragmentDefinitions).length === 0,
            }),
            new Error(`No default query for type "${objectTypeName}".`)
          );
          Object.assign(query, fullQuery.fieldQuery);
          Object.assign(fragmentDefinitions, fullQuery.fragmentDefinitions);
          return;
        }

        const fieldValueType = schemaObject[fieldName]?.valueType;
        if (!fieldValueType) {
          if (silentlySkipInvalidFieldNames) {
            return;
          }
          throw new Error(
            `No field "${fieldName}" on type "${objectTypeName}".`
          );
        }
        if (fieldValueType.type !== "FunctionValueType") {
          throw new Error("Not a function value type.");
        }

        const fieldArguments: ObjectValueWithVariables = Object.fromEntries(
          (selection.arguments || []).flatMap((argument) => {
            const value = getValue(argument.value);
            return typeof value === "undefined"
              ? []
              : [[argument.name.value, value]];
          })
        );

        const { errorMessages, missingKeyErrorMessages } =
          getValueErrorMessages(
            schema,
            {
              type: "ObjectValueType",
              objectTypeName: getFieldArgumentsObjectTypeName({
                parentObjectTypeName: objectTypeName,
                fieldName,
              }),
            },
            fieldArguments,
            variableDefinitions,
            [objectTypeName, fieldName]
          );
        if (errorMessages.length > 0) {
          throw new Error(`Errors: ${errorMessages.join(" ")}`);
        }
        if (missingKeyErrorMessages.length > 0) {
          if (markQueryFieldArgumentsPartial) {
            fieldArguments[isPartialObjectKey] = true;
          }
        }

        const nestedReturnObjectOrUnionValueType =
          getNestedObjectOrUnionValueType(fieldValueType.returnValueType);

        if (!query[objectTypeName]) {
          query[objectTypeName] = {
            type: "InlineFragment",
            objectTypeName,
            selection: {},
          };
        }
        (
          query[objectTypeName] as InlineFragment<ObjectValueWithVariables>
        ).selection[fieldName] = {
          fieldArguments,
          fieldQuery: selection.selectionSet
            ? getFieldQueryFromSelectionSet({
                schema,
                silentlySkipInvalidFieldNames,
                markQueryFieldArgumentsPartial,
                useSharedFragments,
                graphqlFragmentDefinitions,
                fragmentDefinitions,
                selectionSet: selection.selectionSet,
                valueType:
                  nestedReturnObjectOrUnionValueType ||
                  throws(
                    `You cannot select subfields on field "${objectTypeName}.${fieldName}" with return type "${valueTypeToString(
                      fieldValueType.returnValueType
                    )}".`
                  ),
                variableDefinitions,
              })
            : nestedReturnObjectOrUnionValueType
              ? throws(
                  `You must select subfields on field "${objectTypeName}.${fieldName}" with return type "${valueTypeToString(
                    fieldValueType.returnValueType
                  )}".`
                )
              : null,
        };
        break;
      }

      case Kind.FRAGMENT_SPREAD: {
        const fragmentName = selection.name.value;
        const fragment = nullThrows(
          graphqlFragmentDefinitions[fragmentName],
          `No fragment with name "${fragmentName}".`
        );
        const objectTypeName = fragment.typeCondition.name.value;

        if (valueType.type === "UnionValueType") {
          validateUnionAndMember(schema, valueType, objectTypeName);
        }

        if (!fragmentDefinitions[fragmentName]) {
          const fragmentQuery = getFieldQueryFromSelectionSet({
            schema,
            silentlySkipInvalidFieldNames,
            markQueryFieldArgumentsPartial,
            useSharedFragments,
            graphqlFragmentDefinitions,
            fragmentDefinitions,
            selectionSet: fragment.selectionSet,
            valueType: { type: "ObjectValueType", objectTypeName },
            variableDefinitions,
          });
          const [fragmentDefinition] = fragmentQuery
            ? Object.values<Fragment<ObjectValueWithVariables>>(fragmentQuery)
            : [null];
          if (fragmentDefinition?.type !== "InlineFragment") {
            throw new Error(
              `invalid fragment definition: "${JSON.stringify(fragmentDefinition)}"`
            );
          }
          // eslint-disable-next-line no-param-reassign
          fragmentDefinitions[fragmentName] = fragmentDefinition;
        }
        query[objectTypeName] = useSharedFragments
          ? {
              type: "FragmentSpread",
              fragmentName,
            }
          : fragmentDefinitions[fragmentName];
        break;
      }

      case Kind.INLINE_FRAGMENT: {
        const objectTypeName: string = selection.typeCondition
          ? selection.typeCondition.name.value
          : valueType.type === "ObjectValueType"
            ? valueType.objectTypeName
            : throws(
                `You cannot have an inline fragment with no type condition directly on a union type like "${valueType.unionTypeName}". Inline fragments on unions must have a concrete type condition.`
              );

        if (valueType.type === "UnionValueType") {
          validateUnionAndMember(schema, valueType, objectTypeName);
        }

        const fragmentQuery = getFieldQueryFromSelectionSet({
          schema,
          silentlySkipInvalidFieldNames,
          markQueryFieldArgumentsPartial,
          useSharedFragments,
          graphqlFragmentDefinitions,
          fragmentDefinitions,
          selectionSet: selection.selectionSet,
          valueType: { type: "ObjectValueType", objectTypeName },
          variableDefinitions,
        });
        Object.assign(query, fragmentQuery);
        break;
      }

      default: {
        const neverKind: never = kind;
        throw new Error(`Unexpected selection kind: ${neverKind}`);
      }
    }
  });

  return query;
}

function getValue(value: ValueNode): ValueWithVariables {
  const { kind } = value;
  switch (kind) {
    case Kind.BOOLEAN:
    case Kind.STRING:
    case Kind.ENUM:
      return value.value;
    case Kind.INT: {
      return parseInt(value.value, 10);
    }
    case Kind.FLOAT: {
      return parseFloat(value.value);
    }
    case Kind.OBJECT:
      return Object.fromEntries(
        value.fields.map((field) => [field.name.value, getValue(field.value)])
      );
    case Kind.LIST:
      return value.values.map((item) => getValue(item));
    case Kind.NULL:
      throw new Error("Field argument values cannot be null.");
    case Kind.VARIABLE: {
      const queryVariable: QueryVariable = {
        [isQueryVariableKey]: true,
        name: value.name.value,
      };
      return queryVariable;
    }
    default: {
      const neverKind: never = kind;
      throw new Error(`Unexpected value kind: ${neverKind}`);
    }
  }
}

// eslint-disable-next-line max-params
function getValueErrorMessages(
  schema: Schema,
  expectedValueType: ValueType,
  actualValue: ValueWithVariables,
  variableDefinitions: VariableDefinitions,
  path: string[]
): {
  errorMessages: string[];
  missingKeyErrorMessages: string[];
} {
  const errorMessage = `Expected a ${valueTypeToString(
    expectedValueType
  )} but got ${JSON.stringify(
    actualValue
  )} (${typeof actualValue}) for field argument "${path.join(".")}".${
    expectedValueType.type === "EnumValueType"
      ? ` The valid values are ${Object.keys(
          nullThrows(
            schema.enums[expectedValueType.enumTypeName].values,
            `No schema enum "${expectedValueType.enumTypeName}"`
          )
        ).join(", ")}.`
      : ""
  }`;

  // Validate graphql type of the variable against the expected field argument type
  if (isQueryVariable(actualValue)) {
    const actualVariableValueType = variableDefinitions[actualValue.name];

    return {
      errorMessages: valueTypesAreEqual(
        actualVariableValueType.valueType,
        expectedValueType
      )
        ? []
        : [
            `Expected a ${valueTypeToString(
              expectedValueType
            )} but got a variable of type ${valueTypeToString(
              actualVariableValueType.valueType
            )} for field argument "${path.join(".")}".`,
          ],
      missingKeyErrorMessages: [],
    };
  }

  switch (expectedValueType.type) {
    case "BooleanValueType":
      return {
        errorMessages: typeof actualValue === "boolean" ? [] : [errorMessage],
        missingKeyErrorMessages: [],
      };

    case "IntValueType":
      return {
        errorMessages:
          typeof actualValue === "number" && Number.isInteger(actualValue)
            ? []
            : [errorMessage],
        missingKeyErrorMessages: [],
      };

    case "FloatValueType":
      return {
        errorMessages: typeof actualValue === "number" ? [] : [errorMessage],
        missingKeyErrorMessages: [],
      };

    case "StringValueType":
      return {
        errorMessages: typeof actualValue === "string" ? [] : [errorMessage],
        missingKeyErrorMessages: [],
      };

    case "EnumValueType": {
      const schemaEnum = nullThrows(
        schema.enums[expectedValueType.enumTypeName].values,
        `No schema enum "${expectedValueType.enumTypeName}"`
      );
      return {
        errorMessages:
          typeof actualValue === "string" && !!schemaEnum[actualValue]
            ? []
            : [errorMessage],
        missingKeyErrorMessages: [],
      };
    }

    case "ListValueType": {
      if (!Array.isArray(actualValue)) {
        return { errorMessages: [errorMessage], missingKeyErrorMessages: [] };
      }

      const errorMessages: string[] = [];

      actualValue.forEach((item, index) => {
        const itemResult = getValueErrorMessages(
          schema,
          expectedValueType.itemValueType,
          item,
          variableDefinitions,
          [...path, `[${index}]`]
        );
        errorMessages.push(
          ...itemResult.errorMessages,
          // We treat missing key errors in list children as standard errors
          ...itemResult.missingKeyErrorMessages
        );
      });

      return { errorMessages, missingKeyErrorMessages: [] };
    }

    case "ObjectValueType": {
      const schemaObject = nullThrows(
        schema.objects[expectedValueType.objectTypeName],
        `No schema object "${expectedValueType.objectTypeName}"`
      );

      if (
        !actualValue ||
        typeof actualValue !== "object" ||
        Array.isArray(actualValue)
      ) {
        return { errorMessages: [errorMessage], missingKeyErrorMessages: [] };
      }

      const errorMessages: string[] = [];
      const missingKeyErrorMessages: string[] = [];

      Object.entries(schemaObject.fields).forEach(([fieldName, field]) => {
        const fieldValueType = field.valueType;
        const fieldPath = [...path, fieldName];
        const fieldValue = actualValue[fieldName];

        if (typeof fieldValue === "undefined") {
          missingKeyErrorMessages.push(
            `Missing field argument "${fieldPath.join(
              "."
            )}" with expected type "${valueTypeToString(fieldValueType)}".`
          );
          return;
        }

        const fieldResult = getValueErrorMessages(
          schema,
          fieldValueType,
          fieldValue,
          variableDefinitions,
          fieldPath
        );
        errorMessages.push(...fieldResult.errorMessages);
        missingKeyErrorMessages.push(...fieldResult.missingKeyErrorMessages);
      });

      Object.keys(actualValue).forEach((fieldName) => {
        if (!schemaObject.fields[fieldName]) {
          errorMessages.push(
            `Extra field argument "${[...path, fieldName].join(".")}".`
          );
        }
      });

      return { errorMessages, missingKeyErrorMessages };
    }

    case "VoidValueType":
    case "RegexValueType":
    case "UnionValueType":
    case "FunctionValueType":
      throw new Error(`unexpected value type: ${expectedValueType.type}`);

    default: {
      const neverValueType: never = expectedValueType;
      throw new Error(`unexpected value type: ${neverValueType}`);
    }
  }
}

function getNestedObjectOrUnionValueType(
  valueType: ValueType
): ObjectValueType | UnionValueType | null {
  if (
    valueType.type === "ObjectValueType" ||
    valueType.type === "UnionValueType"
  ) {
    return valueType;
  }
  if (valueType.type === "ListValueType") {
    return getNestedObjectOrUnionValueType(valueType.itemValueType);
  }
  return null;
}

function validateUnionAndMember(
  schema: Schema,
  unionValueType: UnionValueType,
  memberTypeName: string
): void {
  const schemaUnion = nullThrows(
    schema.unions[unionValueType.unionTypeName]?.variants,
    `No union type "${unionValueType.unionTypeName}".`
  );
  if (!schemaUnion[memberTypeName]) {
    throw new Error(
      `Union type "${unionValueType.unionTypeName}" has no member "${memberTypeName}".`
    );
  }
}

function getVariableDefinitions(
  graphqlSchema: GraphQLSchema,
  graphqlVariableDefinitions: readonly VariableDefinitionNode[]
): {
  variableDefinitions: VariableDefinitions;
} {
  const variableDefinitions: VariableDefinitions = {};

  graphqlVariableDefinitions.forEach((variableDefinition) => {
    const variableName = variableDefinition.variable.name.value;

    const graphqlType = nullThrows(
      getGraphqlTypeFromAstType(graphqlSchema, variableDefinition.type),
      `Variable "${variableName}" has invalid GraphQL type.`
    );
    if (!isInputType(graphqlType)) {
      throw new Error(
        `GraphQL type for variable "${variableName}" is not an input type.`
      );
    }
    if (!isValidGraphqlInputType(graphqlType)) {
      throw new Error(
        `Invalid type for variable "${variableName}". Variable types must be non-nullable scalars, enums or input object types.`
      );
    }
    const variableValueType = getValueTypeFromGraphqlType(graphqlType);
    variableDefinitions[variableName] = {
      valueType: variableValueType,
      defaultValue: variableDefinition.defaultValue
        ? getDefaultVariableValue(variableDefinition.defaultValue)
        : undefined,
    };
  });

  return { variableDefinitions };
}

function getDefaultVariableValue(defaultValue: ConstValueNode): Value {
  switch (defaultValue.kind) {
    case Kind.BOOLEAN:
      return defaultValue.value;
    case Kind.STRING:
      return defaultValue.value;
    case Kind.INT:
      return parseInt(defaultValue.value);
    case Kind.FLOAT:
      return parseFloat(defaultValue.value);
    case Kind.ENUM:
      return defaultValue.value;
    case Kind.LIST:
      return defaultValue.values.map((value) => getDefaultVariableValue(value));
    case Kind.OBJECT:
      return Object.fromEntries(
        defaultValue.fields.map(({ name, value }) => [
          name,
          getDefaultVariableValue(value),
        ])
      );
    default:
      throw new Error(
        `Unexpected query default value type: ${defaultValue.kind}`
      );
  }
}

function getGraphqlTypeFromAstType(
  graphqlSchema: GraphQLSchema,
  astType: TypeNode
): GraphQLType | undefined {
  const { kind } = astType;
  switch (kind) {
    case Kind.NAMED_TYPE:
      return typeFromAST(graphqlSchema, astType);
    case Kind.LIST_TYPE:
      return typeFromAST(graphqlSchema, astType);
    case Kind.NON_NULL_TYPE:
      return typeFromAST(graphqlSchema, astType);
    default: {
      const neverKind: never = kind;
      throw new Error(`Unexpected type kind: ${neverKind}`);
    }
  }
}
