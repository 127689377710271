import {
  getBooleanExpression,
  getFloatExpression,
  getIntExpression,
  getNoOpExpression,
  getStringExpression,
  getEmptyLogs,
  ApplicationExpression,
  ArithmeticExpression,
  EnumExpression,
  EnumSwitchExpression,
  Expression,
  FunctionExpression,
  GetFieldExpression,
  GetUrlQueryParameterExpression,
  ObjectExpression,
  ObjectValueType,
  RegexExpression,
  RoundNumberExpression,
  SplitExpression,
  StringConcatExpression,
  StringifyNumberExpression,
  SwitchExpression,
  UpdateObjectExpression,
  ValueType,
  VariableExpression,
  Schema,
  uniqueId,
  getBooleanIfExpression,
} from "@hypertune/sdk/src/shared";
import {
  otherExpressionOptionsGroupLabel,
  queryRootArgsTypeName,
  variableExpressionOptionsGroupLabel,
} from "../constants";
import getConstraintFromValueType from "./constraint/getConstraintFromValueType";
import getFieldPathToValueType from "./getFieldPathToValueType";
import getListExpression from "./getListExpression";
import getNewVariables from "./getNewVariables";
import getParameters from "./getParameters";
import getValueTypeFromConstraint from "./constraint/getValueTypeFromConstraint";
import isValueTypeCompatible from "./constraint/isValueTypeCompatible";
import getDefaultExpression from "./getDefaultExpression";
import getIfExpression from "./getIfExpression";
import isPrimitiveExpression from "./isPrimitiveExpression";
import getLogEventExpression from "./getLogEventExpression";
import {
  InnerValueTypeConstraint,
  ListValueTypeConstraint,
  ValueTypeConstraint,
  VariableMap,
} from "./types";
import valueTypeToString from "../schema/valueTypeToString";
import { rootContextTypeNameFromSchema } from "../schema/schemaOperationsObject";
import getContextExpression from "./getContextExpression";

export default function getExpressionOptionGroups(
  schema: Schema,
  variables: VariableMap,
  valueTypeConstraint: ValueTypeConstraint,
  defaultsOptions?: BaseExpressionForValueTypeOptions
): { label: string; options: Expression[] }[] {
  if (
    valueTypeConstraint.type === "ListValueTypeConstraint" &&
    getItemConstraint(valueTypeConstraint).type === "AnyValueTypeConstraint"
  ) {
    return [];
  }

  const options = getExpressionOptions(
    schema,
    variables,
    valueTypeConstraint,
    defaultsOptions
  ).filter((expression) => {
    // Simplify
    if (
      valueTypeConstraint.type === "AnyValueTypeConstraint" &&
      expression.valueType.type === "ObjectValueType"
    ) {
      return false;
    }
    return true;
  });

  const variableOptions = options.filter(isVariableOption);
  const otherOptions = options.filter((option) => !isVariableOption(option));

  const result: { label: string; options: Expression[] }[] = [];
  if (variableOptions.length > 0) {
    result.push({
      label: variableExpressionOptionsGroupLabel,
      options: variableOptions,
    });
  }
  if (otherOptions.length > 0) {
    result.push({
      label: otherExpressionOptionsGroupLabel,
      options: otherOptions,
    });
  }
  return result;
}

function getItemConstraint(
  listConstraint: ListValueTypeConstraint
): ValueTypeConstraint {
  return listConstraint.itemValueTypeConstraint.type ===
    "ListValueTypeConstraint"
    ? getItemConstraint(listConstraint.itemValueTypeConstraint)
    : listConstraint.itemValueTypeConstraint;
}

function isVariableOption(expression: Expression): boolean {
  return (
    expression.type === "VariableExpression" ||
    expression.type === "GetFieldExpression" ||
    expression.type === "ApplicationExpression"
  );
}

function getExpressionOptions(
  schema: Schema,
  variables: VariableMap,
  valueTypeConstraint: ValueTypeConstraint,
  defaultsOptions?: BaseExpressionForValueTypeOptions
): Expression[] {
  if (valueTypeConstraint.type === "ErrorValueTypeConstraint") {
    return [];
  }

  const variableExpressionOptions = getVariableExpressionOptions(
    schema,
    variables,
    valueTypeConstraint
  );

  const valueType = getValueTypeFromConstraint(valueTypeConstraint);
  if (valueType) {
    return [
      ...variableExpressionOptions,
      ...getExpressionOptionsForValueType(
        schema,
        variables,
        valueType,
        defaultsOptions
      ),
    ];
  }

  return variableExpressionOptions;
}

function getVariableExpressionOptions(
  schema: Schema,
  variables: VariableMap,
  valueTypeConstraint: InnerValueTypeConstraint
): Expression[] {
  const baseExpressionOptions: Expression[] = Object.keys(variables).map(
    (variableId) => {
      const variable = variables[variableId];
      const variableExpression: VariableExpression = {
        id: uniqueId(),
        logs: getEmptyLogs(),
        type: "VariableExpression",
        valueType: variable.valueType,
        variableId,
      };
      return variableExpression;
    }
  );

  return [
    ...baseExpressionOptions,
    ...expandVariableExpressionOptions(
      schema,
      baseExpressionOptions,
      0,
      5,
      false
    ),
    // ...(valueTypeConstraint.type === "ListValueTypeConstraint"
    //   ? getVariableExpressionOptions(
    //       schema,
    //       variables,
    //       valueTypeConstraint.itemValueTypeConstraint
    //     ).map((item) => {
    //       const listExpression: ListExpression = {
    //         id: uniqueId(),
    //         logs: getEmptyLogs(),
    //         type: "ListExpression",
    //         valueType: {
    //           type: "ListValueType",
    //           itemValueType: item.valueType,
    //         },
    //         items: [item],
    //       };
    //       return listExpression;
    //     })
    //   : []),
  ].filter((expression) =>
    isValueTypeCompatible(schema, valueTypeConstraint, expression.valueType)
  );
}

// eslint-disable-next-line max-params
function expandVariableExpressionOptions(
  schema: Schema,
  baseExpressionOptions: Expression[],
  depth: number,
  maxDepth: number,
  skipGetFieldExpressionOptions: boolean
): Expression[] {
  if (depth >= maxDepth) {
    return [];
  }

  const getFieldExpressionOptions = skipGetFieldExpressionOptions
    ? []
    : baseExpressionOptions.flatMap((expression) => {
        if (expression.valueType.type !== "ObjectValueType") {
          return [];
        }
        const fieldPathToValueType = getFieldPathToValueType(
          schema,
          expression.valueType,
          {}
        );
        return Object.keys(fieldPathToValueType).map((fieldPath) => {
          const fieldValueType = fieldPathToValueType[fieldPath];
          const getFieldExpression: GetFieldExpression = {
            id: uniqueId(),
            logs: getEmptyLogs(),
            type: "GetFieldExpression",
            valueType: fieldValueType,
            object: expression,
            fieldPath,
          };
          return getFieldExpression;
        });
      });

  const applicationExpressionOptions = baseExpressionOptions.flatMap(
    (expression) => {
      if (expression.valueType.type !== "FunctionValueType") {
        return [];
      }
      const applicationExpression: ApplicationExpression = {
        id: uniqueId(),
        logs: getEmptyLogs(),
        type: "ApplicationExpression",
        valueType: expression.valueType.returnValueType,
        function: expression,
        arguments: new Array(
          expression.valueType.parameterValueTypes.length
        ).fill(null),
      };
      return applicationExpression;
    }
  );

  return [
    ...getFieldExpressionOptions,
    ...applicationExpressionOptions,
    ...expandVariableExpressionOptions(
      schema,
      getFieldExpressionOptions,
      depth + 1,
      maxDepth,
      true
    ),
    ...expandVariableExpressionOptions(
      schema,
      applicationExpressionOptions,
      depth + 1,
      maxDepth,
      false
    ),
  ];
}

function getExpressionOptionsForValueType(
  schema: Schema,
  variables: VariableMap,
  valueType: ValueType,
  options?: BaseExpressionForValueTypeOptions
): Expression[] {
  if (valueType.type === "FunctionValueType") {
    const parameters = getParameters(variables, valueType.parameterValueTypes);
    return getExpressionOptions(
      schema,
      {
        ...variables,
        ...getNewVariables(parameters, valueType.parameterValueTypes),
      },
      getConstraintFromValueType(valueType.returnValueType)
    ).map((body) => {
      const expression: FunctionExpression = {
        id: uniqueId(),
        logs: getEmptyLogs(),
        type: "FunctionExpression",
        valueType,
        parameters,
        body,
      };
      return expression;
    });
  }

  const expressionOptions: Expression[] = getBaseExpressionOptionsForValueType(
    schema,
    variables,
    valueType,
    new Set(),
    options
  );

  if (expressionOptions.length === 0) {
    throw new Error(
      `No base expression options for value type ${valueTypeToString(
        valueType
      )}`
    );
  }

  expressionOptions.push(getIfExpression(valueType));

  // Only add switch option if there are options for the control
  if (
    getVariableExpressionOptions(schema, variables, {
      type: "AnyValueTypeConstraint",
    }).length > 0
  ) {
    const switchExpression: SwitchExpression = {
      id: uniqueId(),
      logs: getEmptyLogs(),
      type: "SwitchExpression",
      valueType,
      control: null,
      cases: [],
      default: null,
    };
    expressionOptions.push(switchExpression);
  }

  // Only add enum switch option if there are options for the control
  if (
    getVariableExpressionOptions(schema, variables, {
      type: "AnyEnumValueTypeConstraint",
    }).length > 0
  ) {
    const enumSwitchExpression: EnumSwitchExpression = {
      id: uniqueId(),
      logs: getEmptyLogs(),
      type: "EnumSwitchExpression",
      valueType,
      control: null,
      cases: {},
    };
    expressionOptions.push(enumSwitchExpression);
  }

  const splitExpression: SplitExpression = {
    id: uniqueId(),
    logs: getEmptyLogs(),
    type: "SplitExpression",
    valueType,
    splitId: null,
    dimensionId: null,
    expose: getBooleanExpression(true),
    unitId: null,
    dimensionMapping: {
      type: "discrete",
      cases: {},
    },
    featuresMapping: {},
    eventObjectTypeName: null,
    eventPayload: null,
  };
  expressionOptions.push(splitExpression);

  return expressionOptions;
}

export type BaseExpressionForValueTypeOptions = { useIfBoolean?: boolean };

// eslint-disable-next-line max-params
export function getBaseExpressionOptionsForValueType(
  schema: Schema,
  variables: VariableMap,
  valueType: ValueType,
  seenObjectTypeNames: Set<string>,
  options?: BaseExpressionForValueTypeOptions
): Expression[] {
  switch (valueType.type) {
    case "VoidValueType": {
      return [getLogEventExpression(null, null), getNoOpExpression()];
    }

    case "BooleanValueType": {
      const booleanExpression =
        options && options.useIfBoolean
          ? getBooleanIfExpression(false)
          : getBooleanExpression(false);
      return [booleanExpression];
    }

    case "IntValueType": {
      const intExpression = getIntExpression(0);
      const arithmeticExpression: ArithmeticExpression = {
        id: uniqueId(),
        logs: getEmptyLogs(),
        type: "ArithmeticExpression",
        valueType,
        operator: "+",
        a: null,
        b: null,
      };
      const roundNumberExpression = getRoundNumberExpression();
      return [intExpression, arithmeticExpression, roundNumberExpression];
    }

    case "FloatValueType": {
      const floatExpression = getFloatExpression(0);
      const arithmeticExpression: ArithmeticExpression = {
        id: uniqueId(),
        logs: getEmptyLogs(),
        type: "ArithmeticExpression",
        valueType,
        operator: "+",
        a: null,
        b: null,
      };
      const roundNumberExpression = getRoundNumberExpression();
      return [floatExpression, arithmeticExpression, roundNumberExpression];
    }

    case "StringValueType": {
      const stringExpression = getStringExpression("");
      const stringConcatExpression: StringConcatExpression = {
        id: uniqueId(),
        logs: getEmptyLogs(),
        type: "StringConcatExpression",
        valueType,
        strings: getListExpression({
          type: "ListValueType",
          itemValueType: valueType,
        }),
      };
      const stringifyNumberExpression: StringifyNumberExpression = {
        id: uniqueId(),
        logs: getEmptyLogs(),
        type: "StringifyNumberExpression",
        valueType,
        number: null,
      };
      const getUrlQueryParameterExpression: GetUrlQueryParameterExpression = {
        id: uniqueId(),
        logs: getEmptyLogs(),
        type: "GetUrlQueryParameterExpression",
        valueType,
        url: null,
        queryParameterName: null,
      };
      return [
        stringExpression,
        stringConcatExpression,
        stringifyNumberExpression,
        getUrlQueryParameterExpression,
      ];
    }

    case "RegexValueType": {
      const expression: RegexExpression = {
        id: uniqueId(),
        logs: getEmptyLogs(),
        type: "RegexExpression",
        valueType: { type: "RegexValueType" },
        value: "",
      };
      return [expression];
    }

    case "EnumValueType": {
      const schemaEnumValues = schema.enums[valueType.enumTypeName]?.values;
      if (!schemaEnumValues || Object.keys(schemaEnumValues).length === 0) {
        return [];
      }
      const expression: EnumExpression = {
        id: uniqueId(),
        logs: getEmptyLogs(),
        type: "EnumExpression",
        valueType,
        value: Object.keys(schemaEnumValues)[0],
      };
      return [expression];
    }

    case "ObjectValueType": {
      const updateObjectExpression: UpdateObjectExpression = {
        id: uniqueId(),
        logs: getEmptyLogs(),
        type: "UpdateObjectExpression",
        valueType,
        object: getObjectExpression(
          schema,
          variables,
          valueType,
          seenObjectTypeNames,
          options
        ),
        updates: {},
      };
      return [
        getObjectExpression(
          schema,
          variables,
          valueType,
          seenObjectTypeNames,
          options
        ),
        updateObjectExpression,
      ];
    }

    case "UnionValueType": {
      const schemaUnionMembers =
        schema.unions[valueType.unionTypeName]?.variants;
      if (!schemaUnionMembers) {
        return [];
      }
      return Object.keys(schemaUnionMembers).map((objectTypeName) => {
        const expression: ObjectExpression = getObjectExpression(
          schema,
          variables,
          { type: "ObjectValueType", objectTypeName },
          seenObjectTypeNames,
          options
        );
        return expression;
      });
    }

    case "ListValueType": {
      const expression = getListExpression(valueType);
      const initialItem = getDefaultExpression(
        schema,
        variables,
        getConstraintFromValueType(valueType.itemValueType),
        seenObjectTypeNames,
        options
      );
      if (isPrimitiveExpression(initialItem)) {
        expression.items.push(initialItem);
      }
      return [expression];
    }

    case "FunctionValueType": {
      const parameters = getParameters(
        variables,
        valueType.parameterValueTypes
      );
      const expression: FunctionExpression = {
        id: uniqueId(),
        logs: getEmptyLogs(),
        type: "FunctionExpression",
        valueType,
        parameters,
        body: getDefaultExpression(
          schema,
          {
            ...variables,
            ...Object.fromEntries(
              parameters.map((parameter, index) => [
                parameter.id,
                {
                  ...parameter,
                  valueType: valueType.parameterValueTypes[index],
                },
              ])
            ),
          },
          getConstraintFromValueType(valueType.returnValueType),
          seenObjectTypeNames,
          options
        ),
      };
      return [expression];
    }

    default: {
      const neverValueType: never = valueType;
      throw new Error(`Unexpected value type: ${neverValueType}`);
    }
  }
}

function getRoundNumberExpression(): RoundNumberExpression {
  const expression: RoundNumberExpression = {
    id: uniqueId(),
    logs: getEmptyLogs(),
    type: "RoundNumberExpression",
    valueType: { type: "IntValueType" },
    number: null,
  };
  return expression;
}

// eslint-disable-next-line max-params
function getObjectExpression(
  schema: Schema,
  variables: VariableMap,
  valueType: ObjectValueType,
  seenObjectTypeNames: Set<string>,
  options?: BaseExpressionForValueTypeOptions
): ObjectExpression {
  const { objectTypeName } = valueType;

  const shouldRecurse = !seenObjectTypeNames.has(objectTypeName);

  seenObjectTypeNames.add(objectTypeName);

  const schemaObjectFields = schema.objects[objectTypeName]?.fields || {};
  const fields = Object.fromEntries(
    Object.entries(schemaObjectFields).map(([fieldName, field]) => {
      if (
        schema.objects[objectTypeName]?.role === "event" &&
        fieldName === "context"
      ) {
        const contextTypeName = rootContextTypeNameFromSchema(schema);
        const rootArgsVariableId = Object.values(variables).find(
          (variable) =>
            variable.valueType.type === "ObjectValueType" &&
            variable.valueType.objectTypeName === queryRootArgsTypeName
        )?.id;

        if (
          contextTypeName &&
          rootArgsVariableId &&
          field.valueType.type === "ObjectValueType" &&
          field.valueType.objectTypeName === contextTypeName
        ) {
          return [
            fieldName,
            getContextExpression(contextTypeName, rootArgsVariableId),
          ];
        }
      }
      return [
        fieldName,
        shouldRecurse
          ? getDefaultExpression(
              schema,
              variables,
              getConstraintFromValueType(field.valueType),
              seenObjectTypeNames,
              options
            )
          : null,
      ];
    })
  );

  seenObjectTypeNames.delete(objectTypeName);

  return {
    id: uniqueId(),
    logs: getEmptyLogs(),
    type: "ObjectExpression",
    valueType,
    objectTypeName,
    fields,
  };
}
