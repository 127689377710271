import React, { useCallback, useMemo, useState } from "react";
import {
  EventTypeMap,
  Expression,
  Schema,
  SplitMap,
  ValueType,
} from "@hypertune/sdk/src/shared";
import {
  SelectedType,
  TypeDefinition,
  TypeOption,
  TypeReferencesMap,
  useSchemaEditorSelectedType,
  useSelectedSchemaEditorMode,
} from "./schemaHooks";
import SchemaEditorSidebar from "./SchemaEditorSidebar";
import SchemaCodeEditor from "./SchemaCodeEditor";
import TypeEditor from "./typeEditor/TypeEditor";

export const typeFilters = [
  "All",
  "Inputs",
  "Objects",
  "Events",
  "Enums",
] as const;
export type TypeFilter = (typeof typeFilters)[number];

export default function SchemaEditor({
  isVisible,
  schema,
  splits,
  eventTypeMap,
  expression,
  readOnlySchemaCode,
  editableSchemaCode,
  errorMessage,
  readOnly,
}: {
  isVisible: boolean;
  schema: Schema;
  splits: SplitMap;
  eventTypeMap: EventTypeMap;
  expression: Expression;
  readOnlySchemaCode: string;
  editableSchemaCode: string;
  errorMessage?: string;
  readOnly: boolean;
}): React.ReactElement {
  const [selectedType, _setSelectedType] = useSchemaEditorSelectedType();
  const [mode, setMode] = useSelectedSchemaEditorMode();
  const [fieldsAndValuesSearchText, setFieldsAndValuesSearchText] =
    useState("");

  const setSelectedType = useCallback(
    (newSelectedType: SelectedType | null) => {
      setFieldsAndValuesSearchText("");
      _setSelectedType(newSelectedType);
    },
    [setFieldsAndValuesSearchText, _setSelectedType]
  );
  const typeReferences = useMemo(
    () => getSchemaTypeReferences(schema),
    [schema]
  );

  return (
    <div
      className={`${isVisible ? "flex" : "hidden"} h-full flex-row items-stretch overflow-hidden`}
    >
      <SchemaEditorSidebar
        readOnly={readOnly}
        schema={schema}
        mode={mode}
        setMode={setMode}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        typeReferences={typeReferences}
      />
      <SchemaCodeEditor
        readOnlySchemaCode={readOnlySchemaCode}
        editableSchemaCode={editableSchemaCode}
        selectedType={selectedType}
        errorMessage={errorMessage}
        readOnly={readOnly}
        isVisible={mode === "code"}
      />
      <TypeEditor
        readOnly={readOnly}
        schema={schema}
        splits={splits}
        eventTypeMap={eventTypeMap}
        expression={expression}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        fieldsAndValuesSearchText={fieldsAndValuesSearchText}
        setFieldsAndValuesSearchText={setFieldsAndValuesSearchText}
        isVisible={mode === "type"}
        typeReferences={typeReferences}
      />
    </div>
  );
}

function getSchemaTypeReferences(schema: Schema): TypeReferencesMap {
  const result: TypeReferencesMap = {};
  function addToResult(typeName: string, def: TypeDefinition): void {
    if (!(typeName in result)) {
      result[typeName] = new Set();
    }
    result[typeName].add(def);
  }
  function addValueTypeReferencesToResult(
    def: TypeDefinition,
    valueType: ValueType
  ): void {
    switch (valueType.type) {
      case "ObjectValueType":
        addToResult(valueType.objectTypeName, def);
        break;
      case "EnumValueType":
        addToResult(valueType.enumTypeName, def);
        break;
      case "UnionValueType":
        addToResult(valueType.unionTypeName, def);
        break;
      case "ListValueType":
        addValueTypeReferencesToResult(def, valueType.itemValueType);
        break;
      case "FunctionValueType":
        valueType.parameterValueTypes.forEach((paramValueType) =>
          addValueTypeReferencesToResult(def, paramValueType)
        );
        addValueTypeReferencesToResult(def, valueType.returnValueType);
        break;
      default:
      // Skip other basic value types
    }
  }
  Object.entries(schema.objects).forEach(([typeName, objectSchema]) => {
    const typeOption: TypeOption =
      objectSchema.role === "output" || objectSchema.role === "args"
        ? "object"
        : objectSchema.role;
    Object.values(objectSchema.fields).forEach((fieldSchema) => {
      addValueTypeReferencesToResult(
        { type: typeOption, name: typeName },
        fieldSchema.valueType
      );
    });
  });
  Object.entries(schema.unions).forEach(([typeName, unionSchema]) => {
    Object.keys(unionSchema.variants).forEach((objectTypeName) => {
      addToResult(objectTypeName, { type: "union", name: typeName });
    });
  });
  return result;
}
