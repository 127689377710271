import { ReactElement, useMemo, useState } from "react";
import {
  Arm,
  Dimension,
  DiscreteDimension,
  Expression,
  Split,
  SplitMap,
  SplitType,
} from "@hypertune/sdk/src/shared";
import { Plus } from "@phosphor-icons/react";
import {
  createArm,
  createDiscreteDimension,
} from "@hypertune/shared-internal/src/expression/split";
import getArmAllocationsSum from "@hypertune/shared-internal/src/expression/getArmAllocationsSum";

import {
  deleteSplitDiscreteDimensionArm,
  removeSplitArmFromExpression,
} from "@hypertune/shared-internal";
import Button from "../../../../components/buttons/Button";
import TypeIcon from "../../../../components/icons/TypeIcon";
import CardGroup, { CardGroupItem } from "../../../../components/CardGroup";
import MutableText from "../../../../components/input/MutableText";
import FloatInput from "../../../../components/input/FloatInput";
import twMerge from "../../../../lib/twMerge";
import DeleteButton from "../../../../components/buttons/DeleteButton";
import Label from "../../../../components/Label";
import { showSchemaNameError } from "../../schema/typeEditor/SchemaNameError";
import NameError from "./NameError";
import { useAppDispatch } from "../../../../app/hooks";
import { setDraftCommitSplitsAndExpression } from "../../projectSlice";
import ModalWithContent from "../../../../components/ModalWithContent";
import { useHypertune } from "../../../../generated/hypertune.react";
import { boldFontWeight } from "../../../../lib/constants";

export default function SelectedSplitEditor({
  split,
  splits,
  expression,
  setSplit,
  readOnly,
}: {
  split: Split;
  splits: SplitMap;
  expression: Expression;
  setSplit: (newSplit: Split) => void;
  readOnly: boolean;
}): ReactElement {
  const content = useHypertune().content().splits();
  const dispatch = useAppDispatch();
  const [armToDelete, setArmToDelete] = useState<{
    dimension: DiscreteDimension;
    armId: string;
  } | null>(null);
  const [dimensionIdToDelete, setDimensionIdToDelete] = useState<string | null>(
    null
  );

  const sortedDimensions = Object.values(split.dimensions).sort(
    (a, b) => a.index - b.index
  );

  function addDimension(): void {
    const newDimension = createDiscreteDimension(
      split.id,
      Object.keys(split.dimensions).length
    );

    setSplit({
      ...split,
      dimensions: {
        ...split.dimensions,
        [newDimension.id]: {
          ...newDimension,
        },
      },
    });
  }

  function setDimension(updatedDimension: Dimension): void {
    setSplit({
      ...split,
      dimensions: {
        ...split.dimensions,
        [updatedDimension.id]: updatedDimension,
      },
    });
  }

  function addArm(dimensionId: string): void {
    const currentDimension = split.dimensions[dimensionId];

    if (currentDimension.type !== "discrete") {
      return;
    }

    const armCount = Object.values(currentDimension.arms).length;
    const newArm = createArm(dimensionId, armCount, `Arm ${armCount + 1}`);

    setSplit({
      ...split,
      dimensions: {
        ...split.dimensions,
        [dimensionId]: {
          ...currentDimension,
          arms: {
            ...currentDimension.arms,
            [newArm.id]: newArm,
          },
        },
      },
    });
  }

  function setArm(updatedArm: Arm): void {
    const dimension = split.dimensions[updatedArm.dimensionId];

    if (dimension.type !== "discrete") {
      return;
    }

    setSplit({
      ...split,
      dimensions: {
        ...split.dimensions,
        [updatedArm.dimensionId]: {
          ...dimension,
          arms: {
            ...dimension.arms,
            [updatedArm.id]: updatedArm,
          },
        },
      },
    });
  }

  const singleDiscreteDimension =
    sortedDimensions.length === 1 && sortedDimensions[0].type === "discrete"
      ? sortedDimensions[0]
      : null;

  return (
    <div className="grid gap-3">
      {singleDiscreteDimension ? (
        <>
          <Label className="whitespace-nowrap" type="title1">
            Arms
          </Label>
          <CardGroup
            layout="grid"
            className="grid-cols-1 gap-3 pb-0"
            cardLayout="horizontal-with-icon"
            cards={[
              ...ArmsContent({
                split,
                dimension: singleDiscreteDimension,
                setArm,
                deleteArm: (armId) =>
                  setArmToDelete({ dimension: singleDiscreteDimension, armId }),
                readOnly,
              }),
              ...(split.type === "test"
                ? [UnallocatedContent({ dimension: singleDiscreteDimension })]
                : []),
            ]}
          />
          {!readOnly && (
            <Button
              className="justify-self-start"
              disabled={readOnly}
              intent="neutral"
              weight="outlined"
              size="large"
              icon={<Plus weight="regular" />}
              text="Add arm"
              onClick={() => {
                addArm(singleDiscreteDimension.id);
              }}
            />
          )}
        </>
      ) : null}
      {sortedDimensions.length > 1 ? (
        <>
          <Label className="whitespace-nowrap" type="title1">
            Dimensions
          </Label>
          {sortedDimensions.map((dimension, index) => {
            if (dimension.type !== "discrete") {
              return null;
            }
            return (
              <div className={twMerge("grid gap-3", index > 0 ? "mt-3" : null)}>
                <CardGroup
                  layout="list"
                  cardLayout="horizontal-with-icon"
                  cards={[
                    {
                      key: "dimension",
                      className: "flex justify-between py-2 pl-6",
                      children: (
                        <>
                          <MutableText
                            className="font-semibold"
                            text={dimension.name}
                            setText={(newValue: string) =>
                              Promise.resolve(
                                setDimension({ ...dimension, name: newValue })
                              )
                            }
                            showPencil={false}
                            hasError={(newName) =>
                              NameError({
                                noun: "Dimension",
                                idMap: split.dimensions,
                                currentName: dimension.name,
                                newName,
                              })
                            }
                            showError={showSchemaNameError}
                            readOnly={readOnly}
                          />
                          {!readOnly && sortedDimensions.length > 1 ? (
                            <DeleteButton
                              onClick={() =>
                                setDimensionIdToDelete(dimension.id)
                              }
                            />
                          ) : null}
                        </>
                      ),
                    },
                    ...ArmsContent({
                      split,
                      dimension,
                      setArm,
                      deleteArm: (armId) =>
                        setArmToDelete({ dimension, armId }),
                      readOnly,
                    }),
                    ...(split.type === "test"
                      ? [UnallocatedContent({ dimension })]
                      : []),
                  ]}
                />
                {!readOnly && (
                  <Button
                    className="justify-self-start"
                    disabled={readOnly}
                    intent="neutral"
                    weight="outlined"
                    size="large"
                    icon={<Plus weight="regular" />}
                    text="Add arm"
                    onClick={() => {
                      addArm(dimension.id);
                    }}
                  />
                )}
              </div>
            );
          })}
        </>
      ) : null}
      {!readOnly && (
        <Button
          className="justify-self-start"
          disabled={readOnly}
          intent="neutral"
          weight="outlined"
          size="large"
          icon={<Plus weight="regular" />}
          text="Add dimension"
          onClick={addDimension}
        />
      )}
      {armToDelete && (
        <ModalWithContent
          content={content.deleteArmModal().get()}
          onSave={() => {
            const { dimension, armId } = armToDelete;
            dispatch(
              setDraftCommitSplitsAndExpression({
                splits: deleteSplitDiscreteDimensionArm(
                  splits,
                  split,
                  dimension,
                  armId
                ),
                expression: removeSplitArmFromExpression(
                  expression,
                  split.id,
                  dimension.id,
                  armId
                ),
              })
            );
            setArmToDelete(null);
          }}
          onClose={() => setArmToDelete(null)}
        />
      )}
      {dimensionIdToDelete && (
        <ModalWithContent
          content={content.deleteDimensionModal().get()}
          onSave={() => {
            const { [dimensionIdToDelete]: _, ...newDimensions } =
              split.dimensions;

            setSplit({
              ...split,
              dimensions: newDimensions,
            });
            setDimensionIdToDelete(null);
          }}
          onClose={() => setDimensionIdToDelete(null)}
        />
      )}
    </div>
  );
}

function UnallocatedContent({
  dimension,
}: {
  dimension: DiscreteDimension;
}): CardGroupItem {
  return {
    key: "unallocated",
    className:
      "flex bg-base-grey-2-light border-grey-border py-2 pl-6 pr-[57px] min-w-[252px]",
    children: (
      <Label
        type="title4"
        className="flex w-full justify-between text-base-grey-1-dark"
      >
        <span>Unallocated </span>
        <div>
          <span className="pr-[14px]">
            {(Math.max(1 - getArmAllocationsSum(dimension), 0) * 100).toFixed(
              2
            )}
          </span>
          <span>%</span>
        </div>
      </Label>
    ),
  };
}

function ArmsContent({
  split,
  dimension,
  setArm,
  deleteArm,
  readOnly,
}: {
  split: Split;
  dimension: DiscreteDimension;
  setArm: (newArm: Arm) => void;
  deleteArm: (armId: string) => void;
  readOnly: boolean;
}): CardGroupItem[] {
  return Object.values(dimension.arms).map((arm) => ({
    key: arm.id,
    className: "h-[74px] items-center gap-3 pl-6 min-w-[252px]",
    children: (
      <>
        <TypeIcon type="arm" size="large" />
        <ArmControl
          splitType={split.type}
          arm={arm}
          setArm={setArm}
          deleteArm={() => deleteArm(arm.id)}
          readOnly={readOnly}
          dimension={dimension}
        />
      </>
    ),
  }));
}

function ArmControl({
  className,
  splitType,
  arm,
  setArm,
  deleteArm,
  readOnly,
  dimension,
}: {
  className?: string;
  splitType: SplitType;
  arm: Arm;
  setArm: (newArm: Arm) => void;
  deleteArm: () => void;
  readOnly: boolean;
  dimension: DiscreteDimension;
}): React.ReactElement {
  const dimensionAllocation = useMemo(
    () => getArmAllocationsSum(dimension),
    [dimension]
  );
  const hasError = arm.allocation > 0 && dimensionAllocation > 1;

  return (
    <div
      className={twMerge(
        "grid auto-cols-auto grid-flow-col grid-cols-[1fr] items-center gap-2",
        className
      )}
    >
      <div className="-ml-[2px] flex h-full max-w-full flex-shrink flex-row items-center overflow-hidden px-[2px]">
        <MutableText
          text={arm.name}
          readOnly={readOnly}
          setText={(newValue: string) =>
            Promise.resolve(setArm({ ...arm, name: newValue }))
          }
          showPencil={false}
          hasError={(newName) =>
            NameError({
              noun: "Arm",
              idMap: dimension.arms,
              currentName: arm.name,
              newName,
            })
          }
          showError={showSchemaNameError}
          style={{
            lineHeight: "16px",
            fontWeight: boldFontWeight,
            maxWidth: "100%",
          }}
          minWidth={0}
          className="max-w-full overflow-x-clip text-ellipsis whitespace-nowrap"
        />
      </div>
      {splitType === "test" ? (
        <>
          <FloatInput
            style={{ width: 50 }}
            inputClassName="w-[34px] text-right"
            initialValue={arm.allocation * 100}
            min={0}
            max={100}
            readOnly={readOnly}
            onChange={(newValue: number) =>
              setArm({
                ...arm,
                allocation: newValue / 100,
              })
            }
            intent={hasError ? "danger" : undefined}
          />
          <div>%</div>
        </>
      ) : null}
      {readOnly || Object.keys(dimension.arms).length === 1 ? (
        <div className="w-8" />
      ) : (
        <DeleteButton onClick={deleteArm} />
      )}
    </div>
  );
}
