import { MouseEventHandler, ReactElement, ReactNode } from "react";
import Skeleton from "react-loading-skeleton";
import twMerge from "../lib/twMerge";

const layoutClasses = {
  none: "",
  horizontal: "flex flex-row justify-between items-center gap-2",
  "horizontal-with-icon":
    "grid auto-cols-auto grid-flow-col grid-cols-[auto,1fr] items-center gap-3",
};
export type CardLayout = keyof typeof layoutClasses;

export type BaseCardProps = {
  className?: string;
  children: ReactNode;
  isLoading?: boolean;
  isSelected?: boolean;
  isListItem?: boolean;
  hasError?: boolean;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export default function Card({
  className,
  children,
  layout,
  isListItem,
  isLoading,
  isSelected,
  hasError,
  onMouseDown,
  onClick,
}: BaseCardProps & {
  layout: CardLayout;
}): ReactElement {
  return (
    <div
      className={twMerge(
        "group rounded-lg border border-bd-default bg-white p-4 shadow-sm",
        layout && layoutClasses[layout],
        isListItem &&
          "rounded-none border-0 shadow-none first:rounded-t-lg last:rounded-b-lg",
        isSelected && "border-base-blue shadow-inputs",
        hasError && "border-intent-danger shadow-inputs-sm-error",
        (onClick || onMouseDown) && "cursor-pointer hover:bg-bg-light",
        className
      )}
      onMouseDown={onMouseDown}
      onClick={onClick}
    >
      {layout && isLoading ? <LoadingSkeleton layout={layout} /> : children}
    </div>
  );
}

function LoadingSkeleton({
  layout,
}: {
  layout: CardLayout;
}): ReactElement | null {
  switch (layout) {
    case "horizontal-with-icon": {
      return (
        <>
          <div className="h-[40px] w-[40px] animate-pulse rounded-md bg-base-grey-1-medium" />
          <div className="flex h-[41px] w-full flex-col">
            <div className=" w-7/12">
              <Skeleton className="rounded-lg" height={16} />
            </div>
            <div className=" w-10/12">
              <Skeleton className="rounded-lg" height={9} />
            </div>
          </div>
        </>
      );
    }
    case "horizontal": {
      return (
        <>
          <Skeleton
            className="rounded-lg leading-none"
            height={16}
            width={100}
          />
          <Skeleton
            className="rounded-lg leading-none"
            height={12}
            width={80}
          />
        </>
      );
    }
    case "none": {
      return null;
    }
    default: {
      const neverLayout: never = layout;
      throw new Error(`Card with unexpected layout: ${neverLayout}`);
    }
  }
}
