import { Schema, SplitMap } from "@hypertune/sdk/src/shared";
import toStartCase from "@hypertune/shared-internal/src/toStartCase";
import React from "react";
import { plusSymbol } from "../../lib/constants";
import Dropdown, { LabeledOption } from "../../components/Dropdown";
import { useAppDispatch } from "../../app/hooks";
import { setNewSplitModalState, setNewTypeModalState } from "./projectSlice";
import { Intent } from "../../components/intent";
import { useSplitEditorSetSelectedSplitId } from "./split/splitHooks";
import { useSchemaEditorSetSelectedType } from "./schema/schemaHooks";
import TypeIcon from "../../components/icons/TypeIcon";
import GoToArrowButton from "../../components/buttons/GoToArrowButton";

export default function IdSelector({
  context,
  source,
  selectedId,
  setSelectedId,
  readOnly,
  showGoToArrow,
  allowEmpty,
  hideNewOption,
  style,
  intent = "neutral",
  variant = "normal",
  dropDownHeight = 30,
}: {
  context: { splits: SplitMap; schema: Schema };
  source: "splits" | "eventTypes";
  selectedId: string | null;
  setSelectedId: (newSelectedId: string | null) => void;
  readOnly: boolean;
  showGoToArrow?: boolean;
  allowEmpty?: boolean;
  hideNewOption?: boolean;
  style?: React.CSSProperties;
  intent?: Intent;
  variant?: "normal" | "with-error";
  dropDownHeight?: number;
}): React.ReactElement {
  const dispatch = useAppDispatch();
  const setSelectedSplitId = useSplitEditorSetSelectedSplitId({
    setSplitView: true,
  });
  const setSelectedType = useSchemaEditorSetSelectedType({
    setSchemaView: true,
  });

  const noun = source === "splits" ? "split" : "event type";
  const label = toStartCase(noun);
  const map: IdMap =
    source === "splits" ? context.splits : getEventIdMap(context.schema);

  function getLabeledOption(
    id: string,
    icon?: React.ReactNode
  ): LabeledOption<string> {
    const entry = map[id];
    return {
      value: id,
      label: entry
        ? source === "splits"
          ? entry.name
          : toStartCase(entry.name)
        : `(Invalid) ${label} ${id}`,
      icon,
    };
  }

  const createOptionValue = "--new";
  const createOption: LabeledOption<string> = {
    value: createOptionValue,
    label: `${plusSymbol} New ${label}`,
  };
  const emptyOptionValue = "--empty";
  const emptyOption: LabeledOption<string> = {
    value: emptyOptionValue,
    label: "None",
  };

  const options = [
    ...(source === "splits"
      ? Object.values(context.splits)
          .filter((split) => !split.archived)
          .map((split) =>
            getLabeledOption(
              split.id,
              <TypeIcon type={split.type} size="small" />
            )
          )
      : Object.keys(map).map((eventTypeName) =>
          getLabeledOption(eventTypeName)
        )),
    ...(readOnly || !allowEmpty ? [] : [emptyOption]),
    ...(readOnly || hideNewOption ? [] : [createOption]),
  ];
  const emptyValue = allowEmpty ? emptyOption : null;
  const value = selectedId
    ? options.find((option) => option.value === selectedId) ?? emptyValue
    : emptyValue;
  const showArrow = showGoToArrow && value;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        position: "relative",
        ...(style || {}),
      }}
    >
      <Dropdown<string | null>
        disabled={readOnly}
        style={{ flexGrow: 1 }}
        intent={
          variant === "with-error" && !selectedId && !allowEmpty
            ? "danger"
            : intent
        }
        height={dropDownHeight}
        indicatorsContainerWidthOffset={showArrow ? 28 : undefined}
        options={{ type: "options", options }}
        value={value}
        onChange={(option) => {
          if (option && option.value === createOptionValue) {
            if (source === "splits") {
              dispatch(
                setNewSplitModalState({
                  onCreate: (_, newSplitId) => {
                    setSelectedId(newSplitId);
                  },
                })
              );
            } else {
              dispatch(
                setNewTypeModalState({
                  defaultTypeOption: "event",
                  onCreate: (typeOption, newEventName) => {
                    if (typeOption === "event") {
                      setSelectedId(newEventName);
                    }
                  },
                })
              );
            }
            return;
          }
          if (option && option.value === emptyOptionValue) {
            setSelectedId(null);
            return;
          }
          setSelectedId(option ? option.value : null);
        }}
        placeholder={`Select ${noun}...`}
        noOptionsMessage={`No ${noun}s found`}
      />
      {showArrow && (
        <GoToArrowButton
          onClick={() => {
            if (!selectedId) {
              return;
            }
            if (source === "splits") {
              setSelectedSplitId(selectedId);
              return;
            }
            setSelectedType({
              type: "event",
              name: selectedId,
              selectedChildName: null,
            });
          }}
        />
      )}
    </div>
  );
}

type IdMap = { [id: string]: { id: string; name: string } };

function getEventIdMap(schema: Schema): IdMap {
  return Object.fromEntries(
    Object.entries(schema.objects)
      .filter(([, objectSchema]) => objectSchema.role === "event")
      .map(([name]) => [name, { id: name, name }])
  );
}
