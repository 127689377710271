import React from "react";
import { fieldPathSeparator } from "@hypertune/sdk/src/shared";
import Label from "./Label";
import MutableText from "./input/MutableText";
import { boldFontWeight } from "../lib/constants";
import Button from "./buttons/Button";
import RightSidebar from "./icons/RightSidebar";
import { useHypertune } from "../generated/hypertune.react";
import Separator from "./icons/Separator";

export default function RenameTopBar<ErrorT>({
  icon,
  label,
  entityName,
  rename,
  hasError,
  showError,
  toggleSidebar,
  disableConfirmDialog,
  actions,
  children,
  errorMessage,
}: {
  icon: React.ReactNode;
  label: string;
  entityName: string;
  rename?: (newName: string) => Promise<void>;
  hasError?: (newText: string) => ErrorT;
  showError?: (error: ErrorT) => React.ReactElement | null;
  toggleSidebar?: () => void;
  disableConfirmDialog?: boolean;
  actions?: React.ReactNode;
  errorMessage?: React.ReactNode;
  children?: React.ReactNode;
}): React.ReactElement | null {
  const content = useHypertune().content();

  return (
    <div className="flex w-full flex-col gap-5 border-b border-bd-darker bg-white px-6 py-4">
      <div className="flex w-full flex-row">
        <div className="flex max-w-full flex-shrink flex-row items-center gap-3 overflow-hidden">
          {icon}
          {rename ? (
            <MutableText
              text={label}
              setText={rename}
              showPencil={false}
              style={{
                lineHeight: "16px",
                fontSize: "15px",
                fontWeight: boldFontWeight,
                maxWidth: "100%",
              }}
              minWidth={0}
              className="max-w-full overflow-x-clip text-ellipsis whitespace-nowrap"
              confirmModalContent={
                !disableConfirmDialog
                  ? content.schema().renameConfirmation().get()
                  : undefined
              }
              confirmModalVariables={
                !disableConfirmDialog ? { entityName } : undefined
              }
              hasError={hasError}
              showError={showError}
            />
          ) : (
            <ReadOnlyLabel label={label} />
          )}
          {errorMessage}
        </div>
        {(toggleSidebar || actions) && (
          <div className="ml-auto flex h-full flex-row gap-3">
            <div className="flex h-full flex-col justify-end">
              {actions}
              {toggleSidebar && (
                <Button
                  icon={<RightSidebar />}
                  onClick={toggleSidebar}
                  weight="minimal"
                />
              )}
            </div>
          </div>
        )}
      </div>
      {children}
    </div>
  );
}

function ReadOnlyLabel({
  label,
}: {
  label: string;
}): React.ReactElement | null {
  const parts = label.split(fieldPathSeparator);

  return (
    <div className="ml-[3px] flex flex-row items-center gap-2">
      {parts.map((part, index) => (
        <>
          {index !== 0 && <Separator />}
          <Label type="title1">{part}</Label>
        </>
      ))}
    </div>
  );
}
