import { EventTypeMap, nullThrows } from "@hypertune/sdk/src/shared";

export default function getEventTypeId(
  eventTypeMap: EventTypeMap,
  eventTypeName: string
): string {
  return nullThrows(
    Object.values(eventTypeMap).find((value) => value.name === eventTypeName),
    new Error(`Missing event type with name: ${eventTypeName}`)
  ).id;
}
