import useIntercom from "../../../app/useIntercom";
import ModalWithContent from "../../../components/ModalWithContent";
import { useUpdatePlanMutation } from "../../../generated/graphql";
import { useHypertune } from "../../../generated/hypertune.react";
import { calendlyUrl } from "../../../lib/constants";
import { switchBusinessRefetchQueries } from "../../../lib/query/refetchQueries";
import { PlanType } from "../../../lib/types";

export default function DowngradeModal({
  businessId,
  currentPlan,
  hasNewBilling,
  onClose,
}: {
  businessId: string;
  currentPlan: PlanType;
  hasNewBilling: boolean;
  onClose: () => void;
}): React.ReactElement | null {
  const content = useHypertune().content().plans();

  const { showIntercom } = useIntercom();
  const [update, { loading }] = useUpdatePlanMutation({
    refetchQueries: switchBusinessRefetchQueries,
    awaitRefetchQueries: true,
  });

  return (
    <ModalWithContent
      content={
        currentPlan === "enterprise"
          ? content.enterpriseDowngradeModal().get()
          : hasNewBilling
            ? content.proDowngradeModal().get()
            : content.legacyProDowngradeModal().get()
      }
      onClose={async (options?: { isAction?: boolean }) => {
        if (currentPlan === "enterprise" && options?.isAction) {
          await showIntercom();
        }
        onClose();
      }}
      closeLoading={showIntercom.loading}
      onSave={async () => {
        if (currentPlan === "enterprise" || !hasNewBilling) {
          window.open(calendlyUrl, "_blank");
        } else {
          await update({ variables: { input: { businessId, cancel: true } } });
        }
        onClose();
      }}
      saveLoading={loading}
    />
  );
}
