import { useState } from "react";
import {
  ObjectExpression,
  ValueType,
  fieldPathSeparator,
  uniqueId,
  FunctionExpression,
} from "@hypertune/sdk/src/shared";
import {
  formatFieldSchemaName,
  addFieldToObject,
  FieldPosition,
  formatTypeSchemaName,
  addDefaultEvent,
  rootContextTypeNameFromSchema,
  rootFieldName,
} from "@hypertune/shared-internal";
import fixAndSimplify from "@hypertune/shared-internal/src/expression/fixAndSimplify";
import Modal from "../../../../../components/Modal";
import Label from "../../../../../components/Label";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import TextInput from "../../../../../components/input/TextInput";
import {
  DraftCommit,
  ObjectAddFieldModalState,
  setDraftCommitSchemaExpressionAndEventTypes,
  setObjectAddFieldModalState,
} from "../../../projectSlice";
import SchemaNameError, { objectFieldNameError } from "../SchemaNameError";
import ValueTypeSelector, {
  valueTypeOptionGroupsFromSchema,
  combineValueTypes,
} from "./ValueTypeSelector";
import Flag from "../../../../../components/icons/Flag";
import { TypeOption, useSchemaEditorSelectedType } from "../../schemaHooks";
import { useProjectSelectedState } from "../../../projectHooks";
import { useLogicSetSelectedFieldPath } from "../../../logicHooks";
import Toggle from "../../../../../components/Toggle";

export const width = 395;

export default function ObjectAddFieldModal(): React.ReactElement | null {
  const draftCommit = useAppSelector(
    (globalState) => globalState.project.draftCommit
  );
  const state = useAppSelector(
    (globalState) => globalState.project.objectAddFieldModal
  );

  if (!state || !draftCommit) {
    return null;
  }
  return (
    <ObjectAddFieldModalInner
      draftCommit={draftCommit}
      objectTypeName={state.objectTypeName}
      fieldPosition={state.fieldPosition}
      entity={state.entity}
    />
  );
}

function ObjectAddFieldModalInner({
  draftCommit,
  objectTypeName,
  fieldPosition = "first",
  entity,
}: {
  draftCommit: DraftCommit;
  objectTypeName: string;
  fieldPosition?: FieldPosition;
  entity: ObjectAddFieldModalState["entity"];
}): React.ReactElement | null {
  const { schema, eventTypes, splits, expression } = draftCommit;

  const dispatch = useAppDispatch();
  const { selected, setSelected } = useProjectSelectedState();
  const setSelectedFieldPath = useLogicSetSelectedFieldPath();
  const [, setSelectedType] = useSchemaEditorSelectedType();

  const optionGroups = valueTypeOptionGroupsFromSchema(schema, objectTypeName);
  const [valueTypes, setValueTypes] = useState<ValueType[]>([
    optionGroups[0].options[0].value,
  ]);
  const [createMatchingEvent, setCreateMatchingEvent] = useState(true);

  const [fieldName, setFlagName] = useState("");
  const fieldSchemaName = formatFieldSchemaName(fieldName);
  const newEventTypeName = formatTypeSchemaName(`${fieldSchemaName}Event`);
  const entityName = entity.name === "logicField" ? "field" : entity.name;
  const nameError =
    objectFieldNameError(schema, entityName, objectTypeName, fieldSchemaName) ||
    (valueTypes[0].type === "VoidValueType" &&
    createMatchingEvent &&
    schema.objects[newEventTypeName]
      ? `Event type with name "${newEventTypeName}" already exists`
      : null);

  const isValid =
    fieldName !== "" &&
    nameError === null &&
    !valueTypes.some((value) => value === null);

  function onClose(): void {
    dispatch(setObjectAddFieldModalState(undefined));
  }
  function onSave(): void {
    if (entity.name === "field") {
      setSelectedType({
        type:
          schema.objects[objectTypeName].role === "output"
            ? "object"
            : (schema.objects[objectTypeName].role as TypeOption),
        name: objectTypeName,
        selectedChildName: fieldSchemaName,
      });
    }
    if (entity.name === "logicField" || entity.name === "flag") {
      setSelectedFieldPath(
        `${entity.parentFieldPath}${fieldPathSeparator}${fieldSchemaName}`
      );
    }
    const newSelectedView = entity.name === "field" ? "schema" : "logic";
    if (selected.view !== newSelectedView) {
      setSelected({ view: newSelectedView });
    }
    onClose();
  }

  function onSubmit(): void {
    if (!isValid) {
      return;
    }
    const valueType = combineValueTypes(valueTypes);
    let newSchema = addFieldToObject(
      schema,
      objectTypeName,
      fieldSchemaName,
      valueType,
      null,
      fieldPosition
    );

    let newEventTypeMap = eventTypes;
    let eventTypeNameAndId;
    if (valueType.type === "VoidValueType" && createMatchingEvent) {
      const newEventId = uniqueId();
      newSchema = addDefaultEvent(newSchema, newEventTypeName, null);
      const contextObjectTypeName = rootContextTypeNameFromSchema(schema);
      newEventTypeMap = {
        ...eventTypes,
        [newEventId]: {
          id: newEventId,
          name: newEventTypeName,
          featureIds: {},
        },
      };
      eventTypeNameAndId = {
        name: newEventTypeName,
        id: newEventId,
        payload: contextObjectTypeName
          ? {
              contextTypeName: contextObjectTypeName,
              rootArgsVariableId: (
                (expression as ObjectExpression).fields[
                  rootFieldName
                ] as FunctionExpression
              ).parameters[0].id,
            }
          : undefined,
      };
    }
    const { newExpression } = fixAndSimplify(
      newSchema,
      splits,
      newEventTypeMap,
      {
        boolean: false,
        string: "",
        int: 0,
        float: 0,
        eventTypeNameAndId,
        complexTypes: true,
      },
      expression
    );
    dispatch(
      setDraftCommitSchemaExpressionAndEventTypes({
        schema: newSchema,
        expression: newExpression,
        eventTypes: newEventTypeMap,
      })
    );
    onSave();
  }

  return (
    <Modal
      buttonLayout="end"
      modalStyle="medium"
      onClose={onClose}
      closeOnEsc
      closeText="Cancel"
      title={
        <div className="flex flex-row items-center gap-2">
          {entityName === "flag" && <Flag />}
          <Label type="title3" className="text-tx-default">
            Add new {entityName}
          </Label>
        </div>
      }
      childrenStyle={{ paddingLeft: 0, paddingRight: 0 }}
      saveText="Create"
      saveIntent="neutral"
      saveWeight="outlined"
      saveDisabled={!isValid}
      onSave={onSubmit}
    >
      <div className="mt-4 flex flex-col text-tx-default">
        <div className="flex max-h-[510px] flex-col overflow-y-auto px-3">
          <Label type="title4" className="mb-[9px] text-tx-muted">
            Name
          </Label>
          <TextInput
            placeholder={`Enter a name for this ${entityName}`}
            value={fieldName}
            onChange={setFlagName}
            focusOnMount
            trimOnBlur={false}
            readOnly={false}
            onEnter={onSubmit}
            size="medium"
            error={
              nameError && <SchemaNameError schemaCheckOrError={nameError} />
            }
          />
          {fieldSchemaName && (
            <TextInput
              value={fieldSchemaName}
              trimOnBlur={false}
              readOnly
              onChange={() => {
                // Dummy
              }}
              style={{ marginTop: 10 }}
            />
          )}
          <ValueTypeSelector
            objectTypeName={objectTypeName}
            optionGroups={optionGroups}
            valueTypes={valueTypes}
            setValueTypes={setValueTypes}
            dropdownStyle={{
              minWidth: width,
              caret: "down",
              scrollToPosition: "center",
              showButtonSubtitle: true,
              buttonClassName:
                "border min-h-[46px] max-w-[395px] px-[14px] py-[12px]",
              subtitleClassName: "max-w-[320px]",
              panelClassName: "max-w-[395px] overflow-x-hidden",
            }}
            newTypeName={fieldName}
            onOpenNewTypeModal={onClose}
          />
          {valueTypes[0].type === "VoidValueType" && (
            <>
              <div className="mt-[24px] flex flex-row justify-between">
                <Label type="title4" className="leading-[13px] text-tx-muted">
                  Create matching event type
                </Label>
                <Toggle
                  size="large"
                  value={createMatchingEvent}
                  setValue={setCreateMatchingEvent}
                />
              </div>
              {newEventTypeName && createMatchingEvent && (
                <TextInput
                  value={newEventTypeName}
                  trimOnBlur={false}
                  readOnly
                  onChange={() => {
                    // Dummy
                  }}
                  style={{ marginTop: 10 }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
