import { Diff, Hunk } from "react-diff-view";
import "react-diff-view/style/index.css";
import toStartCase from "@hypertune/shared-internal/src/toStartCase";
import Label from "../../../components/Label";
import { DiffContainerBase } from "./DiffContainer";
import TypeIcon from "../../../components/icons/TypeIcon";
import { SchemaDiffValue } from "./getSchemaDiffValues";

export default function SchemaDiff({
  schemaDiffValues,
  selectedDiffIndex,
}: {
  schemaDiffValues: SchemaDiffValue[];
  selectedDiffIndex: number | null;
}): React.ReactElement | null {
  return (
    <>
      {schemaDiffValues.map(
        ({ hunks: valueHunks, typeName, typeGroup }, index) => (
          <DiffContainerBase
            contentClassName="pl-0"
            header={
              <>
                <TypeIcon type={typeGroup} size="small" />
                <Label type="title3">Schema / {toStartCase(typeName)}</Label>
              </>
            }
            isSelected={selectedDiffIndex === index}
          >
            <Diff
              key="schema-diff"
              viewType="split"
              diffType="modify"
              hunks={valueHunks}
            >
              {(hunks) =>
                hunks.map((hunk) => <Hunk key={hunk.content} hunk={hunk} />)
              }
            </Diff>
          </DiffContainerBase>
        )
      )}
    </>
  );
}
