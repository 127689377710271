import React from "react";
import Dropdown, { LabeledOption } from "../../components/Dropdown";

export type GroupOrUserId =
  | { type: "group"; groupId: string }
  | { type: "user"; userId: string };

export default function GroupOrUserSelector({
  groupIdToDisplayName,
  userIdToDisplayName,
  selectedId,
  setSelectedId,
  placeholder,
  style,
}: {
  groupIdToDisplayName: { [groupId: string]: string };
  userIdToDisplayName: { [userId: string]: string };
  selectedId: GroupOrUserId | null;
  setSelectedId: (newSelectedId: GroupOrUserId) => void;
  placeholder?: string;
  style?: React.CSSProperties;
}): React.ReactElement {
  const groupsOptionsMap: { [groupId: string]: LabeledOption<GroupOrUserId> } =
    Object.fromEntries(
      Object.entries(groupIdToDisplayName).map(([groupId, displayName]) => [
        groupId,
        { label: displayName, value: { type: "group", groupId } },
      ])
    );
  const usersOptionsMap: { [userId: string]: LabeledOption<GroupOrUserId> } =
    Object.fromEntries(
      Object.entries(userIdToDisplayName).map(([userId, displayName]) => [
        userId,
        { label: displayName, value: { type: "user", userId } },
      ])
    );

  return (
    <Dropdown
      height={30}
      style={style || {}}
      minWidth={175}
      maxWidth={300}
      options={{
        type: "groups",
        groups: [
          { label: "Groups", options: Object.values(groupsOptionsMap) },
          { label: "Users", options: Object.values(usersOptionsMap) },
        ],
      }}
      value={
        !selectedId
          ? null
          : selectedId.type === "group"
            ? groupsOptionsMap[selectedId.groupId] ?? null
            : usersOptionsMap[selectedId.userId] ?? null
      }
      onChange={(option) => {
        if (!option) {
          return;
        }
        setSelectedId(option.value);
      }}
      placeholder={placeholder ?? "Select a group or user"}
      noOptionsMessage="No groups or users found"
    />
  );
}
