import React from "react";
import { nullThrows, stableStringify } from "@hypertune/sdk/src/shared";
import { DbFunnelSegmentData, FunnelStep } from "@hypertune/shared-internal";
import currentFunnelStepHasBreakdowns from "../../../lib/analytics/currentFunnelStepHasBreakdowns";
import getFunnelSegmentDataKey from "../../../lib/analytics/getFunnelSegmentDataKey";
import { blueHex } from "../../../lib/constants";
import { CommitContext, FunnelSegmentData } from "../../../lib/types";
import Step from "./step/Step";
import StepSeparator from "./StepSeparator";
import { sum } from "../../../lib/generic/math";
import { useAppSelector } from "../../../app/hooks";

const colors = [
  "#2494F4",
  "#BD38DE",
  "#26A175",
  "#929D18",
  "#D2682C",
  "#1D961B",
];

const nextColor = Object.fromEntries(
  colors.map((color, i) => [
    color,
    i + 1 < colors.length ? colors[i + 1] : colors[0],
  ])
);

export default function Funnel({
  meId,
  canEdit,
  commitContext,
  steps,
  setSteps,
  data,
  focusModeEnabled,
}: {
  meId: string;
  canEdit: boolean;
  commitContext: CommitContext;
  steps: FunnelStep[];
  setSteps: (newSteps: FunnelStep[]) => void;
  data: DbFunnelSegmentData[][];
  focusModeEnabled: boolean;
}): React.ReactElement {
  const selectedCountVariant = useAppSelector(
    (state) => state.projectAnalytics.countVariant
  );
  const armNames: { [armId: string]: string } = {};
  data.forEach((dbStepData, i) => {
    const step = steps[i];
    if (step.type === "FunnelExposureStep") {
      const split = commitContext.splits[step.splitId];
      if (split) {
        Object.values(split.dimensions).forEach((dimension) => {
          if (dimension.type === "discrete") {
            Object.values(dimension.arms).forEach((arm) => {
              armNames[arm.id] = arm.name;
            });
          }
        });
      }
    }
  });

  const funnelData = data
    .map((dbStepData, index) =>
      dbStepData.map((dbSegmentData) => {
        const segmentData: FunnelSegmentData = {
          breakdownPathValuesList: dbSegmentData.breakdown_path_values_list,
          assignmentList: dbSegmentData.assignment_list,
          aggregations: steps[index].aggregations?.map(({ name }, aIndex) => ({
            label: name,
            value: dbSegmentData.aggregations![aIndex],
          })),
          count:
            selectedCountVariant === "Unique"
              ? dbSegmentData.unique_count
              : dbSegmentData.total_count,
          showPercentage: selectedCountVariant === "Unique",
          label: getSegmentLabel(armNames, dbSegmentData),
          hexColor: blueHex,
        };
        return segmentData;
      })
    )
    .map((stepData) =>
      stepData.sort((a, b) =>
        a.label < b.label ? -1 : a.label > b.label ? 1 : 0
      )
    );

  let color = colors[colors.length - 1];
  const assignedColors: { [segmentDataKey: string]: string } = {};
  funnelData.forEach((stepData, stepIndex) => {
    stepData.forEach((segmentData) => {
      if (stepIndex < 1 || currentFunnelStepHasBreakdowns(segmentData)) {
        // eslint-disable-next-line no-param-reassign
        segmentData.hexColor = nextColor[color];
      } else {
        const parentSegmentDataKey = getFunnelSegmentDataKey(
          segmentData.breakdownPathValuesList.slice(0, -1),
          segmentData.assignmentList.slice(0, -1)
        );
        const parentSegmentColor = nullThrows(
          assignedColors[parentSegmentDataKey],
          "no parent segment color"
        );
        // eslint-disable-next-line no-param-reassign
        segmentData.hexColor = parentSegmentColor;
      }
      const segmentDataKey = getFunnelSegmentDataKey(
        segmentData.breakdownPathValuesList,
        segmentData.assignmentList
      );
      assignedColors[segmentDataKey] = segmentData.hexColor;
      color = segmentData.hexColor;
    });
  });

  const segmentDataMap = Object.fromEntries(
    funnelData.flatMap((stepData) =>
      stepData.map((segmentData) => [
        getFunnelSegmentDataKey(
          segmentData.breakdownPathValuesList,
          segmentData.assignmentList
        ),
        segmentData,
      ])
    )
  );

  const maxStepCount =
    funnelData.length > 0
      ? sum(funnelData[0].map((segmentData) => segmentData.count))
      : 0;

  return (
    <div
      style={{
        marginTop: funnelData.length > 0 ? 24 : 0,
        marginLeft: focusModeEnabled ? 24 : 0,
        gap: focusModeEnabled ? 40 : 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        paddingBottom: 12,
      }}
    >
      <StepSeparator
        canEdit={canEdit}
        commitContext={commitContext}
        steps={steps}
        setSteps={setSteps}
        index={0}
        isSingleSeparator={funnelData.length === 0}
        focusModeEnabled={focusModeEnabled}
      />
      {funnelData.map((stepData, stepIndex) => (
        <>
          <Step
            meId={meId}
            canEdit={canEdit}
            commitContext={commitContext}
            steps={steps}
            setSteps={setSteps}
            segmentDataMap={segmentDataMap}
            maxStepCount={maxStepCount}
            index={stepIndex}
            data={stepData}
            focusModeEnabled={focusModeEnabled}
          />
          <StepSeparator
            canEdit={canEdit}
            commitContext={commitContext}
            steps={steps}
            setSteps={setSteps}
            index={stepIndex + 1}
            focusModeEnabled={focusModeEnabled}
          />
        </>
      ))}
      <div
        className={`h-1 ${focusModeEnabled ? "w-24" : "w-16"} flex-shrink-0`}
      />
    </div>
  );
}

function getSegmentLabel(
  armNames: { [armId: string]: string },
  dbSegmentData: DbFunnelSegmentData
): string {
  const result: string[] = [];

  dbSegmentData.breakdown_path_values_list.forEach(
    (breakdownPathValues, stepIndex) => {
      const assignment = dbSegmentData.assignment_list[stepIndex];
      Object.keys(assignment)
        .sort()
        .forEach((dimensionId) => {
          const dimensionAssignment = assignment[dimensionId];
          if (!dimensionAssignment) {
            result.push("None");
            return;
          }
          if (dimensionAssignment.type !== "discrete") {
            throw new Error("dimension assignment is non-discrete");
          }
          result.push(
            dimensionAssignment.armId
              ? armNames[dimensionAssignment.armId] || "Unknown Arm"
              : "Default"
          );
        });

      Object.keys(breakdownPathValues)
        .sort()
        .forEach((ssFeaturePath) => {
          result.push(stableStringify(breakdownPathValues[ssFeaturePath]));
        });
    }
  );

  return result.length > 0 ? `[${result.join(", ")}]` : "";
}

Funnel.LoadingSkeleton = function ({
  focusModeEnabled,
}: {
  focusModeEnabled: boolean;
}): React.ReactElement {
  return (
    <div className="ml-[75px] mt-6 flex flex-row gap-[75px]">
      <Step.LoadingSkeleton focusModeEnabled={focusModeEnabled} />
      <Step.LoadingSkeleton focusModeEnabled={focusModeEnabled} />
    </div>
  );
};
