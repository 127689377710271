import React from "react";

import Input, { InputProps } from "./Input";

export default function TextInput({
  isPassword,
  ...props
}: Omit<InputProps, "type"> & { isPassword?: boolean }): React.ReactElement {
  return <Input type={isPassword ? "password" : "text"} {...props} />;
}
