import { Plus, ClipboardText, Trash } from "@phosphor-icons/react";
import {
  ProjectTokenMapWithMeta,
  ProjectTokenMetadata,
  toWords,
} from "@hypertune/shared-internal";
import { useCallback, useRef, useState } from "react";
import { DateTime } from "luxon";
import CardGroup from "../../../components/CardGroup";
import Label from "../../../components/Label";
import Button from "../../../components/buttons/Button";
import { CenteredContainerHeader } from "../../../components/container/CenteredContainer";
import { whiteHex, intentDangerHex } from "../../../lib/constants";
import Menu from "../../../components/Menu";
import {
  ProjectDocument,
  useCreateProjectTokenMutation,
  useDeleteProjectTokenMutation,
} from "../../../generated/graphql";
import ModalWithContent from "../../../components/ModalWithContent";
import Modal from "../../../components/Modal";
import Toggle from "../../../components/Toggle";
import ConditionalText from "../../../components/ConditionalText";
import TextInput from "../../../components/input/TextInput";
import CopyButton from "../../../components/buttons/CopyButton";
import { useHypertune } from "../../../generated/hypertune.react";
import twMerge from "../../../lib/twMerge";

export default function TokensEditor({
  projectId,
  projectTokens,
  canEdit,
}: {
  projectId: string;
  projectTokens: ProjectTokenMapWithMeta;
  canEdit: boolean;
}): React.ReactElement | null {
  const content = useHypertune().content();

  const [showCreateTokenModal, setShowCreateTokenModal] = useState(false);
  const [tokenToDelete, setTokenToDelete] = useState<string | null>(null);

  const [deleteProjectToken, { loading }] = useDeleteProjectTokenMutation({
    refetchQueries: [{ query: ProjectDocument, variables: { projectId } }],
    awaitRefetchQueries: true,
  });

  const deleteToken = useCallback(async () => {
    if (!tokenToDelete) {
      return;
    }
    await deleteProjectToken({
      variables: { input: { projectId, token: tokenToDelete } },
    });
    setTokenToDelete(null);
  }, [deleteProjectToken, projectId, tokenToDelete]);

  return (
    <>
      <CenteredContainerHeader title="Project tokens" className="mb-4 mt-8">
        {canEdit && (
          <Button
            text="New token"
            intent="primary"
            weight="filled"
            icon={<Plus color={whiteHex} weight="bold" />}
            onClick={() => setShowCreateTokenModal(true)}
          />
        )}
      </CenteredContainerHeader>
      {Object.keys(projectTokens).length > 0 ? (
        <CardGroup
          layout="list"
          cardLayout="horizontal"
          cards={Object.entries(projectTokens)
            .sort(
              ([, { createdAt: createdAtA }], [, { createdAt: createdAtB }]) =>
                createdAtB.localeCompare(createdAtA)
            )
            .map(([token, meta]) => ({
              key: token,
              children: (
                <TokenCardContent
                  token={token}
                  meta={meta}
                  canEdit={canEdit}
                  setTokenToDelete={setTokenToDelete}
                />
              ),
            }))}
        />
      ) : null}
      {tokenToDelete && (
        <ModalWithContent
          content={content.settings().deleteTokenModal().get()}
          onClose={() => setTokenToDelete(null)}
          onSave={deleteToken}
          saveLoading={loading}
        />
      )}
      {showCreateTokenModal && (
        <CreateTokenModal
          projectId={projectId}
          onClose={() => setShowCreateTokenModal(false)}
        />
      )}
    </>
  );
}

TokensEditor.LoadingSkeleton = function (): React.ReactElement {
  return (
    <div className="my-8">
      <CenteredContainerHeader title="Tokens" />
      <CardGroup
        layout="list"
        cardLayout="horizontal"
        loadingCount={2}
        cards={[]}
      />
    </div>
  );
};

function TokenCardContent({
  token,
  meta,
  canEdit,
  setTokenToDelete,
}: {
  token: string;
  meta: ProjectTokenMetadata;
  canEdit: boolean;
  setTokenToDelete: (tokenToDelete: string) => void;
}): React.ReactElement {
  const tokenRef = useRef<HTMLSpanElement>(null);

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center gap-1">
          <Label type="title4">
            <strong>{meta.name}:</strong>{" "}
            <span
              ref={tokenRef}
              onDoubleClick={() => {
                const range = document.createRange();
                if (tokenRef.current) {
                  range.selectNodeContents(tokenRef.current);
                  window.getSelection()?.removeAllRanges();
                  window.getSelection()?.addRange(range);
                }
              }}
            >
              {token}
            </span>
          </Label>
          <CopyButton text={token} size="x-small" weight="default" />
        </div>
        <div className="flex flex-row gap-3">
          <Label type="title4" className="leading-6">
            <span className="text-tx-muted">Access scopes:</span>{" "}
            {meta.scopes.map((scope) => formatScopeName(scope)).join(", ")}
          </Label>
          <Label type="title4" className="leading-6">
            <span className="text-tx-muted">Created:</span>{" "}
            {DateTime.fromISO(meta.createdAt).toLocaleString()}
          </Label>
        </div>
      </div>
      {canEdit && (
        <Menu
          items={[
            {
              icon: <ClipboardText />,
              intent: "neutral",
              title: "Copy",
              onClick: () => {
                navigator.clipboard
                  .writeText(token)
                  .catch((error) =>
                    console.error("failed to copy token to clipboard", error)
                  );
              },
            },
            {
              icon: <Trash />,
              iconActive: <Trash color={intentDangerHex} />,
              intent: "danger",
              title: "Delete",
              onClick: () => {
                setTokenToDelete(token);
              },
            },
          ]}
        />
      )}
    </>
  );
}

type GranularScopes = {
  query: boolean;
  logs: boolean;
  codegen: boolean;
  flagDefinitions: boolean;
};

function CreateTokenModal({
  projectId,
  onClose,
}: {
  projectId: string;
  onClose: () => void;
}): React.ReactElement | null {
  const content = useHypertune().content();
  const [tokenName, setTokenName] = useState("");
  const [allScope, setAllScope] = useState(true);
  const [granularScopes, setGranularScopes] = useState<GranularScopes>({
    query: true,
    logs: true,
    codegen: true,
    flagDefinitions: true,
  });

  const [createProjectToken, { loading }] = useCreateProjectTokenMutation({
    refetchQueries: [{ query: ProjectDocument, variables: { projectId } }],
    awaitRefetchQueries: true,
  });

  const scopesValid = allScope || Object.values(granularScopes).some(Boolean);
  const metaValid = !!tokenName && scopesValid;

  const createToken = useCallback(async () => {
    if (!metaValid) {
      return;
    }
    await createProjectToken({
      variables: {
        input: {
          projectId,
          tokenName,
          scopes: allScope
            ? ["all"]
            : Object.entries(granularScopes)
                .filter(([, enabled]) => enabled)
                .map(([scope]) => scope),
        },
      },
    });
    onClose();
  }, [
    createProjectToken,
    allScope,
    granularScopes,
    tokenName,
    onClose,
    projectId,
    metaValid,
  ]);

  return (
    <Modal
      title="Create project token"
      saveText="Create token"
      saveDisabled={!metaValid}
      saveLoading={loading}
      onSave={createToken}
      onClose={onClose}
    >
      <div className="flex flex-col items-stretch gap-2">
        <ConditionalText
          text={content.settings().createTokenModalMessage({ fallback: "" })}
          className="mb-4 max-w-lg"
        />
        <Label type="title3">Token name</Label>
        <TextInput
          value={tokenName}
          onChange={setTokenName}
          trimOnBlur
          readOnly={false}
          focusOnMount
        />
        <ScopeToggle
          scope="All scopes"
          enabled={allScope}
          setEnabled={setAllScope}
          className="mt-2"
        />
        {Object.entries(granularScopes).map(([scope, enabled]) => (
          <ScopeToggle
            scope={formatScopeName(scope)}
            enabled={allScope || enabled}
            setEnabled={(newEnabled) => {
              setGranularScopes({
                ...granularScopes,
                [scope]: newEnabled,
              });
            }}
            isDisabled={allScope}
            className="ml-3"
          />
        ))}
      </div>
    </Modal>
  );
}

function ScopeToggle({
  scope,
  enabled,
  setEnabled,
  isDisabled,
  className,
}: {
  scope: string;
  enabled: boolean;
  setEnabled: (newEnabled: boolean) => void;
  isDisabled?: boolean;
  className?: string;
}): React.ReactElement | null {
  return (
    <div
      className={twMerge(
        "flex flex-row items-center justify-between text-tx-default",
        className,
        isDisabled ? "text-tx-muted" : ""
      )}
    >
      <Label type="title3">{scope}</Label>
      <Toggle
        size="large"
        value={enabled}
        setValue={setEnabled}
        disabled={isDisabled}
      />
    </div>
  );
}

function formatScopeName(scope: string): string {
  return toWords(scope)
    .map((word) => word.toLowerCase())
    .join(" ");
}
