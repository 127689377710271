import { Intent } from "../../../components/intent";
import { normal, small } from "../../../lib/constants";
import LabeledExpressionControl from "./LabeledExpressionControl";

export type LabeledExpressionControlListItem =
  | {
      type: typeof LabeledExpressionControlListItemControlType;
      label: Label;
      count?: number;
      intent?: Intent;
      options: React.ReactNode[];
      expressionControl: React.ReactNode;
    }
  | {
      type: typeof LabeledExpressionControlListItemHeaderType;
      label: React.ReactNode;
    };

export const LabeledExpressionControlListItemControlType = "Control" as const;
export const LabeledExpressionControlListItemHeaderType = "Header" as const;

export type Label = {
  component: React.ReactNode;
  width: number;
};

export default function LabeledExpressionControlList({
  useArrow,
  items,
}: {
  useArrow: boolean;
  items: LabeledExpressionControlListItem[];
}): React.ReactElement {
  const labelArrowsMinWidth =
    52 +
    Math.max(
      ...items.map((item) =>
        item.type === LabeledExpressionControlListItemControlType
          ? item.label.width
          : 0
      )
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: useArrow ? small : normal * 2,
      }}
    >
      {items.map((item, index) =>
        item.type === LabeledExpressionControlListItemControlType ? (
          <LabeledExpressionControl
            key={`${index.toString()}`} // TODO: Better key
            label={item.label.component}
            count={item.count}
            useArrow={useArrow}
            arrowMinWidth={labelArrowsMinWidth}
            intent={item.intent}
            options={item.options}
            expressionControl={item.expressionControl}
          />
        ) : (
          <div>
            <div style={{ marginTop: small }} />
            <LabeledExpressionControl
              key={`${index.toString()}`} // TODO: Better key
              label={item.label}
              count={undefined}
              useArrow={false}
              arrowMinWidth={labelArrowsMinWidth}
              intent="neutral"
              options={[]}
              expressionControl={null}
            />
            <div style={{ marginBottom: -normal }} />
          </div>
        )
      )}
    </div>
  );
}
