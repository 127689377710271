import React from "react";
import {
  defaultArmKey,
  ApplicationExpression,
  Dimension,
  Expression,
  SplitExpression,
  VariableExpression,
  DimensionMapping,
} from "@hypertune/sdk/src/shared";
import getArmAllocationsSum from "@hypertune/shared-internal/src/expression/getArmAllocationsSum";
import getConstraintFromValueType from "@hypertune/shared-internal/src/expression/constraint/getConstraintFromValueType";
import isDefaultArmNeeded from "@hypertune/shared-internal/src/expression/isDefaultArmNeeded";
import isValueTypeValid from "@hypertune/shared-internal/src/expression/isValueTypeValid";
import {
  ValueTypeConstraint,
  VariableMap,
} from "@hypertune/shared-internal/src/expression/types";
import createApplication from "../../../lib/expression/createApplication";
import getTextLabel from "../../../lib/generic/getTextLabel";
import getTextWidth from "../../../lib/generic/getTextWidth";
import round from "../../../lib/generic/round";
import {
  darkGreyHex,
  small,
  interFontFamily,
  liftPermissionsDeniedErrorMessage,
  mediumFontSize,
  singlePanelHeight,
  normal,
} from "../../../lib/constants";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
} from "../../../lib/types";
import isReadOnly from "../../../lib/expression/isReadOnly";
import ExpressionControl from "./ExpressionControl";
import Dropdown, { LabeledOption } from "../../../components/Dropdown";
import LabeledExpressionControlList, {
  Label,
  LabeledExpressionControlListItem,
  LabeledExpressionControlListItemControlType,
  LabeledExpressionControlListItemHeaderType,
} from "./LabeledExpressionControlList";
import IdSelector from "../IdSelector";
import ToggleLabel from "../../../components/ToggleLabel";
import DeleteButton from "../../../components/buttons/DeleteButton";
import { Intent, intentToHexColor } from "../../../components/intent";

export default function SplitExpressionControl({
  context,
  variables,
  setVariableName,
  expression,
  setExpression,
  includeExpressionOption,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  expression: SplitExpression;
  setExpression: (newExpression: Expression | null) => void;
  includeExpressionOption: IncludeExpressionOptionFunction;
}): React.ReactElement {
  const readOnly = isReadOnly(context);
  const intent = context.expressionIdToIntent?.[expression.id] ?? "neutral";

  const split =
    expression.splitId && context.commitContext.splits[expression.splitId]
      ? context.commitContext.splits[expression.splitId]
      : null;

  const sortedDimensions = split
    ? Object.values(split.dimensions).sort((a, b) => a.index - b.index)
    : [];

  const dimension =
    expression.dimensionId && split && split.dimensions[expression.dimensionId]
      ? split.dimensions[expression.dimensionId]
      : null;

  const [isSettingsOpen, setIsSettingsOpen] = React.useState<boolean>(
    !split || !dimension || context.expandByDefault
  );

  const needDefaultArm = isDefaultArmNeeded(split, dimension);

  const { dimensionMapping } = expression;

  const extraArmIds: string[] = [];
  if (dimensionMapping.type === "discrete") {
    Object.keys(dimensionMapping.cases).forEach((armId) => {
      if (armId === defaultArmKey) {
        if (!needDefaultArm) {
          extraArmIds.push(armId);
        }
        return;
      }
      const arm =
        dimension && dimension.type === "discrete" && dimension.arms[armId]
          ? dimension.arms[armId]
          : null;
      if (!arm) {
        extraArmIds.push(armId);
      }
    });
  }

  const casePosition: { [armId: string]: number } = {};

  extraArmIds.forEach((armId) => {
    // Show extra cases at the top
    casePosition[armId] = -1;
  });

  const missingArmIds: string[] = [];
  if (dimension && dimension.type === "discrete") {
    Object.keys(dimension.arms)
      .concat(needDefaultArm ? [defaultArmKey] : [])
      .forEach((armId) => {
        // Sort valid cases by the index of their arms in the dimension and show
        // the default arm last
        casePosition[armId] =
          armId === defaultArmKey
            ? Number.MAX_SAFE_INTEGER
            : dimension.arms[armId].index;
        if (
          dimensionMapping.type !== "discrete" ||
          typeof dimensionMapping.cases[armId] === "undefined"
        ) {
          missingArmIds.push(armId);
        }
      });
  }

  const childValueTypeConstraint: ValueTypeConstraint = isValueTypeValid(
    context.commitContext.schema,
    expression.valueType
  )
    ? getConstraintFromValueType(expression.valueType)
    : { type: "ErrorValueTypeConstraint" };

  const dimensionMappingItems: LabeledExpressionControlListItem[] =
    dimensionMapping.type === "discrete"
      ? Object.entries(dimensionMapping.cases)
          .sort((a, b) => casePosition[a[0]] - casePosition[b[0]])
          .map(([armId, armExpression]) => {
            const arm =
              dimension &&
              dimension.type === "discrete" &&
              dimension.arms[armId]
                ? dimension.arms[armId]
                : null;

            const isRed = armId === defaultArmKey ? !needDefaultArm : !arm;

            return {
              type: LabeledExpressionControlListItemControlType,
              label:
                armId === defaultArmKey
                  ? getArmLabel(
                      intent,
                      "Default",
                      split &&
                        split.type === "test" &&
                        dimension &&
                        dimension.type === "discrete"
                        ? 1 - getArmAllocationsSum(dimension)
                        : null
                    )
                  : arm
                    ? getArmLabel(
                        intent,
                        arm.name,
                        split && split.type === "test" ? arm.allocation : null
                      )
                    : getArmLabel(intent, "Unknown Arm", null),
              count: armExpression
                ? context.evaluations[armExpression.id]
                : undefined,
              intent: isRed ? "danger" : intent,
              options:
                !readOnly && armId !== defaultArmKey && !arm
                  ? [
                      <DeleteButton
                        size="x-small"
                        key={`delete${armId}`}
                        onClick={(): void => {
                          const { [armId]: _, ...newCases } =
                            dimensionMapping.cases;
                          setExpression({
                            ...expression,
                            dimensionMapping: {
                              ...dimensionMapping,
                              cases: newCases,
                            },
                          });
                        }}
                      />,
                    ]
                  : [],
              expressionControl: (
                <ExpressionControl
                  context={context}
                  variables={variables}
                  setVariableName={setVariableName}
                  valueTypeConstraint={childValueTypeConstraint}
                  expression={armExpression}
                  setExpression={(newExpression: Expression | null): void =>
                    setExpression({
                      ...expression,
                      dimensionMapping: {
                        ...dimensionMapping,
                        cases: {
                          ...dimensionMapping.cases,
                          [armId]: newExpression,
                        },
                      },
                    })
                  }
                  lift={(child): void => {
                    if (readOnly) {
                      // eslint-disable-next-line no-alert
                      alert(liftPermissionsDeniedErrorMessage);
                      return;
                    }
                    function replaceArgument(
                      variable: VariableExpression | ApplicationExpression
                    ): SplitExpression {
                      const newExpression: SplitExpression = {
                        ...expression,
                        dimensionMapping: {
                          ...(dimensionMapping as DimensionMapping & {
                            type: "discrete";
                          }),
                          cases: {
                            ...(
                              dimensionMapping as DimensionMapping & {
                                type: "discrete";
                              }
                            ).cases,
                            [armId]: child.replaceArgument(variable),
                          },
                        },
                      };
                      return newExpression;
                    }
                    const applicationExpression = createApplication({
                      variables,
                      rawArgument: child.argument,
                      replacedVariableIdToNewVariable:
                        child.replacedVariableIdToNewVariable,
                      valueType: expression.valueType,
                      replaceArgument,
                      newVariableName: child.newVariableName,
                      setExpressionEditorSelectedItem: (newSelectedItem) =>
                        context.setExpressionEditorState({
                          ...context.expressionEditorState,
                          selectedItem: newSelectedItem,
                        }),
                    });
                    setExpression(applicationExpression);
                  }}
                  parentExpression={expression}
                  setParentExpression={setExpression}
                  includeExpressionOption={includeExpressionOption}
                />
              ),
            };
          })
      : [
          // TODO: UI for continuous dimension mapping
        ];

  const settingsItems: LabeledExpressionControlListItem[] = [
    {
      type: LabeledExpressionControlListItemControlType,
      label: getTextLabel("Split"),
      intent,
      options: [],
      expressionControl: (
        <IdSelector
          intent={context.expressionIdToIntent?.[`${expression.id}splitId`]}
          context={context.commitContext}
          source="splits"
          showGoToArrow
          selectedId={expression.splitId}
          setSelectedId={(newSelectedSplitId: string | null) => {
            setExpression({
              ...expression,
              splitId: newSelectedSplitId,
              // If the split has changed, unset dimensionId
              dimensionId:
                newSelectedSplitId !== expression.splitId
                  ? null
                  : expression.dimensionId,
            });
          }}
          readOnly={readOnly}
          variant="with-error"
        />
      ),
    },
    ...(sortedDimensions.length > 0 &&
    (!dimension || sortedDimensions.length > 1)
      ? [
          {
            type: LabeledExpressionControlListItemControlType,
            label: getTextLabel("Dimension"),
            intent,
            options: [],
            expressionControl: (
              <Dropdown<string>
                height={singlePanelHeight}
                options={{
                  type: "options",
                  options: sortedDimensions.map(toLabeledOption),
                }}
                value={
                  expression.dimensionId &&
                  split &&
                  split.dimensions[expression.dimensionId]
                    ? toLabeledOption(split.dimensions[expression.dimensionId])
                    : null
                }
                onChange={(option) => {
                  if (!option) {
                    return;
                  }
                  setExpression({
                    ...expression,
                    dimensionId: option.value,
                  });
                }}
                placeholder="Select dimension..."
                noOptionsMessage="No dimensions found"
              />
            ),
          },
        ]
      : []),
    {
      type: LabeledExpressionControlListItemControlType,
      label: getTextLabel("Unit ID"),
      intent,
      options: [],
      expressionControl: (
        <ExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          valueTypeConstraint={{ type: "StringValueTypeConstraint" }}
          expression={expression.unitId}
          setExpression={(newExpression: Expression | null): void =>
            setExpression({
              ...expression,
              unitId: newExpression,
            })
          }
          lift={(child): void => {
            if (readOnly) {
              // eslint-disable-next-line no-alert
              alert(liftPermissionsDeniedErrorMessage);
              return;
            }
            function replaceArgument(
              variable: VariableExpression | ApplicationExpression
            ): SplitExpression {
              const newExpression: SplitExpression = {
                ...expression,
                unitId: child.replaceArgument(variable),
              };
              return newExpression;
            }
            const applicationExpression = createApplication({
              variables,
              rawArgument: child.argument,
              replacedVariableIdToNewVariable:
                child.replacedVariableIdToNewVariable,
              valueType: expression.valueType,
              replaceArgument,
              newVariableName: child.newVariableName,
              setExpressionEditorSelectedItem: (newSelectedItem) =>
                context.setExpressionEditorState({
                  ...context.expressionEditorState,
                  selectedItem: newSelectedItem,
                }),
            });
            setExpression(applicationExpression);
          }}
          parentExpression={expression}
          setParentExpression={setExpression}
          includeExpressionOption={includeExpressionOption}
        />
      ),
    },
    {
      type: LabeledExpressionControlListItemControlType,
      label: getTextLabel("Should Log Exposure"),
      intent,
      options: [],
      expressionControl: (
        <ExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          valueTypeConstraint={{ type: "BooleanValueTypeConstraint" }}
          expression={expression.expose}
          setExpression={(newExpression: Expression | null): void =>
            setExpression({
              ...expression,
              expose: newExpression,
            })
          }
          lift={(child): void => {
            if (readOnly) {
              // eslint-disable-next-line no-alert
              alert(liftPermissionsDeniedErrorMessage);
              return;
            }
            function replaceArgument(
              variable: VariableExpression | ApplicationExpression
            ): SplitExpression {
              const newExpression: SplitExpression = {
                ...expression,
                expose: child.replaceArgument(variable),
              };
              return newExpression;
            }
            const applicationExpression = createApplication({
              variables,
              rawArgument: child.argument,
              replacedVariableIdToNewVariable:
                child.replacedVariableIdToNewVariable,
              valueType: expression.valueType,
              replaceArgument,
              newVariableName: child.newVariableName,
              setExpressionEditorSelectedItem: (newSelectedItem) =>
                context.setExpressionEditorState({
                  ...context.expressionEditorState,
                  selectedItem: newSelectedItem,
                }),
            });
            setExpression(applicationExpression);
          }}
          parentExpression={expression}
          setParentExpression={setExpression}
          includeExpressionOption={includeExpressionOption}
        />
      ),
    },
    ...(split?.eventObjectTypeName
      ? [
          {
            type: LabeledExpressionControlListItemControlType,
            label: getTextLabel("Payload"),
            intent,
            options: [],
            expressionControl: (
              <ExpressionControl
                context={context}
                variables={variables}
                setVariableName={setVariableName}
                valueTypeConstraint={
                  expression.eventObjectTypeName
                    ? {
                        type: "ObjectValueTypeConstraint",
                        objectTypeName: expression.eventObjectTypeName,
                      }
                    : { type: "ErrorValueTypeConstraint" }
                }
                expression={expression.eventPayload}
                setExpression={(newExpression: Expression | null): void =>
                  setExpression({
                    ...expression,
                    eventPayload: newExpression,
                  })
                }
                lift={(child): void => {
                  if (readOnly) {
                    // eslint-disable-next-line no-alert
                    alert(liftPermissionsDeniedErrorMessage);
                    return;
                  }
                  function replaceArgument(
                    variable: VariableExpression | ApplicationExpression
                  ): SplitExpression {
                    const newExpression: SplitExpression = {
                      ...expression,
                      eventPayload: child.replaceArgument(variable),
                    };
                    return newExpression;
                  }
                  const applicationExpression = createApplication({
                    variables,
                    rawArgument: child.argument,
                    replacedVariableIdToNewVariable:
                      child.replacedVariableIdToNewVariable,
                    valueType: expression.valueType,
                    replaceArgument,
                    newVariableName: child.newVariableName,
                    setExpressionEditorSelectedItem: (newSelectedItem) =>
                      context.setExpressionEditorState({
                        ...context.expressionEditorState,
                        selectedItem: newSelectedItem,
                      }),
                  });
                  setExpression(applicationExpression);
                }}
                parentExpression={expression}
                setParentExpression={setExpression}
                includeExpressionOption={includeExpressionOption}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      {dimensionMappingItems.length > 0 ? (
        <div style={{ height: small }} />
      ) : null}
      <LabeledExpressionControlList
        useArrow={false}
        items={dimensionMappingItems}
      />
      {dimensionMappingItems.length > 0 ? (
        <div style={{ height: normal * 2 }} />
      ) : null}
      <LabeledExpressionControlList
        useArrow={false}
        items={[
          {
            type: LabeledExpressionControlListItemHeaderType,
            label: (
              <ToggleLabel
                label="Settings"
                isOpen={isSettingsOpen}
                setIsOpen={setIsSettingsOpen}
                intent={intent}
              />
            ),
          },
          ...(isSettingsOpen ? settingsItems : []),
        ]}
      />
    </>
  );
}

function toLabeledOption(dimension: Dimension): LabeledOption<string> {
  return { value: dimension.id, label: dimension.name };
}

function getArmLabel(
  intent: Intent,
  armName: string,
  allocation: number | null
): Label {
  function getAllocationText(a: number): string {
    return `${round(a * 100, 3)}%`;
  }
  return {
    component: (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        <p className="leading-[19.5px]">{armName}</p>
        {allocation !== null ? (
          <div
            style={{
              marginLeft: small,
              fontFamily: interFontFamily,
              fontSize: mediumFontSize,
              color:
                intent !== "neutral" ? intentToHexColor(intent) : darkGreyHex,
            }}
          >
            {getAllocationText(allocation)}
          </div>
        ) : null}
      </div>
    ),
    width:
      getTextWidth(interFontFamily, mediumFontSize, armName) +
      (allocation !== null
        ? small +
          getTextWidth(
            interFontFamily,
            mediumFontSize,
            getAllocationText(allocation)
          )
        : 0),
  };
}
