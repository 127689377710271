import { ValueType, Schema } from "@hypertune/sdk/src/shared";
import getConstraintFromValueType from "./getConstraintFromValueType";
import getValueTypeFromConstraint from "./getValueTypeFromConstraint";

import { InnerValueTypeConstraint } from "../types";

export default function isValueTypeCompatible(
  schema: Schema,
  valueTypeConstraint: InnerValueTypeConstraint,
  valueType: ValueType
): boolean {
  switch (valueTypeConstraint.type) {
    case "VoidValueTypeConstraint":
      return valueType.type === "VoidValueType";

    case "BooleanValueTypeConstraint":
      return valueType.type === "BooleanValueType";

    case "IntValueTypeConstraint":
      return valueType.type === "IntValueType";

    case "FloatValueTypeConstraint":
      return (
        valueType.type === "IntValueType" || valueType.type === "FloatValueType"
      );

    case "StringValueTypeConstraint":
      return valueType.type === "StringValueType";

    case "RegexValueTypeConstraint":
      return valueType.type === "RegexValueType";

    case "EnumValueTypeConstraint":
      return (
        valueType.type === "EnumValueType" &&
        valueType.enumTypeName === valueTypeConstraint.enumTypeName
      );

    case "AnyEnumValueTypeConstraint":
      return valueType.type === "EnumValueType";

    case "ObjectValueTypeConstraint":
      return (
        valueType.type === "ObjectValueType" &&
        valueType.objectTypeName === valueTypeConstraint.objectTypeName
      );

    case "AnyObjectValueTypeConstraint":
      return valueType.type === "ObjectValueType";

    case "UnionValueTypeConstraint": {
      if (
        valueType.type === "UnionValueType" &&
        valueType.unionTypeName === valueTypeConstraint.unionTypeName
      ) {
        return true;
      }

      if (valueType.type !== "ObjectValueType") {
        return false;
      }

      const schemaUnionMembers =
        schema.unions[valueTypeConstraint.unionTypeName]?.variants || {};

      return !!schemaUnionMembers[valueType.objectTypeName];
    }

    case "ListValueTypeConstraint":
      return (
        valueType.type === "ListValueType" &&
        isValueTypeCompatible(
          schema,
          valueTypeConstraint.itemValueTypeConstraint,
          valueType.itemValueType
        )
      );

    case "FunctionValueTypeConstraint":
      return (
        valueType.type === "FunctionValueType" &&
        valueType.parameterValueTypes.length ===
          valueTypeConstraint.parameterValueTypeConstraints.length &&
        valueType.parameterValueTypes.every((parameterValueType, index) => {
          // A function type is compatible with a function type constraint only
          // if the parameter type constraints converted to concrete types are
          // all compatible with the function value type's parameter types
          // converted to type constraints, i.e. parameter types are
          // contravariant
          const valueTypeFromConstraint = getValueTypeFromConstraint(
            valueTypeConstraint.parameterValueTypeConstraints[index]
          );
          return (
            valueTypeFromConstraint &&
            isValueTypeCompatible(
              schema,
              getConstraintFromValueType(parameterValueType),
              valueTypeFromConstraint
            )
          );
        }) &&
        isValueTypeCompatible(
          schema,
          valueTypeConstraint.returnValueTypeConstraint,
          valueType.returnValueType
        )
      );

    case "FunctionWithReturnValueTypeConstraint":
      return (
        valueType.type === "FunctionValueType" &&
        isValueTypeCompatible(
          schema,
          valueTypeConstraint.returnValueTypeConstraint,
          valueType.returnValueType
        )
      );

    case "AnyValueTypeConstraint":
      return true;

    default: {
      const neverValueTypeConstraint: never = valueTypeConstraint;
      throw new Error(
        `Unexpected inner value type constraint: ${JSON.stringify(
          neverValueTypeConstraint
        )}`
      );
    }
  }
}
