import React, { MouseEventHandler } from "react";
import { Expression } from "@hypertune/sdk/src/shared";
import {
  ValueTypeConstraint,
  VariableMap,
} from "@hypertune/shared-internal/src/expression/types";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
  LiftFunction,
  VariableContext,
} from "../../../lib/types";
import ApplicationExpressionControl from "./ApplicationExpressionControl";
import ArithmeticExpressionControl from "./ArithmeticExpressionControl";
import BooleanExpressionControl from "./BooleanExpressionControl";
import ComparisonExpressionControl from "./ComparisonExpressionControl";
import EnumExpressionControl from "./EnumExpressionControl";
import EnumSwitchExpressionControl from "./EnumSwitchExpressionControl";
import FloatExpressionControl from "./FloatExpressionControl";
import FunctionExpressionControl from "./FunctionExpressionControl";
import GetFieldExpressionControl from "./GetFieldExpressionControl";
import GetUrlQueryParameterExpressionControl from "./GetUrlQueryParameterExpressionControl";
import InsertExpressionControl from "./InsertExpressionControl";
import IntExpressionControl from "./IntExpressionControl";
import ListExpressionControl from "./ListExpressionControl";
import LogEventExpressionControl from "./LogEventExpressionControl";
import NoOpExpressionControl from "./NoOpExpressionControl";
import ObjectExpressionControl from "./ObjectExpressionControl";
import SplitExpressionControl from "./SplitExpressionControl";
import StringConcatExpressionControl from "./StringConcatExpressionControl";
import StringExpressionControl from "./StringExpressionControl";
import SwitchExpressionControl from "./SwitchExpressionControl";
import UpdateObjectExpressionControl from "./UpdateObjectExpressionControl";
import VariableExpressionControl from "./VariableExpressionControl";
import WrapperExpressionControl from "./WrapperExpressionControl";

export default function ExpressionControlInner({
  context,
  variables,
  setVariableName,
  valueTypeConstraint,
  expression,
  setExpression,
  lift,
  parentExpression,
  setParentExpression,
  shouldStack,
  optionsButton,
  forFunctionExpressionBody,
  variableContext,
  includeExpressionOption,
  useInsert,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  valueTypeConstraint: ValueTypeConstraint;
  expression: Expression | null;
  setExpression: (newExpression: Expression | null) => void;
  lift: LiftFunction;
  parentExpression: Expression | null;
  setParentExpression?: (newParentExpression: Expression | null) => void;
  shouldStack: boolean;
  optionsButton: React.ReactNode;
  forFunctionExpressionBody: {
    shouldStack: boolean;
    collapse?: MouseEventHandler;
  };
  variableContext?: VariableContext;
  includeExpressionOption: IncludeExpressionOptionFunction;
  useInsert?: boolean;
}): React.ReactElement {
  const useInsertExpression =
    useInsert ||
    (!context.readOnly && expression?.type === "VariableExpression");

  if (!expression || useInsertExpression) {
    return (
      <InsertExpressionControl
        context={context}
        variables={variables}
        parentExpression={parentExpression}
        valueTypeConstraint={valueTypeConstraint}
        setExpression={setExpression}
        shouldStack={shouldStack}
        includeExpressionOption={includeExpressionOption}
        selected={expression}
        focusOnMount={
          useInsert &&
          !!expression &&
          context.expressionEditorState.selectedItem?.id === expression.id
        }
      />
    );
  }
  switch (expression.type) {
    case "NoOpExpression":
      return <NoOpExpressionControl optionsButton={optionsButton} />;
    case "BooleanExpression":
      return (
        <BooleanExpressionControl
          context={context}
          expression={expression}
          setExpression={setExpression}
          optionsButton={optionsButton}
        />
      );
    case "IntExpression":
      return (
        <IntExpressionControl
          context={context}
          expression={expression}
          setExpression={setExpression}
          shouldStack={shouldStack}
          optionsButton={optionsButton}
        />
      );
    case "FloatExpression":
      return (
        <FloatExpressionControl
          context={context}
          expression={expression}
          setExpression={setExpression}
          shouldStack={shouldStack}
          optionsButton={optionsButton}
        />
      );
    case "StringExpression":
      return (
        <StringExpressionControl
          context={context}
          expression={expression}
          setExpression={setExpression}
          shouldStack={shouldStack}
          optionsButton={optionsButton}
          parentExpression={parentExpression}
          setParentExpression={setParentExpression}
        />
      );
    case "RegexExpression":
      return (
        <StringExpressionControl
          context={context}
          expression={expression}
          setExpression={setExpression}
          shouldStack={shouldStack}
          optionsButton={optionsButton}
        />
      );
    case "EnumExpression":
      return (
        <EnumExpressionControl
          context={context}
          expression={expression}
          parentExpression={parentExpression}
          setExpression={setExpression}
          shouldStack={shouldStack}
          optionsButton={optionsButton}
          includeExpressionOption={includeExpressionOption}
        />
      );
    case "ObjectExpression":
      return (
        <ObjectExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          setExpression={setExpression}
          includeExpressionOption={includeExpressionOption}
        />
      );
    case "GetFieldExpression":
      return (
        <GetFieldExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          parentExpression={parentExpression}
          expression={expression}
          setExpression={setExpression}
          optionsButton={optionsButton}
          includeExpressionOption={includeExpressionOption}
          shouldStack={shouldStack}
        />
      );
    case "UpdateObjectExpression":
      return (
        <UpdateObjectExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          setExpression={setExpression}
          includeExpressionOption={includeExpressionOption}
        />
      );
    case "ListExpression":
      return (
        <ListExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          setExpression={setExpression}
          lift={lift}
          parentExpression={parentExpression}
          includeExpressionOption={includeExpressionOption}
        />
      );
    case "SwitchExpression":
      return (
        <SwitchExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          setExpression={setExpression}
          lift={lift}
          includeExpressionOption={includeExpressionOption}
        />
      );
    case "EnumSwitchExpression":
      return (
        <EnumSwitchExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          setExpression={setExpression}
          includeExpressionOption={includeExpressionOption}
        />
      );
    case "ComparisonExpression":
      return (
        <ComparisonExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          setExpression={setExpression}
          lift={lift}
          optionsButton={optionsButton}
          includeExpressionOption={includeExpressionOption}
        />
      );
    case "ArithmeticExpression":
      return (
        <ArithmeticExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          setExpression={setExpression}
          includeExpressionOption={includeExpressionOption}
        />
      );
    case "RoundNumberExpression":
      return (
        <WrapperExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          setExpression={setExpression}
          includeExpressionOption={includeExpressionOption}
        />
      );
    case "StringifyNumberExpression":
      return (
        <WrapperExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          setExpression={setExpression}
          includeExpressionOption={includeExpressionOption}
        />
      );
    case "StringConcatExpression":
      return (
        <StringConcatExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          setExpression={setExpression}
          includeExpressionOption={includeExpressionOption}
        />
      );
    case "GetUrlQueryParameterExpression":
      return (
        <GetUrlQueryParameterExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          setExpression={setExpression}
          includeExpressionOption={includeExpressionOption}
        />
      );
    case "SplitExpression":
      return (
        <SplitExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          setExpression={setExpression}
          includeExpressionOption={includeExpressionOption}
        />
      );
    case "LogEventExpression":
      return (
        <LogEventExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          setExpression={setExpression}
          includeExpressionOption={includeExpressionOption}
        />
      );
    case "FunctionExpression":
      return (
        <FunctionExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          parentExpression={parentExpression}
          expression={expression}
          setExpression={setExpression}
          lift={lift}
          forBody={forFunctionExpressionBody}
          variableContext={variableContext}
          includeExpressionOption={includeExpressionOption}
        />
      );
    case "VariableExpression":
      return (
        <VariableExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          parentExpression={parentExpression}
          optionsButton={optionsButton}
        />
      );
    case "ApplicationExpression":
      return (
        <ApplicationExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          expression={expression}
          setExpression={setExpression}
          lift={lift}
          includeExpressionOption={includeExpressionOption}
        />
      );
    default: {
      const neverExpression: never = expression;
      throw new Error(
        `No control implementation for expression: ${JSON.stringify(
          neverExpression
        )}`
      );
    }
  }
}
