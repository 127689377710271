import React from "react";
import {
  BookBookmark,
  CaretDoubleLeft,
  CaretDoubleRight,
  ChatTeardropText,
  Command,
  Gauge,
  MagnifyingGlass,
  SignOut,
} from "@phosphor-icons/react";
import { signOut, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import SidebarItem, { SidebarItemProps } from "./SidebarItem";
import { docsUrl, intentDangerHex, lighterGreyHex } from "../lib/constants";
import useStickyState from "../lib/hooks/useStickyState";
import Button from "./buttons/Button";
import ShortcutDefinition from "./ShortcutDefinition";
import useIntercom from "../app/useIntercom";
import Tooltip from "./tooltips/Tooltip";
import { useHypertune } from "../generated/hypertune.react";
import { usePrimaryBusinessMemberCountQuery } from "../generated/graphql";

export type Section = {
  items: SidebarItemProps[];
};

export default function Sidebar({
  sidebarId,
  collapsible,
  setIsCommandPaletteVisible,
  showCommandPaletteTooltip,
  sections,
  bottomItems,
}: {
  sidebarId: string;
  collapsible?: boolean;
  setIsCommandPaletteVisible?: (isVisible: boolean) => void;
  showCommandPaletteTooltip?: boolean;
  sections: Section[];
  bottomItems?: SidebarItemProps[];
}): React.ReactElement {
  const hypertune = useHypertune();
  const teamMemberLimit = hypertune
    .features()
    .planLimits()
    .softTeamMemberCount({ fallback: -1 });
  const content = hypertune.content();
  const { data } = usePrimaryBusinessMemberCountQuery();

  const navigate = useNavigate();
  const { showIntercom } = useIntercom();
  const [isCollapsed, setIsCollapsed] = useStickyState(
    false,
    `${sidebarId}-collapsed`
  );

  return (
    <div
      className={`flex flex-shrink-0 flex-col items-start gap-[7px] overflow-y-auto overflow-x-hidden border-r p-[10px] ${isCollapsed ? "w-[53px]" : "w-[248px]"}`}
    >
      {collapsible && (
        <div
          className={`flex ${
            isCollapsed ? "flex-col gap-2" : "flex-row gap-[6px]"
          } mb-[9px] w-full`}
        >
          <Tooltip
            id="logic"
            step={2}
            allSteps={content.logic().tooltips()}
            isVisible={showCommandPaletteTooltip}
            className={!isCollapsed ? "flex w-full flex-row" : ""}
          >
            <Button
              size="large"
              weight="elevated"
              icon={
                <MagnifyingGlass
                  size={14}
                  weight="regular"
                  color={lighterGreyHex}
                />
              }
              text={!isCollapsed ? "Search..." : undefined}
              iconEnd={
                !isCollapsed ? (
                  <ShortcutDefinition
                    keys={[
                      <>
                        <Command
                          size={12}
                          weight="regular"
                          color={lighterGreyHex}
                        />
                        K
                      </>,
                    ]}
                  />
                ) : undefined
              }
              onClick={
                setIsCommandPaletteVisible
                  ? () => setIsCommandPaletteVisible(true)
                  : undefined
              }
              className={!isCollapsed ? "flex-grow justify-between" : ""}
            />
          </Tooltip>
          <Button
            size="large"
            weight="elevated"
            icon={
              isCollapsed ? (
                <CaretDoubleRight
                  size={14}
                  weight="regular"
                  color={lighterGreyHex}
                />
              ) : (
                <CaretDoubleLeft
                  size={14}
                  weight="regular"
                  color={lighterGreyHex}
                />
              )
            }
            onClick={() => setIsCollapsed((collapsed) => !collapsed)}
          />
        </div>
      )}
      {sections.map(
        (section) =>
          section.items.map(
            ({
              icon,
              title,
              onClick,
              link,
              hasError,
              isSelected,
              children,
            }) => (
              <SidebarItem
                key={title}
                isCollapsed={isCollapsed}
                icon={icon}
                title={title}
                onClick={onClick}
                link={link}
                hasError={hasError}
                isSelected={isSelected}
              >
                {children}
              </SidebarItem>
            )
          )
        // TODO: add divider if there is more than one section.
      )}
      <div className="mt-auto min-h-[68px] w-full" />
      <div className="flex w-full flex-col items-stretch gap-[7px]">
        {data?.primaryBusiness &&
          teamMemberLimit > 0 &&
          data.primaryBusiness.business.memberCount > teamMemberLimit && (
            <SidebarItem
              isCollapsed={isCollapsed}
              icon={<Gauge color={intentDangerHex} weight="fill" />}
              title="Team size overage"
              subtitle={`${data.primaryBusiness.business.memberCount}/${teamMemberLimit} allowed team members`}
              intent="danger"
              variant="minimal"
              onClick={() => navigate("/plans")}
            />
          )}
        {bottomItems?.map(
          ({ icon, title, onClick, link, hasError, isSelected, children }) => (
            <SidebarItem
              key={title}
              isCollapsed={isCollapsed}
              icon={icon}
              title={title}
              onClick={onClick}
              link={link}
              hasError={hasError}
              isSelected={isSelected}
            >
              {children}
            </SidebarItem>
          )
        ) || null}
        <SidebarItem
          isCollapsed={isCollapsed}
          icon={<ChatTeardropText />}
          title="Live chat"
          onClick={showIntercom}
          loading={showIntercom.loading}
        />
        <SidebarItem
          isCollapsed={isCollapsed}
          icon={<BookBookmark />}
          title="Documentation"
          link={{ href: docsUrl, isExternal: true }}
        />
        <SidebarItem
          isCollapsed={isCollapsed}
          icon={<SignOut />}
          title="Sign out"
          onClick={(): void => {
            signOut(getAuth())
              .then(() => {
                navigate("/login");
              })
              .catch((signOutError) => {
                console.error("signOut error:", signOutError);
              });
          }}
        />
      </div>
    </div>
  );
}
