import {
  mapExpression,
  Expression,
  getEmptyLogs,
} from "@hypertune/sdk/src/shared";

// Expensive
export default function sanitizeExpression(
  expression: Expression | null
): Expression | null {
  return mapExpression((expr) => {
    if (!expr) {
      return null;
    }
    if (expr.type === "SwitchExpression") {
      return {
        ...expr,
        id: "",
        isTransient: false,
        logs: getEmptyLogs(),
        cases: expr.cases.map((switchCase) => ({
          ...switchCase,
          id: "",
        })),
        metadata: undefined,
      };
    }
    return {
      ...expr,
      id: "",
      isTransient: false,
      logs: getEmptyLogs(),
      metadata: undefined,
    };
  }, expression);
}
